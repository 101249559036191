import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  GET_ALL_COACH,
  GET_COACH_BY_ID,
  GET_COACH_INFO,
  GET_COACH_BY_SLUG,
  BOOK_APPOINTMENT,
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const getAllcoachAction = (data: any) => {
  return { type: GET_ALL_COACH, payload: data };
};
const getCoachbyIdAction = (data: any) => {
  return { type: GET_COACH_BY_ID, payload: data };
};
const getcoachinfoAction = (data: any) => {
  return { type: GET_COACH_INFO, payload: data };
};
const getCoachBySlugAction = (data: any) => {
  return { type: GET_COACH_BY_SLUG, payload: data };
};
const bookAppointmentAction = (data: any) => {
  return { type: BOOK_APPOINTMENT, payload: data };
};

export const getAllcoach = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllcoach}`);
      dispatch(getAllcoachAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getcoachinfo = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.getcoachinfo}`, data);
      dispatch(getcoachinfoAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const bookAppointment = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.bookAppointment}`, data);
      // console.log(response, "ersponse");

      dispatch(bookAppointmentAction(response?.data?.data));
      // console.log();
      if (response?.data?.data?.success) {
        // Instamojo.open("https://www.instamojo.com/@admissify");
        window.location.href = response?.data?.data?.payment_request.longurl;
        // dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCoachbyId = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getCoachbyId}${data}`);
      dispatch(getCoachbyIdAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCoachBySlug = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getCoachBySlug}?slug=${data}`);
      dispatch(getCoachBySlugAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
