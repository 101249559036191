import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Calendar from "react-calendar";
import dateFormat from "dateformat";
import "react-calendar/dist/Calendar.css";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import {
  coachAction,
  countryAction,
  UniversityAction,
  SettingAction,
  FaqAction,
  blogAction,
  DiscussionAction,
  articaleAction,
} from "../../redux/common/action";
import { URL } from "../../redux/common/url";
import Seo from "../Seo/Seo";
import Exe from "../../assets/img/icons/exp.png";
import Edu from "../../assets/img/icons/edu.png";
import avail from "../../assets/img/days_availability.png";
import Loc from "../../assets/img/icons/location.png";
import certificate from "../../assets/img/certificate.svg";
import degree from "../../assets/img/degree.svg";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import "../../assets/css/calendar-new.css";
import SocialShareButton from "../SocialShareButton";
import ReactPlayer from "react-player";
import Breadcrumb from "../Breadcrumb";

function CoachDetails() {
  const urlsforShare = window.location.href;
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const [CurrentTime, setCurrentTime] = useState(new Date().getHours());
  const [CurrentDate, setCurrentDate] = useState(new Date().getDate());
  const [CurrentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [CurrentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const timings = [0,1,2,3,4,5,6,7,8,9,10, 11, 12, 1, 2, 3, 4, 5, 6,7,8,9,10,11];
  var noAvailable = false;
  const id: any = useLocation();
  const [faqdata, setfaqdata] = useState();
  const history = useHistory();
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [date, setDate] = useState(new Date());
  const [CoachCounsellorId, setCoachCounsellorId] = useState();
  const coachSlug: any = useParams();

  const baseurl = URL.API_BASE_URL;
  // const relatedcoach: any[] = useSelector((state: any) =>
  //   state.coach.couchList ? state.coach.couchList : []
  // );

  const relatedcoach: any[] = useSelector((state: any) =>
    state.coach.getcouchinfo ? state.coach.getcouchinfo : []
  );

  const activeRelatedcoach =
    relatedcoach &&
    relatedcoach?.filter((data: any) => {
      return data?.status == 1;
    });

  const universities: any[] = useSelector((state: any) =>
    state.unversity.universities?.data ? state.unversity.universities?.data : []
  );

  const [title, settitle] = useState<any>("about-mentor");
  // const coach: any = useSelector((state: any) =>
  //   state.coach.couchDetails ? state.coach.couchDetails : []
  // );

  const coach: any = useSelector((state: any) =>
    state.coach.getcouchBySlug ? state.coach.getcouchBySlug : []
  );
  const counsellor: any = useSelector((state: any) =>
    state.counsellor.counsellordetail ? state.counsellor.counsellordetail : []
  );

  const relatedcounsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  // const university: any[] = useSelector((state: any) =>
  //   state.unversity.universityList ? state.unversity.universityList : []
  // );

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );
  const discuss: any[] = useSelector((state: any) =>
    state.discussion.discussionList ? state.discussion.discussionList : []
  );
  const articalList: any[] = useSelector((state: any) =>
    state.articale.articaleList ? state.articale.articaleList : []
  );
  const reviewList: any[] = useSelector((state: any) =>
    state.articale.reviewList ? state.articale.reviewList : []
  );
  ////////////////////////// Start Faq
  const faqList: any[] = useSelector((state: any) =>
    state.faq.faqList ? state.faq.faqList : []
  );
  const dc = faqList.filter((data) => {
    return data.faq_category_id === faqList[0].faq_category_id;
  });
  const faqL = typeof faqdata == "undefined" ? dc : faqdata;
  const faqLNew = faqList.filter((data) => {
    return data?.user_reference_id == coach?.user_id && data?.user_type == 2;
  });
  const CFaqs = () => {
    dispatch(FaqAction.getfaqlist({}));
    return () => {};
  };
  const getdiscussionList = () => {
    dispatch(
      DiscussionAction.getDiscussionList({ user_id: coach?.user_id, user_type: 4 })
    );
    return () => {};
  };
  const getArticalList = () => {
    dispatch(
      articaleAction.getarticlelist(coach?.user_id)
    );
    return () => {};
  };
  const getReviewList = () => {
    dispatch(
      articaleAction.getreviewlist({
        reference_id: coach?.user_id,
        testimonial_for: 2,
        status: "1",
      })
    );
    return () => {};
  };
  // const onchangefaq = (event: any, data: any) => {
  //   const faqlist: any = faqList.filter((datas) => {
  //     return datas.faq_category_id === data;
  //   });
  //   setfaqdata(faqlist);
  // };
  ////////////////////////// End Faq
  ////////////////////////// Start Vblog
  const vBlog: any[] = useSelector((state: any) =>
    state.blog.vBlog ? state.blog.vBlog : []
  );
  const vblog = (e: any) => {
    dispatch(
      blogAction.getVBlog({
        UserType: 2,
        UserId: coach?.user_id,
      })
    );
    return () => {};
  };
  ////////////////////////// End  Vblog

  const getSetting: any = useSelector((state: any) =>
    state.setting.getSetting ? state.setting.getSetting : []
  );

  // const handleFreeCoaching = (e: any) => {
  //   console.log(e);
  //   setCoachCounsellorId(e)
  // };
  // const handleTime = () => {
  //   var id = $(this).attr('data-id');
  //   console.log(id);
  // }

  // $(".tenam").click(function (k, v) {
  //   var id = $(this).attr('data-id');
  //   console.log(id);
  // });

  const handleAppointment = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var Date = dateFormat(date, "yyyy-mm-dd");
      var Data = {
        refer_url: window.location.origin,
        id: user?.id,
        mobile: user?.mobile,
        reference_type: 2,
        reference_user_id: e,
        date: Date,
        time: CoachCounsellorId,
      };
      dispatch(coachAction.bookAppointment(Data));
    }
  };

  const reletedcoach = (e: any) => {
    history.push("/mentor/" + e, {
      counselloer_id: e,
    });
    dispatch(coachAction.getCoachBySlug(e));
    return () => {};
  };

  useEffect(() => {
    dispatch(SettingAction.getSetting({}));
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(
      coachAction.getcoachinfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      UniversityAction.getuniversities({
        search_by: "",
        offset: 0,
        country_id: [],
        limit: 5,
      })
    );
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(coachAction.getCoachBySlug(coachSlug.slug));
    // dispatch(coachAction.getCoachbyId(id?.search));
    return () => {};
  }, [coachSlug.slug]);

  var preferredCountry: any = [];
  var preferred_country = coach?.preferred_country
    ? coach?.preferred_country.split(",")
    : [];
  var preferred_universities = coach?.preferred_universities
    ? coach?.preferred_universities.split(",")
    : [];

    const [LastCategoryId, setLastCategoryId] = useState<any>(null);
    const faqCatList: any[] = useSelector((state: any) =>
      state.faq.faqCatList ? state.faq.faqCatList : []
    );
    const onchangefaq = (event: any, data: any) => {
      setLastCategoryId(data);
    };
    useEffect(() => {
      dispatch(FaqAction.getfaqcategory({}));
      return () => { };
    }, []);
  
    useEffect(() => {
      faqCatList.map((data, index) => {
        if (index == 0)
          setLastCategoryId(data?.id);
      });
    }, [faqCatList]);

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        title={coach?.name ? `Mentor | ${coach?.name}` : ""}
        meta_description={coach?.additional_info}
        meta_keywords={""}
        modified_time={coach?.updatedAt || coach?.createdAt}
        image={coach?.profile_image ? baseurl+ coach?.profile_image : ""}
      />
      <section className="unifilter-page counsellor_details">
      <Breadcrumb page={"Mentor"} data={{slug: coach?.namee, path: "/mentor"}}/>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-pull-10 order-lg-2">
              <div className="page-sidebar">
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    suggested <b>university</b>{" "}
                  </h5>

                  <ul className="bg-white">
                    {universities &&
                      universities?.map((data: any, i: any) => {
                        return (
                          i < 5 && (
                            <li
                              key={i}
                              onClick={() => {
                                history.push("/university/" + data?.slug, {
                                  university_id: data?.id,
                                });
                              }}
                            >
                              {data.university_logo != null ? (
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={baseurl + data.university_logo}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ cursor: "pointer" }}
                                  src="/assets/img/university-icon.png"
                                  alt=""
                                />
                              )}
                              <div className="counsellorName">
                                <h6
                                  className="__hover"
                                  onClick={() => {
                                    history.push("/university/" + data?.slug, {
                                      university_id: data?.id,
                                    });
                                  }}
                                >
                                  {data?.university_name &&
                                    data?.university_name}
                                </h6>
                                <div className="feat-inn">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />
                                  </ul>
                                </div>
                              </div>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>

                {/* <!-- WIDGET --> */}
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    related <b>mentor</b>{" "}
                  </h5>
                  <ul className="bg-white">
                    {activeRelatedcoach &&
                      activeRelatedcoach?.map((data: any, i: any) => {
                        return (
                          i < 6 && (
                            <li
                              key={i}
                              style={{
                                display:
                                  coach?.slug == data?.slug ? "none" : "",
                              }}
                            >
                              <div className="counsellorBox">
                                <div
                                  className="relatedCounsellor"
                                  style={{
                                    background: `url(${
                                      baseurl + data?.profile_image
                                    })`,
                                    cursor: "pointer",
                                  }}
                                ></div>
                              </div>
                              {/* <img src="assets/img/testi.png" alt="" /> */}
                              <div className="counsellorName">
                                <h6
                                  onClick={() => {
                                    reletedcoach(data?.slug);
                                  }}
                                >
                                  {data?.gender == 1 ? "Mr. " : "Ms. "}{" "}
                                  {data?.name}{" "}
                                </h6>
                                <div className="feat-inn">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />
                                  </ul>
                                </div>
                              </div>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-9 col-md-push-2">
              <div className="filter-page__content">
                <div className="filter-item-wrapper counsellorlist counsellor_details">
                  <div className="shade1">
                    <div className="uni-item-list">
                      <div className="item-media">
                        <div className="image-cover">
                          <div
                            className="counsellorAneCoach_details"
                            style={{
                              background: `url(${
                                baseurl + coach?.profile_image
                              })`,
                            }}
                          ></div>
                          {/* <img
                            src="assets/img/pankaj.png"
                            alt=""
                            className="scale"
                          /> */}
                        </div>
                      </div>
                      <div className="item-body">
                        <div className="item-title">
                          <div className="pricedegree">
                            <div className="sos-icon">
                              <SocialShareButton
                                urlsforShare={urlsforShare}
                                data={""}
                              />
                            </div>
                            <p>
                              {coach?.rate_per_hr ? 
                              `INR ${coach?.rate_per_hr}/60 min`
                              :
                              "Free"}
                              {" "}
                              <span>
                                {" "}
                                <img src={certificate} alt="" />
                              </span>{" "}
                              <span>
                                {" "}
                                <img src={degree} alt="" />
                              </span>
                            </p>
                          </div>
                          <h3>
                            {coach?.gender == 1 ? "Mr. " : "Ms. "}{" "}
                            {coach?.name && coach?.name}
                            <sup className="color-green">
                              <small>
                                <i
                                  className="fa fa-check-circle"
                                  aria-hidden="true"
                                ></i>
                              </small>
                            </sup>
                            <span className="online">
                              <i
                                className="fa fa-circle"
                                aria-hidden="true"
                                style={{
                                  color: coach?.User?.is_online
                                    ? "green"
                                    : "red",
                                }}
                              ></i>{" "}
                              {coach?.User?.is_online ? "online" : "offline"}
                            </span>
                          </h3>
                          <p>mentor</p>
                          <p className="__content_">
                            {coach?.additional_info && coach?.additional_info}
                          </p>
                          <p className="yellow pt-2">
                            {coach?.passout_university &&
                              coach?.passout_university}
                          </p>
                        </div>
                        <div className="item-footer mt-0">
                          <div className="item-hotel-star">
                            <div>
                              <StarRatings
                                rating={
                                  parseFloat(coach?.rating)
                                    ? parseFloat(coach?.rating)
                                    : 0
                                }
                                starRatedColor="#fcbd30"
                                starDimension="13px"
                                starSpacing="0px"
                              />{" "}
                              <span>
                                |{" "}
                                {coach?.counselled && coach?.counselled > 500
                                  ? "500+"
                                  : coach?.counselled}{" "}
                                student google reviews
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="item-abouttxt">
                          <table
                            width="100%"
                            style={{ border: "0" }}
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody className="mob-po-relative">
                              <tr>
                                <td style={{ width: "150px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={Exe}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>experience</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {coach?.experience == null
                                              ? ""
                                              : coach?.experience + " yr"}{" "}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td style={{ width: "165px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={Loc}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>country preference </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {countrys.map((v, k) => {
                                            if (
                                              preferred_country &&
                                              preferred_country.includes(
                                                v?.id.toString()
                                              )
                                            ) {
                                              preferredCountry.push(
                                                v?.country_name
                                              );
                                            }
                                          })}
                                          <>
                                            <span className="yellow">
                                              {preferredCountry.length != 0
                                                ? preferredCountry.join(", ")
                                                : "N/A"}
                                            </span>
                                          </>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={Edu}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>highest education</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {coach?.qualification &&
                                              coach?.qualification}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            width="100%"
                            style={{ border: "0" }}
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody className="mob-po-relative">
                              <tr>
                                <td style={{ width: "150px" }}>
                                  <table
                                    width="100%"
                                    style={{ border: "0" }}
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          rowSpan={2}
                                          style={{ width: " 40px" }}
                                        >
                                          <img
                                            src={avail}
                                            alt=""
                                            className="scale"
                                          />
                                        </td>
                                        <td>availability</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span className="yellow">
                                            {coach?.days_availability
                                              ? coach?.days_availability
                                              : "N/A"}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="item-btn-more">
                            <div className="btnbox">
                              {/* <a href="#" className="awe-btnbook">
                                <i
                                  className="fa fa-commenting"
                                  aria-hidden="true"
                                ></i>{" "}
                                chat now
                              </a> */}
                              <a
                                href=""
                                data-toggle="modal"
                                data-target="#date_foo"
                                className="awe-btnbook download"
                                // onClick={(e) => handleFreeCoaching(coach?.user_id)}
                              >
                                book appointment
                              </a>
                              <a
                                href={"tel:" + getSetting[0]?.mobile}
                                className="awe-btnbook"
                              >
                                <i
                                  className="fa fa-phone"
                                  aria-hidden="true"
                                ></i>{" "}
                                connect support
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="contentbx tabdetails">
                      <ul className="nav nav-tabs bggreadent" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabs-1"
                            role="tab"
                          >
                            about mentor{" "}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-2"
                            role="tab"
                            onClick={getReviewList}
                          >
                            {" "}
                            reviews
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-3"
                            role="tab"
                            onClick={CFaqs}
                          >
                            faqs
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-4"
                            role="tab"
                            onClick={getArticalList}
                          >
                            article
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-5"
                            role="tab"
                            onClick={vblog}
                          >
                            vblog
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-6"
                            role="tab"
                            onClick={getdiscussionList}
                          >
                            discussion
                          </a>
                        </li>
                      </ul>
                      {/* <!-- Tab panes --> */}
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="tabs-1"
                          role="tabpanel"
                        >
                          <p className="f-size">
                            <div
                              className="editor_css"
                              dangerouslySetInnerHTML={{
                                __html: coach?.description
                                  ? coach?.description
                                  : "no content available",
                              }}
                            ></div>
                          </p>
                        </div>
                        <div className="tab-pane" id="tabs-2" role="tabpanel">
                          <div className="f-size row tesi-wrap no-bg">
                            {reviewList &&
                              reviewList?.length > 0 &&
                              reviewList?.map((data, idx) => {
                                if (data?.status == 1)
                                  return (
                                    <div className="col-md-6 col-sm-12 col-xs-12 item">
                                      <div className="team_member">
                                        {/* <figure className="effect-julia">
                                          {data?.profile_image &&
                                          data?.profile_image !== null ? (
                                            <img
                                              className="scale"
                                              src={
                                                baseurl + data?.profile_image
                                              }
                                              alt="team"
                                            />
                                          ) : (
                                            <img
                                              className="scale"
                                              src="/assets/img/noImage.jpeg"
                                              alt="team"
                                            />
                                          )}
                                        </figure> */}
                                        <div className="member_name">
                                          {data?.testimonial_type == 2 &&
                                          data?.video_url ? (
                                            <p>
                                              <ReactPlayer
                                                controls
                                                url={data?.video_url}
                                                width="100%"
                                                height="100%"
                                              />
                                            </p>
                                          ) : (
                                            <p className="auto_scroll">
                                              {data?.content}
                                            </p>
                                          )}
                                          <h5>
                                            <span></span>
                                            {data?.name}
                                          </h5>
                                          <div className="feat-inn">
                                            <ul>
                                              <StarRatings
                                                rating={
                                                  parseFloat(data?.rating)
                                                    ? parseFloat(data?.rating)
                                                    : 0
                                                }
                                                starRatedColor="#fcbd30"
                                                starDimension="15px"
                                                starSpacing="0px"
                                              />
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                              })}
                          </div>
                        </div>
                        <div className="tab-pane" id="tabs-3" role="tabpanel">
                          <div className="row">
                            <div className="col-sm-12">
                              <div
                                className="contentbx choachwhat cFaq__ faqtabs mt-5"
                                style={{ maxWidth: "100%" }}
                              >
                                <ul className="nav nav-tabs" role="tablist">
                                {faqCatList && faqCatList?.map((data, index) => {
                                        return (
                                          <li className="nav-item" key={index}>
                                            <a
                                              className={
                                                index == 0
                                                  ? "nav-link active"
                                                  : "nav-link"
                                              }
                                              data-toggle="tab"
                                              href={"#tabs_1"}
                                              onClick={(event) =>
                                                onchangefaq(
                                                  event,
                                                  data?.id
                                                )
                                              }
                                              role="tab"
                                              style={{
                                                backgroundColor: "#e6e6e6",
                                                color: "#555555",
                                              }}
                                            >
                                                 {data?.category && data?.category}
                                            </a>
                                          </li>
                                        );
                                    })}
                                </ul>
                                {/* <!-- Tab panes --> */}
                                <div
                                  className="tab-content"
                                  style={{ padding: "0" }}
                                >
                                  <div
                                    className="tab-pane active"
                                    id="tabs_1"
                                    role="tabpanel"
                                  >
                                    <div className="accordion" id="faq">
                                      {faqLNew &&
                                        faqLNew?.map((data, index) => {
                                          if (data?.faq_category_id == LastCategoryId) {
                                            return (
                                              <div className="card" key={index}>
                                                <div
                                                  className="card-header"
                                                  id={"faqhead1" + data?.id}
                                                >
                                                  <a
                                                    href="#"
                                                    className={
                                                      index == 0
                                                        ? "btn btn-header-link"
                                                        : "btn btn-header-link collapsed"
                                                    }
                                                    data-toggle="collapse"
                                                    data-target={
                                                      "#faq1" + data?.id
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls={
                                                      "faq1" + data?.id
                                                    }
                                                  >
                                                    {data?.title}
                                                  </a>
                                                </div>

                                                <div
                                                  id={"faq1" + data?.id}
                                                  className={
                                                    index == 0
                                                      ? "collapse show"
                                                      : "collapse"
                                                  }
                                                  aria-labelledby={
                                                    "faqhead1" + data?.id
                                                  }
                                                  data-parent="#faq"
                                                >
                                                  <div className="card-body">
                                                    {data?.description &&
                                                      data?.description}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                                    }
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p
                            className="f-size"
                            style={{
                              display: faqList.length == 0 ? "" : "none",
                            }}
                          >
                            {" "}
                            currently not available.
                          </p>
                        </div>
                        <div className="tab-pane" id="tabs-4" role="tabpanel">
                          <div className="faqtabs articaltabs">
                            <div className="tab-content">
                              <div className="accordion" id="artical">
                                {articalList &&
                                  articalList?.length > 0 &&
                                  articalList?.map((data, index) => {
                                    return (
                                      <div className="card" key={index}>
                                        <div
                                          className="card-header"
                                          id={"articalhead1" + data?.id}
                                        >
                                          <a
                                            href="#"
                                            className={
                                              index == 0
                                                ? "btn btn-header-link"
                                                : "btn btn-header-link collapsed"
                                            }
                                            data-toggle="collapse"
                                            data-target={"#artical1" + data?.id}
                                            aria-expanded="true"
                                            aria-controls={
                                              "artical1" + data?.id
                                            }
                                          >
                                            {data?.heading}
                                          </a>
                                        </div>
                                        <div
                                          id={"artical1" + data?.id}
                                          className={
                                            index == 0
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                          aria-labelledby={
                                            "articalhead1" + data?.id
                                          }
                                          data-parent="#artical"
                                        >
                                          <div
                                            className="card-body editor_css"
                                            dangerouslySetInnerHTML={{
                                              __html: data?.details
                                                ? data?.details
                                                : " ",
                                            }}
                                          />
                                        </div>
                                        <a className="blog_url_button text-center mx-auto" href={data?.blog_url} target="_blank">Blog Url</a>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="tab-pane" id="tabs-5" role="tabpanel">
                          <div className="row">
                            {vBlog &&
                              vBlog?.map((data, index) => {
                                const videoUrl = data?.video_url;
                                const res = videoUrl ? videoUrl.split("=") : "";
                                const embeddedUrl =
                                  "https://www.youtube.com/embed/" + res[1];
                                return (
                                  <div className="col-12 __vblog" key={index}>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <iframe
                                          src={embeddedUrl}
                                          width="600"
                                          height="500"
                                          style={{ border: "0" }}
                                          allowFullScreen
                                          loading="lazy"
                                        ></iframe>
                                      </div>
                                      <div className="col-md-6">
                                        {data?.short_details}
                                      </div>
                                      <div className="col-md-12 coach-view">
                                        {data?.details}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <p
                            className="f-size"
                            style={{ display: vBlog.length == 0 ? "" : "none" }}
                          >
                            {" "}
                            currently not available.
                          </p>
                        </div> */}
                        <div className="tab-pane" id="tabs-6" role="tabpanel">
                          <p className="f-size">
                            {discuss &&
                              discuss?.length > 0 &&
                              discuss?.map((data, idx) => (
                                <div className="shade1 reviewstops bg-grey-light review-discussion">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="counsellorName">
                                        <h6
                                          onClick={() => {
                                            history.push(
                                              "/about-discussion/" +
                                                data?.slug +
                                                "",
                                              {
                                                discussion_id: data?.slug,
                                              }
                                            );
                                          }}
                                        >
                                          <b>
                                            {data?.topic ? data?.topic : " "}
                                          </b>
                                        </h6>
                                        <div className="reviews_item">
                                          <a
                                            onClick={() => {
                                              history.push(
                                                "/about-discussion/" +
                                                  data?.slug +
                                                  "",
                                                {
                                                  discussion_id: data?.slug,
                                                }
                                              );
                                            }}
                                          >
                                            <p className="pt-1 pb-1">
                                              {data?.description
                                                ? data?.description + " "
                                                : " "}
                                            </p>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="date_foo"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <p className="calen-head">select date & time</p>
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <div className="calendar-container">
                    <Calendar
                      onChange={setDate}
                      value={date}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="timepicker" ng-if="picktime == 'true'">
                    <div ng-className="{'am': timeframe == 'am', 'pm': timeframe == 'pm' }">
                      <div
                        className="timepicker-container-outer"
                        selectedtime="time"
                        timetravel
                      >
                        <div className="timepicker-container-inner">
                          <div
                            className="timeline-container"
                            ng-mousedown="timeSelectStart($event)"
                            sm-touchstart="timeSelectStart($event)"
                          >
                            <div className="timeline"></div>
                          </div>

                          {timings.map((value, index) => {
                            var currtime = (index > 12) ? value + 12 : value;
                            var timeString = (value == 0 ? 12 : value) + ':00' + ((currtime) >= 12 && value != 0 ? ' pm' : ' am');
                            if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth == date.getMonth() &&
                              CurrentDate == date.getDate() &&
                              CurrentTime < currtime
                            ) {
                              noAvailable = true;

                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched tenam"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth == date.getMonth() &&
                              CurrentDate < date.getDate()
                            ) {
                              noAvailable = true;
                              var timeString =
                                value + ":00" + (currtime >= 12 ? "pm" : "am");
                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched tenam"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (
                              CurrentYear == date.getFullYear() &&
                              CurrentMonth < date.getMonth()
                            ) {
                              noAvailable = true;

                              return (
                                <>
                                  <div className="display-time">
                                    <div
                                      className="time__"
                                      ng-className="{'time-active': edittime.active}"
                                    >
                                      <input
                                        type="text"
                                        className="time-input ng-pristine ng-valid ng-not-empty ng-touched tenam"
                                        ng-model="edittime.input"
                                        ng-keydown="changeInputTime($event)"
                                        ng-focus="edittime.active = true; edittime.digits = [];"
                                        ng-blur="edittime.active = false"
                                        data-id={timeString}
                                        onClick={(e) => {
                                          setCoachCounsellorId(timeString);
                                        }}
                                        // id="tenam"
                                      />

                                      <div className="formatted-time ng-binding">
                                        {timeString}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            } else {
                            }
                          })}
                          {noAvailable ? (
                            ""
                          ) : (
                            <div className="slot text-center">
                              <strong> No slot available </strong>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figcaption className="details-box p-3">
                <div className="bttns text-right">
                  <a className="applybtn bggreadent">
                    <span
                      className="btn btn-success font-weight-bold resolve-btn"
                      onClick={(e) => handleAppointment(coach?.user_id)}
                    >
                      submit
                    </span>
                  </a>
                </div>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoachDetails;
