import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  applyNoAction,
  UniversityAction,
  UniversityCourseAction,
} from "../../redux/common/action";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";
import UniversitiesDetails from "./UniversitiesDetails";
import Program from "../Program/Program";
import Reviews from "../Reviews/Reviews";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { URL } from "../../redux/common/url";
import Seo from "../Seo/Seo";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Admission from "../Admission/Admission";
import Ranking from "../Ranking/Ranking";
import { Helmet } from "react-helmet-async";
import Breadcrumb from "../Breadcrumb";

function AboutUniversity() {
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const {slug} = useParams();
  const history = useHistory();
  const [like, setLike] = useState(false);
  const [programred, setprogramred] = useState(false);
  const baseurl = URL.API_BASE_URL;
  const uniSlug: any = useParams();
  //   const university: any = useSelector((state: any) =>
  //   state.unversity.universityById ? state.unversity.universityById : []
  // );
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const isAuthenticated: any = getToken();
  var appliedCourseCount = 0;
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const urlsforShare = window.location.href;
  const getAllaplicant: any = useSelector((state: any) =>
    state.applyNow.getAllaplicant ? state.applyNow.getAllaplicant : []
  );
  var userInterests:any = localStorage.getItem("userInterest");
  if (userInterests) {
    userInterests = JSON.parse(userInterests);
  }

  const filteraplicant =
    getAllaplicant &&
    getAllaplicant?.filter((data: any) => {
      return data?.student_id == user?.id;
    });

  const university: any = useSelector((state: any) =>
    state.unversity.universityBySlug ? state.unversity.universityBySlug : []
  );

  const uniLike: any = useSelector((state: any) =>
    state.unversity.uniLike ? state.unversity.uniLike : []
  );
  const uniLikeUnlike: any = useSelector((state: any) =>
    state.unversity.uniLikeDislike ? state.unversity.uniLikeDislike : []
  );

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    toast.success("Copied!");
  }

  const oneTime =
    filteraplicant &&
    filteraplicant?.filter((data: any) => {
      return data?.university_id == university?.id;
    });

  const applyOneTime = (e: any) => {
    if (oneTime?.length > 0) {
      toast.warning("You can apply one time");
    } else {
      history.push("/applynow");
    }
  };

  useEffect(() => {
    dispatch(applyNoAction.getallapplicant({}));
    return () => {};
  }, []);

  const id: any = useLocation();

  const [universityDetails, setuniversityDetails] =
    useState<any>("about-univercity");

  const hendleLike = (e: any) => {
    var Data = {
      id: user?.id,
      student_id: user?.id,
      university_id: university?.id,
      mobile: user?.mobile,
    };
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      dispatch(UniversityAction.universityLikeDislike(Data, user?.id));
    }
  };

  useEffect(() => {
    dispatch(UniversityAction.getUniversityBySlug(uniSlug?.slug));
    return () => {};
  }, [uniLikeUnlike]);

  useEffect(() => {
    var Data = {
      id: user?.id,
      university_id: university?.id,
      mobile: user?.mobile,
    };
    dispatch(UniversityAction.universityLike(Data, user?.id));
    return () => {};
  }, [uniLikeUnlike]);

  const appliedCourses: any = useSelector((state: any) =>
    state.unversity.appliedCoursesFrontendUniStud
      ? state.unversity.appliedCoursesFrontendUniStud
      : []
  );

  const banners =
    typeof university?.UniversityDetail?.banners == "string"
      ? JSON.parse(university?.UniversityDetail?.banners)
      : "";

  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/counsellor-and-mentor");
    }
  };
  const handleApply = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    }
  };

  const website =
    university?.UniversityDetail?.website &&
    university?.UniversityDetail?.website?.includes("http")
      ? university?.UniversityDetail?.website
      : "https://" + university?.UniversityDetail?.website;

  const uni = (e: any, data: any) => {
    setuniversityDetails(data.data);
  };

  //   const universityId = id?.state?.university_id

  var slidercont = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  var IsAvailability:any = false;
  if (userInterests) {
    if (university?.country_id && userInterests.length > 0) {
      userInterests[0]?.country_id?.map((data: any, i: any) => {
        if (data.countryId == university?.country_id) {
          IsAvailability = true;
        }
      });
    }
  }
  const getUniverSityTitle = (uName:any) => {
    return `${uName || ""} : Admissions, Courses, Fees, Scholarships with Admissify`
  }
  const getUniverSityDes = (uName:any) => {
    return `Get detailed information on ${uName} courses, fees, eligibility, scholarships, and more with Admissify. Find out about our admission process and explore the possibilities of studying at ${uName?.split(" ")[0]}’s.`
  }
  return (
    <div>
      <Helmet
         script={[{ 
            type:"application/ld+json", 
            class :"jsx-492706fe178c42c2",
            innerHTML: JSON.stringify(
              {
                "@context":"http://schema.org",
                "@type":"CollegeOrUniversity",
                "url":window.location.href || "",
                "name":university?.UniversityDetail?.university_name || "",
                "logo":university?.university_logo != null ? baseurl + university?.university_logo : "",
                "description":getUniverSityDes(university?.UniversityDetail?.university_name || university?.university_name),
                "address":{
                  "@type":"PostalAddress",
                  "name":university?.UniversityDetail?.address || ""
                },
                "image":{
                  "@type":"ImageObject",
                  "url":university?.UniversityDetail?.banners != null ? baseurl + banners : ""
                },
                "aggregateRating":{
                  "@type":"AggregateRating",
                  "reviewCount":university?.review_count || 0,
                  "ratingValue":university?.rating || 0
                }
              }              
            )
          }]} 
        />
      <Seo 
      slug={window.location.pathname}
      title={getUniverSityTitle(university?.UniversityDetail?.university_name || university?.university_name)}
      meta_description={getUniverSityDes(university?.UniversityDetail?.university_name || university?.university_name)}
      meta_keywords={""}
      modified_time={university?.UniversityDetail?.updated_at || university?.UniversityDetail?.created_at}
      image={university?.UniversityDetail?.banners != null ? baseurl + banners : ""}
      />
      <section
        className="headslider"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="sliderbox">
          <div className="slidercont uni-details">
            <Slider {...slidercont}>
              <div className="wrapbx">
                {university?.UniversityDetail?.banners != null ? (
                  <img
                    className="scale hide_desktop"
                    src={baseurl + banners}
                    alt=""
                  />
                ) : (
                  <img
                    src="/assets/img/noImage.jpeg"
                    className="jsx-2737925295 college-img hide_desktop"
                    style={{ height: "50vh", width: "auto" }}
                  />
                )}

                {university?.UniversityDetail?.banners != null ? (
                  <img
                    className="scale show_mobile"
                    src={baseurl + banners}
                    alt=""
                  />
                ) : (
                  <img
                    src="/assets/img/noImage.jpeg"
                    className="jsx-2737925295 college-img show_mobile"
                    style={{ height: "50vh", width: "auto" }}
                  />
                )}
                <div className="uni_banner_upr">
                  <div className="content">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="textwrp">
                            <h1 className="sliderH1">
                              {" "}
                              {university?.university_name &&
                                university?.university_name}
                            </h1>
                            {university?.university_name && (
                              <p>
                                Check whether {university?.university_name} is
                                right for you as an international student.
                              </p>
                            )}
                            <div className="btncwrp">
                              {(() => {
                                if (user?.id) {
                                  return (
                                    <a
                                      onClick={(e) => {
                                        uni(e, { data: "program" });
                                      }}
                                      className="awe-btnbook text-center"
                                      disabled={
                                        IsAvailability == true ||
                                        appliedCourses?.data?.length >= 3
                                      }
                                    >
                                      {IsAvailability == true &&
                                      appliedCourses?.data?.length >= 3
                                        ? "applied"
                                        : "Apply Now"}
                                    </a>
                                  );
                                } else {
                                  return (
                                    <a
                                      onClick={handleApply}
                                      className="awe-btnbook text-center"
                                    >
                                      Apply Now
                                    </a>
                                  );
                                }
                              })()}

                              <a
                                onClick={handleFreeCoaching}
                                className="awe-btnbook counselling text-center"
                              >
                                {" "}
                                get free counselling
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="wrapbx">
                <img
                  className="scale hide_desktop"src={baseurl + banners}
                  alt=""
                />
                <img
                  className="scale show_mobile"src={baseurl + banners}
                  alt=""
                />
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="textwrp">
                          <h2> {university?.university_name && university?.university_name}</h2>
                         {university?.university_name && <p>
                            Check whether {university?.university_name } is right
                            for you as an international student.
                          </p>}
                          <div className="btncwrp text-center">
                            <a onClick={applyOneTime} className="awe-btnbook">
                            {oneTime?.length > 0? "applied" : "Apply Now"} 
                            </a> 
                            <a onClick={handleFreeCoaching} className="awe-btnbook counselling"> get free counselling</a> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="wrapbx">
              <img
                  className="scale hide_desktop"
                  src={baseurl + banners}
                  alt=""
                />
                <img
                  className="scale show_mobile"
                  src={baseurl + banners}
                  alt=""
                />
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="textwrp">
                          <h2> {university?.university_name && university?.university_name}</h2>
                         {university?.university_name && <p>
                            Check whether {university?.university_name } is right
                            for you as an international student.
                          </p>}
                          <div className="btncwrp text-center">
                            <a onClick={applyOneTime} className="awe-btnbook">
                            {oneTime?.length > 0? "applied" : "Apply Now"} 
                            </a> 
                            <a onClick={handleFreeCoaching} className="awe-btnbook counselling"> get free counselling</a>

                             
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Slider>
          </div>
          <div className="wishlisttop">
            <ul className="info text-center list-inline">
              <li>
                <button
                  className={
                    university?.id == uniLike[0]?.university_id
                      ? "wishlist active"
                      : "wishlist"
                  }
                  onClick={(e) => hendleLike(like)}
                  type="button"
                  tabIndex={0}
                >
                  <i className="fa fa-heart"></i>
                </button>
              </li>
              <li>
                <button
                  className="wishlist"
                  type="button"
                  onClick={onOpenModal}
                  tabIndex={0}
                >
                  <i className="fa fa-share-alt"></i>
                </button>
              </li>
            </ul>
          </div>
          <div className="starbtnsite">
            <ul className="info">
              <li>
                <div className="wishlist active">
                  <i className="fa fa-star"></i>
                  {university?.rating == null
                    ? "0"
                    : university?.rating?.toFixed(1)}
                  /5
                </div>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    uni(e, { data: "reviews" });
                  }}
                  className="review"
                >
                  {university?.review_count} reviews
                </a>
              </li>
              <li>
                <a
                  href={website}
                  target="_blank"
                  className="site"
                  style={{
                    pointerEvents:
                      university?.UniversityDetail?.website ? undefined : "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.581"
                    height="23.076"
                    viewBox="0 0 25.581 23.076"
                  >
                    <path
                      id="Icon_awesome-globe"
                      data-name="Icon awesome-globe"
                      d="M16.68,7.328C15.961,3.353,14.266.563,12.293.563S8.625,3.353,7.906,7.328ZM7.534,11.6A25.078,25.078,0,0,0,7.7,14.449h9.185a25.078,25.078,0,0,0,.164-2.849,25.078,25.078,0,0,0-.164-2.849H7.7A25.078,25.078,0,0,0,7.534,11.6ZM23.629,7.328a11.956,11.956,0,0,0-7.832-6.3,14.08,14.08,0,0,1,2.478,6.3ZM8.784,1.025a11.947,11.947,0,0,0-7.827,6.3H6.31a14.021,14.021,0,0,1,2.473-6.3ZM24.16,8.752H18.474a25.815,25.815,0,0,1,.164,2.849,25.815,25.815,0,0,1-.164,2.849h5.681a9.9,9.9,0,0,0,.426-2.849A10.07,10.07,0,0,0,24.16,8.752ZM5.948,11.6a25.815,25.815,0,0,1,.164-2.849H.426a9.73,9.73,0,0,0,0,5.7H6.107A27.089,27.089,0,0,1,5.948,11.6Zm1.958,4.273c.719,3.975,2.414,6.765,4.387,6.765s3.668-2.791,4.387-6.765Zm7.9,6.3a11.967,11.967,0,0,0,7.832-6.3H18.281A14.08,14.08,0,0,1,15.8,22.176ZM.957,15.873a11.956,11.956,0,0,0,7.832,6.3,14.08,14.08,0,0,1-2.478-6.3H.957Z"
                      transform="translate(0.5 -0.063)"
                      stroke="#444"
                      strokeWidth="1"
                    />
                  </svg>
                </a>
                <a
                  href={
                    university?.UniversityDetail?.brochure == "" ||
                    university?.UniversityDetail?.brochure == null
                      ? "javascript:void(0)"
                      : university?.UniversityDetail?.brochure &&
                        baseurl + university?.UniversityDetail?.brochure
                  }
                  target=""
                  download
                  className={
                    university?.UniversityDetail?.brochure == "" ||
                    university?.UniversityDetail?.brochure == null
                      ? "awe-btnbook download brochuredis site download"
                      : "awe-btnbook download site download"
                  }
                >
                  {" "}
                  brochure{" "}
                  <i className="iconx">
                    <img src="/assets/img/icons/download.svg" />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="navsecond">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-9">
              <ul className="nav2">
                <li
                  className={
                    universityDetails == "about-univercity" ? "active" : ""
                  }
                >
                  <a
                    onClick={(e) => {
                      uni(e, { data: "about-univercity" });
                    }}
                  >
                    about university
                  </a>
                  {/* <Link to={"/universitiesdetails"}>about university</Link> */}
                </li>
                <li className={universityDetails == "program" ? "active" : ""}>
                  <a
                    onClick={(e) => {
                      uni(e, { data: "program" });
                    }}
                  >
                    programs
                  </a>
                  {/* <Link to={"/program"}>programs</Link> */}
                </li>
                <li
                  className={universityDetails == "admission" ? "active" : ""}
                >
                  <a
                    onClick={(e) => {
                      uni(e, { data: "admission" });
                    }}
                  >
                    admission
                  </a>
                </li>
                <li className={universityDetails == "ranking" ? "active" : ""}>
                  <a
                    onClick={(e) => {
                      uni(e, { data: "ranking" });
                    }}
                  >
                    ranking
                  </a>
                </li>
                <li className={universityDetails == "reviews" ? "active" : ""}>
                  <a
                    onClick={(e) => {
                      uni(e, { data: "reviews" });
                    }}
                  >
                    reviews
                  </a>
                </li>
                {/* <li>
                  <a href="">placements</a>
                </li> */}
              </ul>
            </div>
            <div className="col-md-12 col-lg-3 text-right">
              <p className="appfees">
                application fees: <span>{university?.fee_currency_code}</span>{" "}
                {university?.application_fee}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="unishareIcon">
          <FacebookShareButton
            url={urlsforShare}
            // quote={blog?.heading}
          >
            <FacebookIcon size={26} round={true} onClick={onCloseModal} />
          </FacebookShareButton>

          <TwitterShareButton url={urlsforShare}>
            <TwitterIcon size={26} round={true} onClick={onCloseModal} />
          </TwitterShareButton>

          <LinkedinShareButton url={urlsforShare}>
            <LinkedinIcon size={26} round={true} onClick={onCloseModal} />
          </LinkedinShareButton>
          <WhatsappShareButton url={urlsforShare}>
            <WhatsappIcon size={26} round={true} />
          </WhatsappShareButton>
        </div>

        <div className="shareLinkInModel">
          {" "}
          <p>
            <b>{urlsforShare}</b>
          </p>
        </div>

        <div>
          <div
            className="sharebtncos"
            onClick={() => {
              copy();
              onCloseModal();
            }}
          >
            {!copied ? "Copy link" : "Copied!"}
          </div>
        </div>
      </Modal>
      <div>
      <Breadcrumb page={"University"} data={{slug: university?.university_name, path: "/universities"}}/>
      </div>
      {universityDetails == "about-univercity" ? (
        <UniversitiesDetails
          universityDetails={universityDetails}
          setuniversityDetails={setuniversityDetails}
          course_id_dt={id?.state?.course_id_dt}
          course_level_dt={id?.state?.course_level_dt}
        />
      ) : (
        ""
      )}
      {universityDetails == "program" ? (
        <Program
          course_id_dt={id?.state?.course_id_dt}
          course_level_dt={id?.state?.course_level_dt}
        />
      ) : (
        ""
      )}
      {universityDetails == "reviews" ? <Reviews /> : ""}
      {universityDetails == "admission" ? <Admission /> : ""}
      {universityDetails == "ranking" ? <Ranking /> : ""}
    </div>
  );
}

export default AboutUniversity;
