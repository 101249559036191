import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CounsellorList from "../CounsellorList/CounsellorList";
import { Link, useHistory } from "react-router-dom";
import Seo from "../../Component/Seo/Seo";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import {
  counselloerAction,
  coachAction,
  countryAction,
  UniversityAction,
} from "../../redux/common/action";
import Breadcrumb from "../Breadcrumb";
function TermsAndConditions() {
  const history = useHistory();
  const [available, setavailable] = useState<any[]>([]);
  const [availableid, setavailableid] = useState<any[]>([]);
  const [fees, setfees] = useState<any[]>([]);
  const [feesid, setfeesid] = useState<any[]>([]);
  const [experience, setexperience] = useState<any[]>([]);
  const [experienceid, setexperienceid] = useState<any[]>([]);
  const [country, setcountry] = useState<any[]>([]);
  const [countryid, setcountryid] = useState<any[]>([]);
  const [availableOpne, setavailableOpne] = useState<any>(false);
  const [feesOpne, setfeesOpne] = useState<any>(false);
  const [experienceOpne, setexperienceOpne] = useState<any>(false);
  const [countryOpne, setcountryOpne] = useState<any>(false);
  const hendlecountryOpne = (e: any) => {


    if (countryOpne == true) {
      setcountryOpne(false)
    } else {
      setcountryOpne(true)
    }

  }

  const hendleavailableOpne = (e: any) => {


    if (availableOpne == true) {
      setavailableOpne(false)
    } else {
      setavailableOpne(true)
    }

  }
  const hendlefeesOpne = (e: any) => {


    if (feesOpne == true) {
      setfeesOpne(false)
    } else {
      setfeesOpne(true)
    }

  }
  const hendleexperienceOpne = (e: any) => {


    if (experienceOpne == true) {
      setexperienceOpne(false)
    } else {
      setexperienceOpne(true)
    }

  }


  const [counsellorandcoach, setcounsellorandcoach] =
    useState<any>("counsellor");
  const dispatch = useDispatch();

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );
  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );

  const coach: any[] = useSelector((state: any) =>
    state.coach.couchList ? state.coach.couchList : []
  );

  const coun = (e: any, data: any) => {
    setcounsellorandcoach(data.data);
  };

  const shortBy = (e: any) => {
    console.log(e);

    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: e,
        offset: 0,
        limit: 10,
      })
    );
    dispatch(
      coachAction.getcoachinfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: e,
        offset: 0,
        limit: 10,
      })
    );
    return () => { };
  };

  // useEffect(() => {
  //   dispatch(counselloerAction.getAllCounsellor({}));
  //   return () => {};
  // }, []);

  useEffect(() => {
    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(countryAction.getAllCountry({}));
    return () => { };
  }, []);

  // useEffect(() => {
  //   dispatch(coachAction.getAllcoach({}));
  //   return () => {};
  // }, []);

  useEffect(() => {
    dispatch(
      coachAction.getcoachinfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 10,
      })
    );
    return () => { };
  }, []);

  const hendlClickAvailable = (event: any) => {
    let array = [...available];
    let arrayid = [...availableid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.value);
      setavailable(array);
      setavailableid(arrayid);
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setavailable(array);
        setavailableid(arrayid);
      }
    }
  };

  const remove = (event: any, value: any) => {
    let array = [...available];
    let arrayid = [...availableid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setavailable(array);
      setavailableid(arrayid);
    }
  };



  const hendlClickfees = (event: any) => {
    let array = [...fees];
    let arrayid = [...feesid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setfees(array);
      setfeesid(arrayid);
      console.log(arrayid?.length);

      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => { };
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setfees(array);
        setfeesid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: "",
            rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: "",
            rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 10,
          })
        );
        return () => { };
      }
    }
  };

  const removefees = (event: any, value: any) => {
    let array = [...fees];
    let arrayid = [...feesid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setfees(array);
      setfeesid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => { };
    }
  };

  const hendlClickexperience = (event: any) => {
    let array = [...experience];
    let arrayid = [...experienceid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setexperience(array);
      setexperienceid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => { };
    } else {
      let coun = array.indexOf(event.target.value);
      console.log(coun);

      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setexperience(array);
        setexperienceid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            rate_per_hr: "",
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            rate_per_hr: "",
            preferred_country: [],
            sort_by: "",
            offset: 0,
            limit: 10,
          })
        );
        return () => { };
      }
    }
  };

  const removeexperience = (event: any, value: any) => {
    let array = [...experience];
    let arrayid = [...experienceid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setexperience(array);
      setexperienceid(arrayid);
      console.log();

      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => { };
    }
  };

  const hendlClickCountry = (event: any) => {
    let array = [...country];
    let arrayid = [...countryid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setcountry(array);
      setcountryid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => { };
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setcountry(array);
        setcountryid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: "",
            rate_per_hr: "",
            preferred_country: arrayid,
            sort_by: "",
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: "",
            rate_per_hr: "",
            preferred_country: arrayid,
            sort_by: "",
            offset: 0,
            limit: 10,
          })
        );
        return () => { };
      }
    }
  };

  const removecountry = (event: any, value: any) => {
    let array = [...country];
    let arrayid = [...countryid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setcountry(array);
      setcountryid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: "",
          offset: 0,
          limit: 10,
        })
      );
      return () => { };
    }
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  useEffect(() => {
    window?.scrollTo(0, 0);
    })

  return (
    <>
      <Seo slug={window.location.pathname}/>
      <div>
        <section className="t-banner">
          <div className="t-banner-img">
            <img src="assets/img/contact-banner.jpg"></img>
            <div className="container">
              <div className="tag-l">
                <h1>about us</h1>
                <p>admissify is the new name in the world of Immigration and Abroad Learning facilities. We empower young aspirants to fly high successfully.</p>
              </div>
            </div>
          </div>
        </section>
        <div className="container"><Breadcrumb page={"About us"} data={{slug: "", path: ""}}/></div>
        <div className="headingmains text-center ofc_add">
          <h4 className="titlewithline course-head m-4">
            welcome to admissify
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="43.032" height="35.894" viewBox="0 0 43.032 35.894">
                <path
                  id="mortarboard"
                  d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                  transform="translate(0 -42.469)"
                  fill="#fbb415"
                ></path>
              </svg>
            </span>
          </h4>
        </div>
        <section className="header-footer-content">
          <div className="container">
        {seo?.header_content && (
                  <div className="about-uni-text shade1">
                    <SeoHeaderContent />
                  </div>
                )}
                </div>
        </section>
        <section className="about-c">
          <div className="container">
            <div className="row">
              <div className="about-cont shade1">
                <p>
                  admissify is changing the face of the Overseas Education Consultancy experience.
                  Our revolutionary web platform works in conjunction with the innovative mobile application,
                  available on iOS & android, to empower students to pursue their ambition of studying abroad - tailored
                  exactly to fit the individual requirements for assistance & guidance.
                </p><br />
                <p>
                  The admissify platform is home to over 2000 universities and over 60,000 courses across all popular international study destinations. The unique ‘Intelli-Search’ feature is an amazing tool to research & shortlist best fit universities and courses according to your exact circumstances, such as academic scores, budget, country, scholarships, intake time and more.
                </p><br />
                <p>
                  The most exclusive facet of all is infact the people behind the technology – Our Internationally Certified counsellors, country expert immigration lawyers, experienced accommodation agents and industry leading advisory panel from across the world, makes the admissify team an unparalleled asset to the aspiring achievers of tomorrow.
                </p><br />
                <p>admissify also uniquely has offices in many major global cities, from which we provide exclusive accommodation options and post arrival services which were a long awaited finally & here</p>
              </div>
            </div>
          </div>
        </section>
        <section className="ad-exc">
          <div className="headingmains text-center ofc_add">
            <h4 className="titlewithline course-head m-4">
              admissify exclusives
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="43.032" height="35.894" viewBox="0 0 43.032 35.894">
                  <path
                    id="mortarboard"
                    d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                    transform="translate(0 -42.469)"
                    fill="#fbb415"
                  ></path>
                </svg>
              </span>
            </h4>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-2 col-md-4 col-lg-2">
                <div className="exc-tab exclusives">
                  <span className="icon-circle">
                    <img className="scale" src="assets/img/app-icon1.png" alt="" />
                  </span>
                  <p>Admissify’s 1 for All Application system is a single & easy in-app e-form with free document checking & processing</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 col-md-4 col-lg-2">
                <div className="exc-tab exclusives">
                  <span className="icon-circle">
                    <img className="scale" src="assets/img/app-icon2.png" alt="" />
                  </span>
                  <p>Internationally Certified Counsellors available to chat in app anytime for experienced advice</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 col-md-4 col-lg-2">
                <div className="exc-tab exclusives">
                  <span className="icon-circle">
                    <img className="scale" src="assets/img/online-counseling.png" alt="" />
                  </span>
                  <p>Specialist Level 2 International counselling by the World’s top school alumni & ex admissions tutors</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 col-md-4 col-lg-2">
                <div className="exc-tab exclusives">
                  <span className="icon-circle">
                    <img className="scale" src="assets/img/appli-track.png" alt="" />
                  </span>
                  <p>
                    In-app tracker provides exact document status & application progress for every uni <br />
                    application
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 col-md-4 col-lg-2">
                <div className="exc-tab exclusives">
                  <span className="icon-circle">
                    <img className="scale" src="assets/img/visa.png" alt="" />
                  </span>
                  <p>Visa Specialist Immigration Lawyers for country specific guidance with verified high success rates</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 col-md-4 col-lg-2">
                <div className="exc-tab exclusives">
                  <span className="icon-circle">
                    <img className="scale" src="assets/img/financial.png" alt="" />
                  </span>
                  <p>Financial services from leading institutions at exclusive rates for student loans, funding & currency exchange</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="categories section appbg aos-init aos-animate" data-aos="fade-left" data-aos-duration="1500">
          <div className="container">
            <div className="col-md-8 sub-section ">
              <div className="headingmains text-left">
                <h2>Download the <img className="scale" src="assets/img/logonews1-app.png" /> app on</h2>
                <div className="appdown">
                  <h3>Apply from over
                    3000+ Universities</h3>
                  <ul>
                    <li>
                      <a href={"https://apps.apple.com/in/app/admissify/id1235485892"} target="_blank">
                        <img className="scale" src="assets/img/app1.png" />
                      </a>
                    </li>
                    <li>
                      <a href={"https://play.google.com/store/apps/details?id=com.admissify"} target="_blank">
                        <img className="scale" src="assets/img/app2.png" />
                      </a>
                    </li>
                  </ul>
                </div>

              </div>


              <ul className="row cate-box w-100">
                <li className="job-categorynew">
                  <div>

                    <span className="icon-circle">
                      <img className="scale" src="assets/img/app-icon1.png" alt="" />
                    </span>
                    <h6 className="title">Smart Search</h6>
                    <p>Auto shortlist from 3000+
                      top universities</p>
                  </div>
                </li>

                <li className="job-categorynew">
                  <div>

                    <span className="icon-circle">
                      <img className="scale" src="assets/img/app-icon2.png" alt="" />
                    </span>
                    <h6>Chat</h6>
                    <p>Chat with best
                      alumni counselors</p>
                  </div>
                </li>
                <li className="job-categorynew">
                  <div>

                    <span className="icon-circle">
                      <img className="scale" src="assets/img/app-icon3.png" alt="" />
                    </span>
                    <h6 className="title">Track </h6>
                    <p>Track status of live
                      applications</p>
                  </div>
                </li>
                <li className="job-categorynew">
                  <div>

                    <span className="icon-circle">
                      <img className="scale" src="assets/img/app-icon4.png" alt="" />
                    </span>
                    <h6 className="title">Apply</h6>
                    <p>Use the admissify’s
                      common app e-form</p>

                  </div>
                </li>
                <li className="job-categorynew">
                  <div>

                    <span className="icon-circle">
                      <img className="scale" src="assets/img/app-icon5.png" alt="" />
                    </span>
                    <h6 className="title">News &amp; FAQs</h6>
                    <p>Scholarship, student and
                      immigration news</p>
                  </div>
                </li>



              </ul>


            </div>

          </div></section>

        <section className="aco-bg">
          <div className="headingmains text-center ofc_add">
            <h4 className="titlewithline course-head m-4">
              accommodation
              <span>
                <img src="assets/img/accom-icon.png" />
              </span>
            </h4>
          </div>
          <div className="container">
            <div className="row">
              <div className="aco-c">
                <p>admissify is the only Education Consultancy that provides specialist Student Accommodation Provisions in most/all of our study destinations worldwide. admissify is tied up with the best accommodation providers in the UK, USA, Australia and more, and with our own offices operating in the heart of their main cities, you are never without help and assistance, even after you leave the skies of India.</p>
              </div>

            </div>
          </div>
        </section>
        <div className="headingmains text-center ofc_add">
          <h4 className="titlewithline course-head">
            global presence
            <span>
              <img src="assets/img/globale-p.png" />
            </span>
          </h4>
        </div>
        <div className="container">
          <div className="row">
            <div className="aco-c">
              <p className="mb-2">Admissify’s International Roots and Presence in the UK and USA allows us to provide exclusive accommodation deals and many post arrival services, but beyond this we act as a safe and secure in- country presence, should any unforeseen assistance be required. We have legal professionals and other relevant professionals available in our International offices.</p>
              <p className="mb-2">We can also guide students about International Placement opportunities in your Study Destination Country including job and interview advice as well as CV optimisation, which can help to excel your path to success.</p>
              <p>We can also put you in touch with fellow students from across the South Asian Region studying in your Study Destination Country and provide information for events with great networking opportunities, helping you to build strong relationships and take your future forwards.</p>
            </div>

          </div>
        </div>
        <section className="about-coach mt-5">
          <div className="postwrap bluedark">
            <div className="headingmains text-center ofc_add">
              <h4 className="titlewithline course-head pt-20">
                coaching
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.5" height="34.382" viewBox="0 0 30.5 34.382">
                    <g id="Accept" transform="translate(-40 -8)">
                      <path id="Path_6334" data-name="Path 6334" d="M71.291,49.273H48V16H66.855l4.436,4.436Z" transform="translate(-7.445 -7.445)" fill="#fbb415"></path>
                      <path id="Path_6335" data-name="Path 6335" d="M320,16v4.436h4.436" transform="translate(-260.591 -7.445)" fill="#cca400"></path>
                      <g id="Group_4345" data-name="Group 4345" transform="translate(43.327 15.209)">
                        <path id="Path_6336" data-name="Path 6336" d="M88,112h17.745v2.218H88Z" transform="translate(-88 -112)" fill="#047475"></path>
                        <path id="Path_6337" data-name="Path 6337" d="M88,176h17.745v2.218H88Z" transform="translate(-88 -171.564)" fill="#047475"></path>
                        <path id="Path_6338" data-name="Path 6338" d="M88,240h17.745v2.218H88Z" transform="translate(-88 -231.127)" fill="#047475"></path>
                        <ellipse id="Ellipse_154" data-name="Ellipse 154" cx="5.5" cy="6" rx="5.5" ry="6" transform="translate(14.672 12.791)" fill="#047475"></ellipse>
                      </g>
                      <path id="Path_6339" data-name="Path 6339" d="M67.327,464H64.555a.555.555,0,0,0,0,1.109h2.773a.555.555,0,0,0,0-1.109Z" transform="translate(-22.336 -424.391)" fill="#047475"></path>
                      <path id="Path_6340" data-name="Path 6340" d="M129.109,464h-.555a.555.555,0,0,0,0,1.109h.555a.555.555,0,0,0,0-1.109Z" transform="translate(-81.9 -424.391)" fill="#047475"></path>
                      <path id="Path_6341" data-name="Path 6341" d="M80.555,107.327H98.3a.555.555,0,0,0,.555-.555v-2.218A.555.555,0,0,0,98.3,104H80.555a.555.555,0,0,0-.555.555v2.218A.555.555,0,0,0,80.555,107.327Zm.555-2.218H97.745v1.109H81.109Z" transform="translate(-37.227 -89.345)" fill="#fbb415"></path>
                      <path id="Path_6342" data-name="Path 6342" d="M80.555,171.327H98.3a.555.555,0,0,0,.555-.555v-2.218A.555.555,0,0,0,98.3,168H80.555a.555.555,0,0,0-.555.555v2.218A.555.555,0,0,0,80.555,171.327Zm.555-2.218H97.745v1.109H81.109Z" transform="translate(-37.227 -148.909)" fill="#fbb415"></path>
                      <path id="Path_6343" data-name="Path 6343" d="M80.555,235.327H98.3a.555.555,0,0,0,.555-.555v-2.218A.555.555,0,0,0,98.3,232H80.555a.555.555,0,0,0-.555.555v2.218A.555.555,0,0,0,80.555,235.327Zm.555-2.218H97.745v1.109H81.109Z" transform="translate(-37.227 -208.473)" fill="#fbb415"></path>
                      <path id="Path_6344" data-name="Path 6344" d="M91.091,296H80.555a.555.555,0,0,0,0,1.109H91.091a.555.555,0,1,0,0-1.109Z" transform="translate(-37.227 -268.036)" fill="#047475"></path>
                      <path id="Path_6345" data-name="Path 6345" d="M87.764,320H80.555a.555.555,0,0,0,0,1.109h7.209a.555.555,0,0,0,0-1.109Z" transform="translate(-37.227 -284.827)" fill="#047475"></path>
                      <path id="Path_6346" data-name="Path 6346" d="M81.664,345.109a.555.555,0,0,0,0-1.109H80.555a.555.555,0,0,0,0,1.109Z" transform="translate(-37.227 -312.709)" fill="#047475"></path>
                      <path id="Path_6347" data-name="Path 6347" d="M121.109,345.109a.555.555,0,0,0,0-1.109h-.555a.555.555,0,0,0,0,1.109Z" transform="translate(-74.455 -312.709)" fill="#047475"></path>
                      <path id="Path_6348" data-name="Path 6348" d="M70.5,34.064a6.663,6.663,0,0,0-6.1-6.631V12.991a.555.555,0,0,0-.162-.392L59.8,8.162A.555.555,0,0,0,59.409,8H40.555A.555.555,0,0,0,40,8.555V41.827a.555.555,0,0,0,.555.555H63.845a.555.555,0,0,0,.555-.555V40.695A6.663,6.663,0,0,0,70.5,34.064ZM59.964,9.893l2.543,2.543H59.964ZM41.109,41.273V9.109H58.855v3.882a.555.555,0,0,0,.555.555h3.882V27.432a6.655,6.655,0,0,0,0,13.262v.578Zm22.736-1.664a5.545,5.545,0,1,1,5.545-5.545A5.545,5.545,0,0,1,63.845,39.609Z" transform="translate(0 0)" fill="#fff"></path>
                      <path id="Path_6349" data-name="Path 6349" d="M343.6,352.162a.555.555,0,0,0-.784,0l-3.576,3.576-2.4-1.441a.555.555,0,0,0-.571.951l2.773,1.664a.555.555,0,0,0,.677-.083l3.882-3.882A.555.555,0,0,0,343.6,352.162Z" transform="translate(-275.481 -320.154)" fill="#fff"></path>
                    </g>
                  </svg>
                </span>
              </h4>
            </div>
            <div className="aco-c">
              <div className="container">
                <div className="row">
                  <p className="m-auto">Welcome to the future of top tier coaching. admissify brings you state of the art, fully interactive virtual classrooms, hosted by the best teaching faculty from across India the USA and UK.</p>
                  <div className="col-md-6">
                    <article className="postcard">
                      <img className="postcard__img coaching_img" src="assets/img/coaching_01.png" alt=""></img>
                    </article>
                  </div>
                  <div className="col-md-6">
                    <ul className="coach-l">
                      <li>We Specialize in GMAT, GRE, SAT & IELTS, TOEFL, PT training</li>
                      <li>Record lessons from the admissify app or virtual class to review & revise great learning anytime and at your convenience.</li>
                      <li>Manage your class schedule from the admissify app so you never miss a class again.</li>
                      <li>Experienced and highly qualified Professional teaching Faculty, for the best learning available.</li>
                      <li>Compatible with Apple, Android and PC devices</li>
                      <li>Request a demo lesson before booking to check it out! We’re confident you will love it!</li>
                    </ul>
                  </div>
                </div>
                <div className="all-center my-2">
                  <a href={"/coaching"} className="view-more readbtn mb-5">know more</a>
                </div>
              </div>
            </div>

          </div>
        </section>
        <section className="team">
          <div className="container">
            <section className="ad-exc">
              <div className="headingmains text-center ofc_add">
                <h4 className="titlewithline course-head m-4">
                  our experts
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="43.032" height="35.894" viewBox="0 0 43.032 35.894">
                      <path
                        id="mortarboard"
                        d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                        transform="translate(0 -42.469)"
                        fill="#fbb415"
                      ></path>
                    </svg>
                  </span>
                </h4>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="exc-tab exc-tab-box">
                      <p>
                        <strong>Our experts</strong> are comprised of distinguished alumni and former admissions officers from some of the best universities in the world. They have incredible experience and ability in helping
                        students achieve top admissions and scholarships at globally renowned universities
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="exc-tab exc-tab-box">
                      <p>
                        <strong>Our elite faculty </strong>for test preparation are highly professional and hold high achievement records so that you are guaranteed to achieve the best marks you can and subsequently the best
                        admission possible.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="exc-tab exc-tab-box">
                      <p>
                        <strong>Our advisory panel </strong>of Leading Business figures, Scientists and Professors can provide career or industry related advice relevant to your aspirations. They also proudly endorse admissify as
                        having the power of knowledge and all-round expertise to help you at every stage.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="exc-tab exc-tab-box">
                      <p>
                        <strong>Our counsellors</strong> are highly experienced and country specialised, having completed international training modules from London they are all Internationally certified and have proven track
                        records for student satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-item-wrapper counsellorlist coach_slider singleslider">
                <CounsellorList
                  available={available}
                  fees={fees}
                  experience={experience}
                  country={country}
                />
              </div>
            </section>
          </div>
        </section>
        <section className="header-footer-content">
          <div className="container">
        {seo?.footer_content && (
              <div className="about-uni-text shade1 mt-5">
            <SeoFooterContent />
              </div>
               )}
               </div>
        </section>

      </div>
    </>
  )
}
export default TermsAndConditions
