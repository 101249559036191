import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentAction, scholershipAndOfferAction } from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

function PaymentHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const baseurl = URL.API_BASE_URL;
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);


  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      window.location.href = "/login";
    } else {
      window.location.href = "/";
    }
  };


  // useEffect(() => {
  //   if (Object.keys(studentProfile).length <= 0) {
  //     dispatch(studentAction.getStudentById(user?.id));
  //     return () => { };
  //   }
  // }, []);

  const paymentHistory: any = useSelector((state: any) =>
    state.student.getPaymentHistory ? state.student.getPaymentHistory : []
  );

  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );
  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => { };
  }, []);

  useEffect(() => {
    if (Object.keys(studentProfile).length > 0 && user?.id) {
      var data = {
        "id": user?.id,
        "mobile": user?.mobile
      }
      dispatch(studentAction.paymentHistoryById(data));
    }
    return () => { };
  }, [studentProfile]);

  console.log(paymentHistory);

  return (
    <>
      <div className="col-md-12 col-lg-9">
        <div className="row">

          <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
            <div className="page-sidebar">
              {getOFferAndScholar &&
                getOFferAndScholar?.map((data: any, i: any) => {
                  return i < 2 && (
                    <div className="widget sidebar-title ads-blog">
                      <div key={i}>
                        <div className="offers-item_ yello title">
                          <a onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                            data-target="#offer-pop">
                            {data?.type == "Offers" ? (
                              data?.coupon_icon !== null ? (
                                <img
                                  className="scale"
                                  src={baseurl + data?.coupon_icon}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                />
                              )
                            ) : data?.sch_image !== null ? (
                              <img
                                className="scale"
                                src={baseurl + data?.sch_image}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/img/noImage.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className="scale"
                                src="/assets/img/noImage.jpeg"
                              />
                            )}
                          </a>
                          <figcaption className="content-box offerHeight_">
                            <a className="margin_auto" onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                              data-target="#offer-pop">
                              <h5>
                                {data?.type == "Offers" && (
                                  <span>
                                    {data?.coupon_type == 1
                                      ? 'Get ' + data?.max_off + "%"
                                      : 'Get ' + data?.max_off}{" "}
                                    OFF
                                  </span>
                                )}
                                {data?.type == "Offers"
                                  ? data?.coupon_title?.length > 16
                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                    : data?.coupon_title
                                  : data?.sch_title?.length > 40
                                    ? `${data?.sch_title.substring(0, 40)}.`
                                    : data?.sch_title}
                              </h5>
                            </a>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>





          <div className="col-md-12 col-lg-9">
            <div className="contentbx shade1 bg-white application_wrap h-100">
              <section className="categories">
                <div className="col-md-12 col-lg-12">
                  <div className="appli-t">
                    <h1>payments </h1>
                    <img src="/assets/img/appoint--icon.svg" />
                  </div>
                  {paymentHistory && paymentHistory?.data?.list?.length == 0 ? (
                    <div className="col-lg-12 p-2">
                      <div className="appli-box shade1">

                        <div className="col-md-12 appli-box-t">
                          <p>No payment found!</p>

                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="container mt-5">
                      {paymentHistory && paymentHistory?.data?.list?.length > 0 &&
                        paymentHistory?.data?.list?.map((data: any, i: any) => {

                          return (
                            <div className="mt-2 first-con1">
                              <div className="row revie-uni justify-content-between p-2">

                                <div className="col-md-4">
                                  <label className="mb-0"><strong>mentor/counsellor/service :</strong></label>
                                  <div className="mb-2">{data?.Appointment ? data?.Appointment?.Coach ? 'Mentor- ' + data?.Appointment?.Coach?.name : data?.Appointment?.Counsellor ? 'Counsellor- ' + data?.Appointment?.Counsellor?.name : 'N/A' : 'N/A'}</div>
                                </div>
                                <div className="col-md-4">
                                  <label className="mb-0"><strong>payment id :</strong></label>
                                  <div className="mb-2">
                                    {data?.payment_id ? data?.payment_id : 'N/A'}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <label className="mb-0"><strong>payment method :</strong></label>
                                  <div className="mb-2">{data?.payment_method ? data?.payment_method : 'N/A'}</div>
                                </div>
                                <div className="col-md-4">
                                  <label className="mb-0"><strong>payment status :</strong></label>
                                  <div className="mb-2">{data?.payment_status ? data?.payment_status : 'N/A'}</div>
                                </div>

                                <div className="col-md-4">
                                  <label className="mb-0"><strong>appointment id :</strong></label>
                                  <div className="mb-2">{data?.order_id ? 'appt-' + data?.order_id : 'N/A'}</div>
                                </div>
                                <div className="col-md-4">
                                  <label className="mb-0"><strong>amount :</strong></label>
                                  <div className="mb-2">{data?.amount ? '₹' + data?.amount : '0'}</div>
                                </div>
                                <div className="col-md-4">
                                  <label className="mb-0"><strong>created date :</strong></label>
                                  <div className="mb-2">{data?.createdAt ? moment(data?.createdAt).format('DD-MM-YYYY') : 'N/A'}</div>
                                </div>


                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default PaymentHistory;
