import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UniversityAction } from "../../redux/common/action";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import StarRatings from "react-star-ratings";
import { Rating } from 'react-simple-star-rating'
import { URL } from "../../redux/common/url";
import { Modal } from 'react-responsive-modal';
import { toast } from "react-toastify";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
function Admission() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const id: any = useLocation();
  const urlsforShare = window.location.href;
  const uniSlug: any = useParams()
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [rating, setRating] = useState<any>() // initial rating value
  const [comment, setcomment] = useState<any>({
    university_id: "",
    student_id: "",
  });
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const baseurl = URL.API_BASE_URL
  const universityComment: any[] = useSelector((state: any) =>
    state.unversity.universityComment ? state.unversity.universityComment : []
  );

  const universitybySlug: any = useSelector((state: any) =>
    state.unversity.universityBySlug ? state.unversity.universityBySlug : []
  );
  const handleRating = (rate: any) => {

    const rt = rate / 20
    setRating(rt)
    // other logic
  }
  comment.rating = rating


  const otherUni = (e: any) => {

    history.push("/university/" + e, {
      university_id: e,
    });
    dispatch(UniversityAction.getUniversityBySlug(e));
    return () => { };

  }

  const updateCommentFilter = universityComment[0]?.reviews && universityComment[0]?.reviews?.filter((data: any) => {
    return data?.student_id == user?.id ? user?.id : "";
  })

  const upcom = updateCommentFilter?.length > 0 ? updateCommentFilter[0]?.comments : ""



  const university: any = useSelector((state: any) =>
    state.unversity.universityById ? state.unversity.universityById : []
  );

  const universities: any[] = useSelector((state: any) =>
    state.unversity.universities.data ? state.unversity.universities.data : []
  );



  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    toast.success("Copied!");

  }





  comment.university_id = universitybySlug?.id
  comment.student_id = user?.id




  useEffect(() => {
    dispatch(UniversityAction.getUniversityBySlug(uniSlug?.slug));
    return () => { };
  }, [uniSlug?.slug, universityComment]);


  useEffect(() => {
    dispatch(
      UniversityAction.getuniversities({
        search_by: "",
        offset: 0,
        country_id: [universitybySlug?.country_id],
        limit: 6,
      })
    );
    return () => { };
  }, [university]);


  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  var slidercont = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="unishareIcon">
          <FacebookShareButton
            url={urlsforShare}
          // quote={blog?.heading}
          >
            <FacebookIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </FacebookShareButton>

          <TwitterShareButton
            url={urlsforShare}
          >
            <TwitterIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </TwitterShareButton>

          <LinkedinShareButton
            url={urlsforShare}
          >
            <LinkedinIcon
              size={26}
              round={true}
              onClick={onCloseModal}
            />
          </LinkedinShareButton>
        </div>
        <div className="shareLinkInModel"> <p><b>{urlsforShare}</b></p></div>
        <div>
          <div className="sharebtncos" onClick={(() => { copy(); onCloseModal(); })} >{!copied ? "Copy link" : "Copied!"}</div>
        </div>
      </Modal>
      <section className="uni-list-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-pull-10 order-lg-2">
              <div className="uni-sidebar">
                {/* <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center p-2">
                  <img src="/assets/img/university-icon.svg" alt="" />
                  <p>
                    <small>are you interested?</small>
                  </p>
                  <button type="button" className="btn simple" onClick={handleFreeCoaching} >
                    get free counselling
                  </button>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center p-2">
                  <p>
                    <small>
                      follow & share this college to get information about
                      admission.
                    </small>
                  </p>
                  <button type="button" className="btn simple wfollow" disabled style={{ cursor: "not-allowed" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.822"
                      height="10.18"
                      viewBox="0 0 12.822 11.18"
                    >
                      <g
                        id="Icon_feather-user-plus"
                        data-name="Icon feather-user-plus"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_6294"
                          data-name="Path 6294"
                          d="M9.561,25.893V24.762A2.208,2.208,0,0,0,7.411,22.5H3.649A2.208,2.208,0,0,0,1.5,24.762v1.131"
                          transform="translate(-1.5 -15.714)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6295"
                          data-name="Path 6295"
                          d="M11.049,6.762A2.208,2.208,0,0,1,8.9,9.025,2.208,2.208,0,0,1,6.75,6.762,2.208,2.208,0,0,1,8.9,4.5,2.208,2.208,0,0,1,11.049,6.762Z"
                          transform="translate(-4.869 -4.5)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6296"
                          data-name="Path 6296"
                          d="M30,12v3.393"
                          transform="translate(-19.79 -9.173)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                        <path
                          id="Path_6297"
                          data-name="Path 6297"
                          d="M28.724,16.5H25.5"
                          transform="translate(-16.902 -11.976)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        ></path>
                      </g>
                    </svg>{" "}
                    Follow
                  </button>{" "}
                  <button type="button" className="btn simple wshare" onClick={onOpenModal}>
                    <i className="fa fa-share-alt"></i> Share
                  </button>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5">
                  <div className="headgreenbg text-center">
                    colleges in {universitybySlug?.Country?.country_name && universitybySlug?.Country?.country_name}
                    {/* Dubai, UAE */}
                  </div>
                  <ul className="universitein">
                    {universities &&
                      universities?.map((data: any, i: any) => {
                        return data?.id !== universitybySlug.id ? (
                          <li key={i} onClick={(e) => { otherUni(data?.slug) }}>
                            <div className="imglogo" style={{ cursor: "pointer" }}>
                              {data.university_logo != null && data.university_logo ? <img src={baseurl + data.university_logo} alt="" /> : <img src="/assets/img/university-icon.png" alt="" />}
                            </div>
                            <div className="unitextwidth1">
                              <p className="__hover" style={{ cursor: "pointer" }}>
                                {data?.university_name?.length > 16 ? `${data?.university_name.substring(0, 16)}...` : data?.university_name} 
                                </p>
                              <p>
                                {/* <small>Dubai Knowledge Park,</small> */}
                              </p>
                            </div>
                            <div className="unitextwidth2">
                              <p>
                                <a href="">{data?.rating == null ? "0" : data?.rating?.toFixed(1)}</a>
                              </p>
                              <p>
                                <small>{data?.review_count && data?.review_count} review</small>
                              </p>
                            </div>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>

            <div className="col-lg-10 col-md-push-2">
              <div className="shade1 reviewstops">
                <div className="row">
                  <div className="col-12">
                    <div className="reviews_list">
                      <div className="reviews_item">
                        <div className='country-details mb-4 editor_css' style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: universitybySlug?.UniversityDetail?.university_admission ? universitybySlug?.UniversityDetail?.university_admission : "No content found!" }}></div>
                        <p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Admission;
