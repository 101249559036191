import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { courseAction, UniversityAction } from "../../redux/common/action";
import { useHistory, useLocation } from "react-router-dom";
import AdSlider from "../AdSlider/AdSlider";
import StarRatings from "react-star-ratings";
import MenuOutSideClick from "../../layout/header/outsideClick";
import { URL } from "../../redux/common/url";
import Seo from "../Seo/Seo";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { isMobile } from "react-device-detect";
import { number } from "../../validation/Validation";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
var userInt = false;
function UniversitiesList() {
  const history = useHistory();
  const lastLocation = useLocation();
  const dispatch = useDispatch();
  const { state }: any = useLocation();
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  var userInterests: any = localStorage.getItem("userInterest");
  const baseurl = URL.API_BASE_URL;
  const anchorRef1 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  const [smartSearch, setSmartSearch] = useState<any>(
    state?.isSmartSearch || false
  );
  const [urlChange, setUrlChange] = useState<any>(
    state?.isSmartSearch || false
  );
  const [isTrending, setIsTrending] = useState<any>(state?.is_trending || "");
  const [marks, setMarks] = useState<any>(state?.marks || "");
  const [intake, setIntake] = useState<any>(state?.intake || "");
  const [rank, setRank] = useState<any>(state?.rank || "");
  const [uniCourseId, setUniCourseId] = useState<any>(state?.course_id || "");
  const [uniCountryId, setUniCountryId] = useState<any>(
    state?.country_id ? state?.country_id[0] : ""
  );
  const [uniLevelId, setUniLevelId] = useState<any>(state?.course_level || "");
  const [countryName, setCountryName] = useState<any[]>([]);
  const [countryId, setCountryId] = useState<any[]>([]);
  const [courseName, setCourseName] = useState<any[]>([]);
  const [courseId, setCourseId] = useState<any[]>([]);
  const [courseLevel, setCourseLevel] = useState<any>(1);
  const [readMore, setreadMore] = useState<any>(1);
  const [loadmore, setloadmore] = useState<any>(50);
  const [offset, setoffset] = useState<any>(0);
  const [uniSearch, setUniSearch] = useState<any>(0);
  const [sort, setsort] = useState<any>();
  const [countryOpne, setcountryOpne] = useState<any>(isMobile ? true : false);
  const [courseOpne, setcourseOpne] = useState<any>(isMobile ? true : false);
  const [TotalCount, setTotalCount] = useState<any>(false);
  const university: any[] = useSelector((state: any) =>
    state.unversity.universityList ? state.unversity.universityList : []
  );
  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };

  const hendlecourseOpne = (e: any) => {
    if (courseOpne == true) {
      setcourseOpne(false);
    } else {
      setcourseOpne(true);
    }
  };

  let universities: any[] = useSelector((state: any) =>
    state.unversity.universities.data ? state.unversity.universities.data : []
  );
  const UniCount: any[] = useSelector((state: any) =>
    state.unversity.universities.totalCount
      ? state.unversity.universities.totalCount
      : 0
  );
  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );
  const allCourse_: any = useSelector((state: any) =>
    state.universitycourse.allCourse ? state.universitycourse.allCourse : []
  );
  const allCourseMain = allCourse_.sort((a: any, b: any) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );
  let allCourse = allCourseMain;

  const courseList =
    allCourse &&
    allCourse?.filter((data: any) => {
      return data.level == (courseLevel || 1);
    });
  const activecourse_ =
    courseList &&
    courseList?.filter((data: any) => {
      return data?.status === 1;
    });
  const activecourse = activecourse_.sort((a: any, b: any) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  const allActiveStreams: any = useSelector((state: any) =>
    state.stream.streamList ? state.stream.streamList : []
  );

  /* const Streme = allActiveStreams &&
    allActiveStreams.filter((data: any) => {
      return data?.course_level_id == (courseLevel || 1);
  }); */

  const steameList = allActiveStreams.sort((a: any, b: any) =>
    a?.stream_name > b?.stream_name
      ? 1
      : b?.stream_name > a?.stream_name
      ? -1
      : 0
  );
  function countrySlugById(Cid: any) {
    return countrys?.find((ele) => ele?.id == Cid)?.slug;
  }
  function blankUniData() {
    setSmartSearch(false);
    setIntake("");
    setMarks("");
    setRank("");
    /* setIsTrending(""); */
  }
  function blankUserInt() {
    userInt = false;
    setCountryId([]);
    setCountryName([]);
    setCourseId([]);
    setCourseName([]);
    setCourseLevel(1);
  }
  useEffect(() => {
    if (userInterests) {
      userInt = true;
      userInterests = JSON.parse(userInterests);
      if (user && userInterests != null) {
        userInterests[0]?.country_id?.map((data: any, i: any) => {
          if (!countryId.includes(data.countryId)) {
            countryName.push(data.countryName);
            countryId.push(data.countryId);
          }
        });
        userInterests[0]?.course_id?.map((data: any, i: any) => {
          if (!courseId.includes(data.courseId)) {
            courseName.push(data.courseName);
            courseId.push(data.courseId);
          }
        });
        setCourseLevel(userInterests[0]?.course_level);
      }
    }
  }, [userInterests]);

  const handleFreeCoaching = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      history.push("/counsellor-and-mentor");
    }
  };

  useEffect(() => {
    dispatch(UniversityAction.getAdBanner({ status: 1 }));
    return () => {};
  }, []);

  useEffect(() => {
    let payload = {};
    if (smartSearch) {
      payload = {
        search_by: "",
        country_id: uniCountryId ? [uniCountryId] : "",
        course_id: uniCourseId ? [uniCourseId] : "",
        course_level: uniLevelId || "",
        rank: rank,
        intake: intake,
        marks: marks,
        offset: 0,
        limit: 50,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    } else if (userInt) {
      payload = {
        country_id: countryId ? countryId : "",
        course_id: courseId ? courseId : "",
        course_level: courseLevel || "",
        offset: 0,
        limit: 50,
        is_trending: courseLevel || courseId || countryId ? "" : isTrending,
      };
    } else {
      payload = {
        offset: 0,
        limit: 50,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    }
    dispatch(UniversityAction.getuniversities(payload));
  }, []);

  const handleUnisearch = (e: any) => {
    e.preventDefault();
    setUrlChange(true);
    setsort("");
    blankUniData();
    blankUserInt();
    setUniSearch(1);
    userInt = false;
    let payload = {
      country_id: uniCountryId ? [uniCountryId] : "",
      course_id: uniCourseId ? [uniCourseId] : "",
      course_level: uniLevelId || "",
      offset: 0,
      limit: 50,
      is_trending: uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
    };
    dispatch(UniversityAction.getuniversities(payload));
    let countrySlug = countrySlugById(uniCountryId);
    let preurl = "/";
    if (countrySlug) {
      preurl += `${countrySlug}/`;
    }
    if (uniLevelId) {
      preurl += `${uniLevelId == 1 ? "ug" : "pg"}/`;
    }
    history.push(`${preurl}universities`);
  };
  const handleLoadMore = (e: any) => {
    let payload = {};
    if (smartSearch) {
      payload = {
        search_by: "",
        country_id: uniCountryId ? [uniCountryId] : "",
        course_id: uniCourseId ? [uniCourseId] : "",
        course_level: uniLevelId || "",
        rank: rank,
        intake: intake,
        marks: marks,
        offset: offset + 50,
        limit: 50,
        sort_by: sort,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    } else if (userInt) {
      payload = {
        country_id: countryId ? countryId : "",
        course_id: courseId ? courseId : "",
        course_level: courseLevel || "",
        offset: offset + 50,
        limit: 50,
        sort_by: sort,
        is_trending: countryId || courseId || courseLevel ? "" : isTrending,
      };
    } else if (uniSearch) {
      payload = {
        country_id: uniCountryId ? [uniCountryId] : "",
        course_id: uniCourseId ? [uniCourseId] : "",
        course_level: uniLevelId || "",
        offset: offset + 50,
        limit: 50,
        sort_by: sort,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    } else {
      payload = {
        offset: offset + 50,
        limit: 50,
        sort_by: sort,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    }
    setloadmore(loadmore + 50);
    setoffset(offset + 50);
    dispatch(UniversityAction.getuniversities(payload));
  };

  const callFilterApi = (courseid: any, countryid: any) => {
    userInt = true;
    setsort("");
    setUniSearch(0);
    setUrlChange(false);
    setSmartSearch(false);
    setUniCountryId("");
    setUniCourseId("");
    setUniLevelId("");
    dispatch(
      UniversityAction.getuniversities({
        search_by: "",
        country_id: countryid?.length > 0 ? countryid : "",
        course_id: courseid?.length > 0 ? courseid : "",
        offset: 0,
        limit: 50,
        is_trending: countryid?.length || courseid?.length ? "" : isTrending,
      })
    );
  };
  const hendlClickCountry = (event: any) => {
    let array = [...countryName];
    let arrayid = [...countryId];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setCountryName(array);
      setCountryId(arrayid);
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        arrayid.splice(coun, 1);
        array.splice(coun, 1);
        setCountryName(array);
        setCountryId(arrayid);
        {
          countryName?.length !== 0 ? (
            <span>
              {" "}
              selected <b>filters</b>{" "}
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          ) : courseName?.length !== 0 ? (
            <span>
              {" "}
              selected <b>filters</b>{" "}
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          ) : (
            <span>
              {" "}
              no filter selected
              <i className="fa fa-angle-up"></i>
            </span>
          );
        }
      }
    }
    callFilterApi(courseId, arrayid);
    history.push("/universities");
  };

  const remove = (event: any, value: any) => {
    let array = [...countryName];
    let arrayid = [...countryId];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setCountryName(array);
      setCountryId(arrayid);
    }
    callFilterApi(courseId, arrayid);
    history.push("/universities");
  };

  const hendlClickCourse = (event: any) => {
    /* blankUniData(); */
    setUniSearch(0);
    let array = [...courseName];
    let arrayid = [...courseId];
    if (event.target.checked) {
      arrayid.push(event.target.value);
      array.push(event.target.name);
      setCourseId(arrayid);
      setCourseName(array);
    } else {
      let coun = array.indexOf(event.target.name);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setCourseId(arrayid);
        setCourseName(array);
      }
    }
    callFilterApi(arrayid, countryId);
    history.push("/universities");
  };

  const removeCourse = (event: any, value: any) => {
    let array = [...courseName];
    let arrayid = [...courseId];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setCourseId(arrayid);
      setCourseName(array);
    }
    callFilterApi(arrayid, countryId);
    history.push("/universities");
  };
  const sortArray = (e: any) => {
    setsort(e);
    let payload = {};
    if (smartSearch) {
      payload = {
        search_by: "",
        country_id: uniCountryId ? [uniCountryId] : "",
        course_id: uniCourseId ? [uniCourseId] : "",
        course_level: uniLevelId,
        rank: rank,
        intake: intake,
        marks: marks,
        offset: 0,
        limit: 50,
        sort_by: e,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    } else if (userInt) {
      payload = {
        country_id: countryId ? countryId : "",
        course_id: courseId ? courseId : "",
        course_level: courseLevel || "",
        offset: 0,
        limit: 50,
        sort_by: e,
        is_trending: countryId || courseId || courseLevel ? "" : isTrending,
      };
    } else if (uniSearch) {
      payload = {
        country_id: uniCountryId ? [uniCountryId] : "",
        course_id: uniCourseId ? [uniCourseId] : "",
        course_level: uniLevelId,
        offset: 0,
        limit: 50,
        sort_by: e,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    } else {
      payload = {
        offset: 0,
        limit: 50,
        sort_by: e,
        is_trending:
          uniCourseId || uniLevelId || uniCountryId ? "" : isTrending,
      };
    }
    dispatch(UniversityAction.getuniversities(payload));
  };
  const filteruniver = (e: any) => {
    dispatch(
      UniversityAction.getuniversities({
        search_by: e.target.value,
        offset: 0,
        limit: 50,
      })
    );
    return () => {};
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  //   })

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        title=""
        meta_description=""
        meta_keywords=""
        modified_time=""
        image=""
      />
      <section
        className="headslider"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="sliderbox">
          <div className="slidercont owl-carousel111">
            <div>
              <img
                className="scale"
                src={"/assets/img/uni-list-header.png"}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 trakhistorypadding">
              <div className="uni_list_wrap">
                <div className="work">
                  <h1 className="head-gk3 text-center uni_search_title">
                    Search Study Abroad University
                  </h1>
                  <form>
                    {/* <!--Input area start--> */}
                    <div className="row">
                      <div className="col-md-12 col-lg-3">
                        <div className="form-group">
                          <label>search by university name</label>
                          <input
                            type="text"
                            ref={anchorRef1}
                            className="form-control"
                            placeholder="search"
                            autoComplete="off"
                            name="uni"
                            onChange={(e: any) => {
                              filteruniver(e);
                            }}
                          />
                          {anchor1 && (
                            <div
                              className="searchuniversities"
                              style={{ padding: "0px" }}
                            >
                              <ul>
                                {universities &&
                                  universities?.map((data: any, i: any) => {
                                    return (
                                      <li
                                        key={i}
                                        onClick={() => {
                                          history.push(
                                            "/university/" + data?.slug,
                                            {
                                              university_id: data?.id,
                                              course_level_dt: uniLevelId
                                                ? Number(uniLevelId)
                                                : "",
                                              course_id_dt: uniCourseId
                                                ? uniCourseId
                                                : "",
                                            }
                                          );
                                        }}
                                      >
                                        {data?.university_name}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-9">
                        <div className="row">
                          <div className="col-md-4">
                            <label>
                              <img src="/assets/img/country_icon.svg" /> country
                            </label>
                            <select
                              className="form-control"
                              name="country_id"
                              onChange={(e: any) => {
                                blankUniData();
                                setUniCountryId(e.target.value);
                              }}
                              value={uniCountryId}
                            >
                              <option value="">select country</option>
                              {countrys &&
                                countrys?.map((data: any, i: any) => {
                                  return (
                                    <option value={data?.id} key={i}>
                                      {data?.country_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label>
                              <img src="/assets/img/study_icon.svg" alt="" />{" "}
                              study level
                            </label>
                            <select
                              className="form-control study_level"
                              onChange={(e: any) => {
                                blankUniData();
                                setUniLevelId(e.target.value);
                                setUniCourseId("");
                              }}
                              value={uniLevelId}
                              name="course_level"
                            >
                              <option value="">study level</option>
                              <option value="1">UG</option>
                              <option value="2">PG</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label>
                              <img src="/assets/img/course_icon.svg" alt="" />{" "}
                              main course
                            </label>
                            <select
                              className="form-control main_course"
                              name="course_id"
                              onChange={(e: any) => {
                                blankUniData();
                                setUniCourseId(e.target.value);
                              }}
                              value={uniCourseId}
                            >
                              <option value="">select course</option>
                              {steameList &&
                                steameList?.map((data: any, i: any) => {
                                  if (uniLevelId == data?.course_level_id)
                                    return (
                                      <option key={i} value={data?.id}>
                                        {data?.stream_name}
                                      </option>
                                    );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="mt_btn_yellow unisearchbtn"
                        onClick={handleUnisearch}
                      >
                        search
                      </button>
                    </div>
                    {/* <!--Input area ok--> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {seo?.header_content && (
        <section className="mt-3">
          <div className="container">
            <div className="about-uni-text shade1">
              <SeoHeaderContent />
            </div>
          </div>
        </section>
      )}
      <section className="uni-list-page">
        <div className="container">
          <Breadcrumb page={"University"} data={{ slug: "", path: "" }} />
          <div className="row">
            <div className="col-lg-2 col-md-pull-10">
              <div className="page-sidebar">
                <div className="widget sidebar-title">
                  <h5>
                    {countryName?.length !== 0 ? (
                      <span>
                        {" "}
                        selected <b>filters</b>{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    ) : courseName?.length !== 0 ? (
                      <span>
                        {" "}
                        selected <b>filters</b>{" "}
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      </span>
                    ) : (
                      <span>
                        {" "}
                        no filter selected
                        <i className="fa fa-angle-down"></i>
                      </span>
                    )}{" "}
                  </h5>
                  <ul
                    className={
                      countryName?.length !== 0
                        ? "clear-filter"
                        : courseName?.length !== 0
                        ? "clear-filter"
                        : "clear-filter filDisNone"
                    }
                  >
                    {countryName.map((data, i) => {
                      return (
                        <li key={i}>
                          {data}
                          <a onClick={(e: any) => remove(e, data)}>
                            <sup>
                              <i className="fa fa-close"></i>
                            </sup>
                          </a>
                        </li>
                      );
                    })}

                    {courseName.map((data, i) => {
                      return (
                        <li key={i}>
                          {data}
                          <a onClick={(e: any) => removeCourse(e, data)}>
                            <sup>
                              <i className="fa fa-close"></i>
                            </sup>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {/* <!-- WIDGET --> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5>
                    <span onClick={hendlecountryOpne}>
                      filter by <b>country</b>{" "}
                      {countryOpne == true ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={countryOpne == true ? "countryHide" : ""}>
                    {countryOpne == false &&
                      countrys &&
                      countrys?.map((data: any, i: any) => {
                        // if (countryId.includes(data?.id.toString()) == true) {
                        //   interestedCountryFilter(data?.country_name);
                        // }
                        return (
                          <li key={i}>
                            <label>
                              <input
                                className="vertical_align_checkbox"
                                name={data?.id}
                                type="checkbox"
                                value={data?.country_name}
                                checked={countryName.includes(
                                  data?.country_name
                                )}
                                onChange={(e: any) => hendlClickCountry(e)}
                              />
                              <i className="awe-icon awe-icon-check"></i>
                              <span className="vertical_align">
                                {data?.country_name}
                              </span>
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}

                <div className="widget widget_has_radio_checkbox">
                  <h5>
                    <span onClick={hendlecourseOpne}>
                      filter by <b>course</b>{" "}
                      {courseOpne == true ? (
                        <i className="fa fa-angle-down"></i>
                      ) : (
                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>

                  <ul
                    className={
                      courseOpne == true ? "countryHide pt-2" : " pt-2"
                    }
                  >
                    {courseOpne == false && activecourse && (
                      <li>
                        <div className="lavelbox text-left filter-courses-level">
                          <input
                            style={{ marginLeft: "0" }}
                            type="radio"
                            name="RadioGroup1"
                            value={1}
                            onClick={(e: any) => {
                              setCourseLevel(e.target.value);
                              setCourseId([]);
                              setCourseName([]);
                            }}
                            id="RadioGroup1_0"
                            checked={
                              courseLevel
                                ? courseLevel == 1
                                  ? true
                                  : false
                                : true
                            }
                          />
                          <label htmlFor="RadioGroup1_0"> bachelor's </label>
                          <input
                            type="radio"
                            name="RadioGroup1"
                            value={2}
                            onClick={(e: any) => {
                              setCourseLevel(e.target.value);
                              setCourseId([]);
                              setCourseName([]);
                            }}
                            id="RadioGroup1_1"
                            checked={courseLevel == 2 ? true : false}
                          />
                          <label htmlFor="RadioGroup1_1"> master's</label>
                        </div>
                      </li>
                    )}
                    {courseOpne == false &&
                      activecourse &&
                      activecourse?.map((data: any, i: any) => {
                        if (data?.id !== 333)
                          return (
                            <li
                              key={i}
                              className={"_all_course_ _course_" + data?.level}
                              style={{
                                display: courseLevel
                                  ? data?.level == courseLevel
                                    ? ""
                                    : "none"
                                  : data?.level == 1
                                  ? ""
                                  : "none",
                              }}
                            >
                              <label className="content-wrap">
                                <input
                                  className="vertical_align_checkbox"
                                  type="checkbox"
                                  name={data?.title}
                                  value={data?.id}
                                  checked={
                                    courseId.includes(data?.id) ||
                                    courseId.includes(data?.id.toString())
                                  }
                                  onChange={(e: any) => hendlClickCourse(e)}
                                />
                                <i className="awe-icon awe-icon-check"></i>
                                <span className="vertical_align">
                                  {data?.title?.length > 20
                                    ? `${data?.title.substring(0, 20)}...`
                                    : data?.title}
                                </span>
                              </label>
                            </li>
                          );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-md-push-2">
              <div className="row">
                <div className="col-md-9 resultv">
                  <h2 className="uni_count_text">
                    you can find your best match out of{" "}
                    <span>
                      {/* {universities?.length ? universities?.length + loadmore ? loadmore : 0 : "0"}{" "} */}
                      {UniCount} study abroad universities
                    </span>
                  </h2>
                  <p>
                    you can always shortlist your preferred Uni’s by clicking on
                    the heart icon and come back any time to access it any time.
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="page-top">
                    <select
                      className="awe-select"
                      value={sort}
                      onChange={(e) => sortArray(e.target.value)}
                    >
                      <option value="">sort by</option>
                      <option value="rating">ratings</option>
                      <option value="lowestfee">lowest fees</option>
                      <option value="highestfee">highest fees</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="filter-page__content">
                <div className="filter-item-wrapper">
                  {universities?.length == 0 ? (
                    <div className="nodataFound">
                      <img src="/assets/img/noDataFound.jpeg" alt="" />
                    </div>
                  ) : (
                    ""
                  )}
                  {universities &&
                    universities?.map((data: any, i: any) => {
                      const banners =
                        typeof data?.UniversityDetail?.banners == "string"
                          ? JSON.parse(data?.UniversityDetail?.banners)
                          : "";
                      var isImg = false;
                      if (banners.length > 0) {
                        isImg = true;
                      }
                      return (
                        <>
                          {(() => {
                            if (i == 3) {
                              i = 1;
                              return universities?.length > 0 ? (
                                <AdSlider />
                              ) : (
                                ""
                              );
                            } else {
                              i++;
                            }
                          })()}
                          <div className="uni-item-list" key={i}>
                            <div className="item-media">
                              <div
                                className={
                                  isImg
                                    ? "image-cover uni-listupr text-center mob-text-left"
                                    : "image-cover uni-listupr text-center image-cover-uni-listupr-noimg"
                                }
                              >
                                {data?.UniversityDetail?.banners !== null &&
                                data?.UniversityDetail !== null ? (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      let preurl = "/";
                                      let countrySlug =
                                        countrySlugById(uniCountryId);
                                      if (urlChange && uniCountryId) {
                                        preurl += `${countrySlug}/`;
                                      }
                                      if (urlChange && uniLevelId) {
                                        preurl += `${
                                          uniLevelId == 1 ? "ug" : "pg"
                                        }/`;
                                      }
                                      history.push(
                                        `${preurl}university/${data?.slug}`,
                                        {
                                          university_id: data?.id,
                                          course_level_dt: uniLevelId
                                            ? Number(uniLevelId)
                                            : "",
                                          course_id_dt: uniCourseId
                                            ? uniCourseId
                                            : "",
                                        }
                                      );
                                    }}
                                    src={baseurl + banners}
                                    className="no-image"
                                  />
                                ) : (
                                  <img
                                    src="/assets/img/noImage.jpeg"
                                    alt=""
                                    className="no-image"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="item-body">
                              <div className="item-title uni-name-mob">
                                <h5>
                                  <a
                                    onClick={() => {
                                      let preurl = "/";
                                      let countrySlug =
                                        countrySlugById(uniCountryId);
                                      if (urlChange && uniCountryId) {
                                        preurl += `${countrySlug}/`;
                                      }
                                      if (urlChange && uniLevelId) {
                                        preurl += `${
                                          uniLevelId == 1 ? "ug" : "pg"
                                        }/`;
                                      }
                                      history.push(
                                        `${preurl}university/${data?.slug}`,
                                        {
                                          university_id: data?.id,
                                          course_level_dt: uniLevelId
                                            ? Number(uniLevelId)
                                            : "",
                                          course_id_dt: uniCourseId
                                            ? uniCourseId
                                            : "",
                                        }
                                      );
                                    }}
                                    className="uni_name"
                                  >
                                    {data?.university_name}
                                  </a>
                                </h5>
                              </div>
                              <div className="item-footer">
                                <div className="item-hotel-star">
                                  <div>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />

                                    <span>
                                      |{" "}
                                      {data?.review_count > 500
                                        ? "500+"
                                        : data?.review_count}{" "}
                                      student reviews
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="item-address">
                                {data.university_type == 1
                                  ? "Private"
                                  : data.university_type == 2
                                  ? "Public"
                                  : ""}
                              </div>
                              <div className="item-abouttxt top-66">
                                <table
                                  width="100%"
                                  style={{ border: "0" }}
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/intake.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>intake</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="uni_intake">
                                                  {data?.intake
                                                    ? data?.intake
                                                    : "N/A"}
                                                </span>
                                                <span className="mobile_intake">
                                                  {data?.intake ? (
                                                    data?.intake?.split(" ")
                                                      .length > readMore ? (
                                                      <p className="course__font">
                                                        {" "}
                                                        {data?.intake
                                                          ?.split(" ")
                                                          .splice(0, 1)
                                                          .join(" ")}
                                                        .{" "}
                                                        <a
                                                          className="read-more-new"
                                                          onClick={() =>
                                                            setreadMore(
                                                              data?.intake?.split(
                                                                " "
                                                              ).length &&
                                                                data?.intake?.split(
                                                                  " "
                                                                ).length + 1
                                                            )
                                                          }
                                                        >
                                                          read more
                                                        </a>{" "}
                                                      </p>
                                                    ) : (
                                                      <span>
                                                        {data?.intake}
                                                      </span>
                                                    )
                                                  ) : (
                                                    "N/A"
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/review.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>reviews</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="yellow">
                                                  {data?.review_count &&
                                                    data?.review_count}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td className="ranking01">
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/ranking.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>ranking</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="yellow">
                                                  {data?.ranking
                                                    ? data?.ranking
                                                    : 0}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td className="country01">
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/country1.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>country</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="yellow">
                                                  {data?.Country?.country_name}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="item-btn-more">
                              <div className="btnbox">
                                <a
                                  href={
                                    data?.UniversityDetail?.brochure == "" ||
                                    data?.UniversityDetail?.brochure == null
                                      ? "javascript:void(0)"
                                      : data?.UniversityDetail?.brochure &&
                                        baseurl +
                                          data?.UniversityDetail?.brochure
                                  }
                                  target=""
                                  download
                                  className={
                                    data?.UniversityDetail?.brochure == null ||
                                    data?.UniversityDetail?.brochure == ""
                                      ? "awe-btnbook download brochuredis"
                                      : "awe-btnbook download"
                                  }
                                >
                                  download brochure{" "}
                                  <i className="iconx">
                                    <img src="/assets/img/icons/download.svg" />
                                  </i>
                                </a>

                                <a
                                  onClick={handleFreeCoaching}
                                  className="awe-btnbook"
                                >
                                  get free counselling
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}

                  {universities?.length > 0 ? <AdSlider /> : ""}

                  {universities &&
                    universities?.map((data: any, i: any) => {
                      const banners =
                        typeof data?.UniversityDetail?.banners == "string"
                          ? JSON.parse(data?.UniversityDetail?.banners)
                          : "";
                      var isImg = false;
                      if (banners.length > 0) {
                        isImg = true;
                      }
                      return (
                        i > 3 &&
                        i < loadmore &&
                        i < offset && (
                          <div className="uni-item-list" key={i}>
                            <div className="item-media">
                              <div
                                className={
                                  isImg
                                    ? "image-cover uni-listupr text-center "
                                    : "image-cover uni-listupr text-center image-cover-uni-listupr-noimg"
                                }
                              >
                                {data?.UniversityDetail?.banners !== null ? (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      let preurl = "/";
                                      let countrySlug =
                                        countrySlugById(uniCountryId);
                                      if (urlChange && uniCountryId) {
                                        preurl += `${countrySlug}/`;
                                      }
                                      if (urlChange && uniLevelId) {
                                        preurl += `${
                                          uniLevelId == 1 ? "ug" : "pg"
                                        }/`;
                                      }
                                      history.push(
                                        `${preurl}university/${data?.slug}`,
                                        {
                                          university_id: data?.id,
                                          course_level_dt: uniLevelId
                                            ? Number(uniLevelId)
                                            : "",
                                          course_id_dt: uniCourseId
                                            ? uniCourseId
                                            : "",
                                        }
                                      );
                                    }}
                                    src={baseurl + banners}
                                    alt=""
                                    onError={(e: any) => {
                                      e.target.onerror = null;
                                      e.target.src = "/assets/img/noImage.jpeg";
                                    }}
                                    className="no-image"
                                  />
                                ) : (
                                  <img
                                    src="/assets/img/noImage.jpeg"
                                    alt=""
                                    className="no-image"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="item-body">
                              <div className="item-title">
                                <h5>
                                  <a
                                    onClick={() => {
                                      let preurl = "/";
                                      let countrySlug =
                                        countrySlugById(uniCountryId);
                                      if (urlChange && uniCountryId) {
                                        preurl += `${countrySlug}/`;
                                      }
                                      if (urlChange && uniLevelId) {
                                        preurl += `${
                                          uniLevelId == 1 ? "ug" : "pg"
                                        }/`;
                                      }
                                      history.push(
                                        `${preurl}university/${data?.slug}`,
                                        {
                                          university_id: data?.id,
                                          course_level_dt: uniLevelId
                                            ? Number(uniLevelId)
                                            : "",
                                          course_id_dt: uniCourseId
                                            ? uniCourseId
                                            : "",
                                        }
                                      );
                                    }}
                                    className="uni_name"
                                  >
                                    {data?.university_name}
                                  </a>
                                </h5>
                              </div>
                              <div className="item-footer">
                                <div className="item-hotel-star">
                                  <div>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />

                                    <span>
                                      |{" "}
                                      {data?.review_count > 500
                                        ? "500+"
                                        : data?.review_count}{" "}
                                      student reviews
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="item-address">
                                {data.university_type == 1
                                  ? "Private"
                                  : data.university_type == 2
                                  ? "Public"
                                  : ""}
                              </div>
                              <div className="item-abouttxt top-66">
                                <table
                                  width="100%"
                                  style={{ border: "0" }}
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/intake.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>intake</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="uni_intake">
                                                  {data?.intake
                                                    ? data?.intake
                                                    : "N/A"}
                                                </span>
                                                <span className="mobile_intake">
                                                  {data?.intake ? (
                                                    data?.intake?.split(" ")
                                                      .length > readMore ? (
                                                      <p className="course__font">
                                                        {" "}
                                                        {data?.intake
                                                          ?.split(" ")
                                                          .splice(0, 1)
                                                          .join(" ")}
                                                        .{" "}
                                                        <a
                                                          className="read-more-new"
                                                          onClick={() =>
                                                            setreadMore(
                                                              data?.intake?.split(
                                                                " "
                                                              ).length &&
                                                                data?.intake?.split(
                                                                  " "
                                                                ).length + 1
                                                            )
                                                          }
                                                        >
                                                          read more
                                                        </a>{" "}
                                                      </p>
                                                    ) : (
                                                      <span>
                                                        {data?.intake}
                                                      </span>
                                                    )
                                                  ) : (
                                                    "N/A"
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td>
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/review.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>reviews</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="yellow">
                                                  {data?.review_count &&
                                                    data?.review_count}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td className="ranking01">
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/ranking.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>ranking</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="yellow">
                                                  {data?.ranking
                                                    ? data?.ranking
                                                    : 0}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td className="country01">
                                        <table
                                          width="100%"
                                          style={{ border: "0" }}
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td rowSpan={2}>
                                                <img
                                                  src="/assets/img/icons/country1.svg"
                                                  alt=""
                                                  className="scale"
                                                />
                                              </td>
                                              <td>country</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="yellow">
                                                  {data?.Country?.country_name}
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="item-btn-more">
                              <div className="btnbox">
                                <a
                                  href={
                                    data?.UniversityDetail?.brochure == "" ||
                                    data?.UniversityDetail?.brochure == null
                                      ? "javascript:void(0)"
                                      : data?.UniversityDetail?.brochure &&
                                        baseurl +
                                          data?.UniversityDetail?.brochure
                                  }
                                  target=""
                                  download
                                  className={
                                    data?.UniversityDetail?.brochure == null ||
                                    data?.UniversityDetail?.brochure == ""
                                      ? "awe-btnbook download brochuredis"
                                      : "awe-btnbook download"
                                  }
                                >
                                  download brochure{" "}
                                  <i className="iconx">
                                    <img src="/assets/img/icons/download.svg" />
                                  </i>
                                </a>

                                <a
                                  onClick={handleFreeCoaching}
                                  className="awe-btnbook"
                                >
                                  get free counselling
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
                {universities?.length == 50 ? (
                  <div className="all-center my-2">
                    <a
                      onClick={(e) => {
                        handleLoadMore(e);
                      }}
                      className="view-more readbtn"
                    >
                      Load More
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {seo?.footer_content && (
        <section className="mt-3">
          <div className="container">
            <div className="about-uni-text shade1">
              <SeoFooterContent />
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default UniversitiesList;
