import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { blogAction } from "../../redux/common/action";
import dateFormat from "dateformat";
import { getToken } from "../../Utils/Auth/Token";
import { URL } from "../../redux/common/url";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import jwt_decode from "jwt-decode";
import Seo from "../Seo/Seo";
import {
  FacebookIcon,
  FacebookShareButton,

  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialShareButton from "../SocialShareButton";

function BlogDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const id: any = useLocation();
  const blogSlug: any = useParams();
  const [like, setlike] = useState<any>("");
  const [comment, setcomment] = useState<any>("");
  const [titleSeo, settitleSeo] = useState<any>("");
  const [seoKeyword, setseoKeyword] = useState<any>("");
  const [seo_descriptions, setseo_descriptions] = useState<any>("");
  const [viewAll, setviewAll] = useState<any>("5");

  const isAuthenticated: any = getToken();
  const baseurl = URL.API_BASE_URL;
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const blog: any = useSelector((state: any) =>
    state.blog.blogBySlug ? state.blog.blogBySlug : {}
  );

  // const commenreducer: any = useSelector((state: any) =>
  //   state.commenreducer.loading ? state.commenreducer.loading : {}
  // );

  const urlsforShare = window.location.href;

  // useEffect(() => {
  //   settitleSeo(blog?.seo_title && blog?.seo_title);
  //   setseoKeyword(blog?.seo_keywords && blog?.seo_keywords);
  //   setseo_descriptions(blog?.seo_descriptions && blog?.seo_descriptions);
  // }, [blog]);

  const blogIds: any = localStorage.getItem("blog_id");
  const arr = JSON.parse(blogIds && blogIds);
  const reverseArray = arr && Array.prototype.reverse.call(arr);


  const keys = ["id"]
  const filtered =
    reverseArray &&
    reverseArray?.filter(
      (
        (s) => (o: any) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k: any) => o[k]).join("|"))
      )(new Set())
    );



  useEffect(() => {
    if (blog?.id !== undefined) {
      const blogIds: any = localStorage.getItem("blog_id");
      const blogIdss = blogIds !== null ? blogIds : "[]";
      const arr = JSON.parse(blogIdss);
      arr.push(blog);
      localStorage.setItem("blog_id", JSON.stringify(arr));
    }
  }, [blog?.id]);

  // document.title = "Blog-Detail -admissify";

  //   useEffect(() => {
  //     var connn=  document.getElementsByTagName("META")[3];   /* "Your description about the page or site here to set dynamically" */

  //     var att = document.createAttribute("content");
  //     att.value = "seo_descriptions";
  //     connn.setAttributeNode(att);
  //   }, []);

  const blogLikeUpdate: any = useSelector((state: any) =>
    state.blog.blogUpdateLikeComment ? state.blog.blogUpdateLikeComment : []
  );

  const getUserActionForBlogDetails: any = useSelector((state: any) =>
    state.blog.getUserActionForBlogDetails
      ? state.blog.getUserActionForBlogDetails
      : []
  );

  const blogLikeUnlike: any = useSelector((state: any) =>
    state.blog.blogLikeUnlike ? state.blog.blogLikeUnlike : []
  );

  const blogCommentFindAll: any = useSelector((state: any) =>
    state.blog.blogCommentFindAll ? state.blog.blogCommentFindAll : []
  );

  const blogCommentAdd: any = useSelector((state: any) =>
    state.blog.blogCommentAdd ? state.blog.blogCommentAdd : []
  );

  const filtCommet =
    blogCommentFindAll &&
    blogCommentFindAll?.filter((data: any) => {
      return data?.blog_id == blog?.id;
    });

  let date = blog?.created_at && dateFormat(blog?.created_at, "mmm dS, yyyy");

  const blogList: any[] = useSelector((state: any) =>
    state.blog.blogList ? state.blog.blogList : []
  );
  const blogCategory: any[] = useSelector((state: any) =>
    state.blog.blogCategory ? state.blog.blogCategory : []
  );

  const fitBlog =
    blogList &&
    blogList?.filter((data: any) => {
      return data?.status == 1;
    });

  const filterBlog =
    fitBlog &&
    fitBlog?.filter((data: any) => {
      return data?.BlogCategory?.status == 1;
    });

  const suggestedBlog =
    filterBlog &&
    filterBlog?.filter((data: any) => {
      return data?.is_recommend == 1;
    });

  const hendleblog = (e: any) => {
    history.push("/blog/" + e, {
      blog_id: e,
    });
    dispatch(blogAction.blogBySlug(e));
    return () => { };
  };

  const hendleLike = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      dispatch(
        blogAction.blogLikeUnlike({ blog_id: blog?.id, student_id: user?.id })
      );
    }
  };

  const hendlecomment = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      dispatch(
        blogAction.blogCommentAdd({
          student_id: user?.id,
          blog_id: blog?.id,
          comments: comment,
        })
      );
      dispatch(blogAction.blogBySlug(blogSlug.slug));
      setcomment("");
      return () => { };
    }
  };

  const handleEnter = (event: any) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      hendlecomment(event);
    }
  };

  useEffect(() => {
    dispatch(blogAction.blogBySlug(blogSlug.slug));
    return () => { };
  }, [blogLikeUnlike, blogSlug, blogCommentAdd]);

  useEffect(() => {
    if (blog?.id !== undefined) {
      dispatch(
        blogAction.getUserActionForBlogDetails({
          student_id: user?.id,
          blog_id: blog?.id && blog?.id,
        })
      );
      return () => { };
    }

    // dispatch(blogAction.getUserActionForBlogDetails({student_id:user?.id,
    // blog_id:blog?.id && blog?.id}));

    return () => { };
  }, [blogLikeUnlike, blog?.id]);

  // useEffect(() => {
  //   dispatch(blogAction.getblogbyid(id?.state?.blog_id));
  //   // setblogid(id?.state?.blog_id)
  //   return () => {};
  // }, []);

  useEffect(() => {
    dispatch(blogAction.blogCommentFindAll({}));
    return () => { };
  }, [blog]);

  useEffect(() => {
    dispatch(blogAction.getbloglist({}));
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(blogAction.getBlogCategory({}));
    return () => { };
  }, []);

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        title={blog?.seo_title}
        meta_description={blog?.seo_descriptions}
        meta_keywords={blog?.seo_keywords}
        modified_time={blog?.data?.updated_at || blog?.data?.created_at}
        image={blog?.feature_image ? baseurl+ blog?.feature_image : ""}
      />
      {Object.keys(blog)?.length > 0 ? (
        <section className="unifilter-page unifilter__mob">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 order-lg-2">
                <div className="page-sidebar blog-details">
                  {/* <!-- WIDGET --> */}
                  <div className="widget counsellor blogrecent shade2 ">
                    <h5 className="headgreenbg">recent </h5>
                    <ul className="bg-white">
                      {filtered &&
                        filtered?.map((data: any, i: any) => {
                          // const recentBlog: any =
                          //   filterBlog &&
                          //   filterBlog?.filter((datas: any) => {
                          //     return datas?.id == data && data;
                          //   });

                          return (
                            i < 6 && (
                              <li key={i}>
                                <div
                                  className="resent_blog"
                                  style={{
                                    background: `url(${baseurl + data?.images
                                      })`,
                                  }}
                                ></div>
                                {/* <img src={baseurl+data?.images} alt="" /> */}
                                {/* <img src="assets/img/testi.png" alt="" /> */}
                                <p
                                  onClick={() => {
                                    hendleblog(data?.slug);
                                  }}
                                >
                                  <a>
                                    {data?.heading &&
                                      data?.heading}
                                  </a>
                                  {/* pennsylvania scholarships and.... */}
                                </p>
                              </li>
                            )
                          );
                        })}
                      {/* {filterBlog &&
                      filterBlog?.map((data: any, i: any) => {
                        return (
                         i< 6 && <li key={i}>
                            <div className="resent_blog" style={{ background: `url(${baseurl+data?.images})` }}></div>
                         
                            <p
                              onClick={() => {hendleblog(data?.slug)
                              }}
                            >
                              <a>{data?.heading &&  data?.heading}</a>
               
                            </p>
                          </li>
                        );
                      })} */}
                    </ul>
                  </div>
                  {/* <!-- END / WIDGET --> */}

                  <div className="widget counsellor blogrecent shade2 ">
                    <h5 className="headgreenbg">suggested </h5>
                    <ul className="bg-white">
                      {suggestedBlog &&
                        suggestedBlog?.map((data: any, i: any) => {
                          return (
                            i < 6 && (
                              <li key={i}>
                                <div
                                  className="resent_blog"
                                  style={{
                                    background: `url(${baseurl + data?.images
                                      })`,
                                  }}
                                ></div>
                                {/* <img src={baseurl+data?.images} alt="" /> */}
                                {/* <img src="assets/img/testi.png" alt="" /> */}
                                <p
                                  onClick={() => {
                                    hendleblog(data?.slug);
                                  }}
                                >
                                  <a>{data?.heading && data?.heading}</a>
                                  {/* pennsylvania scholarships and.... */}
                                </p>
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </div>

                  {/* <div className="widget sidebar-title shade2 p-2 bg-white">
                  <div className="simplelink">
                      {blogCategory  && blogCategory ?.map((data:any, i:any)=>{
                        
                          return(
                           i < 6 && <a href="" key={i}> { data?.category.length > 12 ?`${data?.category.substring(0, 12)}.` :  data?.category}
                           
                             </a>
                          )
                          
                      })}
                   
                  
                  </div>
                </div> */}
                </div>
              </div>

              {
                blog?.length == 0 ? <LoadingScreen /> : ""
              }
              <div className="col-lg-9">
                <div className="filter-page__content">
                  <div className="filter-item-wrapper">
                    {/* <!-- ITEM --> */}
                    <div className="blog-details pb-0">
                      <div className="row">
                        <div className="col-12">
                          <div className="all-center pb-0">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="blog-item">
                                  <figcaption className="content-box headblogwrap">
                                    <div className="headblog">
                                      <h1>
                                        {/* study abroad budget change will cost students in faculty-led programs */}
                                        {blog?.heading}
                                      </h1>
                                      <p className="yellow pb-1">
                                        <strong>
                                          {blog?.BlogCategory?.category &&
                                            blog?.BlogCategory?.category}
                                          {/* News */}
                                        </strong>
                                      </p>
                                      <p>
                                        <small>
                                          By {blog?.auther && blog?.auther}{" "}
                                          {/* admissify */} on {date && date}
                                        </small>
                                      </p>
                                    </div>
                                    <SocialShareButton urlsforShare={urlsforShare} data={""}/>
                                  </figcaption>
                                  <figure
                                    className="feat-text1 mb-0 blog_details"
                                    style={{
                                      background: `url(${baseurl + blog?.feature_image
                                        })`,
                                    }}
                                  >
                                    {/* <img
                                    className="scale"
                                    src={baseurl+blog?.feature_image}
                                    // src="assets/img/estudiantes-universitar.png"
                                  /> */}
                                  </figure>
                                  <figcaption className="content-box">
                                    <h2 className="pb-2 bloghead2">
                                      {/* study abroad budget change will cost students in faculty-led programs */}
                                      {blog?.heading}
                                    </h2>
                                    <div
                                      className="blog-content editor_css"
                                      dangerouslySetInnerHTML={{
                                        __html: blog?.details
                                          ? blog?.details
                                          : "",
                                      }}
                                    ></div>

                                    <ul className="iconscomments">
                                      <li>
                                        <a
                                          onClick={(e) => {
                                            hendleLike(true);
                                          }}
                                        >
                                          <i
                                            className={
                                              getUserActionForBlogDetails?.blog_liked ==
                                                1
                                                ? "fa fa-thumbs-o-up active"
                                                : "fa fa-thumbs-o-up"
                                            }
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {blog?.like_count}{" "}
                                        </a>
                                      </li>
                                      <li>
                                        {/* <a
                                        onClick={(e) => {
                                          hendlecomment(true);
                                        }}
                                      > */}
                                        <i
                                          className="fa fa-comment yellow"
                                          aria-hidden="true"
                                        ></i>
                                        {blog?.comment_count == null
                                          ? "0"
                                          : blog?.comment_count}
                                        {/* </a> */}
                                      </li>
                                      <li>
                                        {/* <a> */}
                                        <i
                                          className="fa fa-eye yellow"
                                          aria-hidden="true"
                                        ></i>
                                        {blog?.view_count && blog?.view_count}
                                        {/* </a> */}
                                      </li>
                                    </ul>

                                    <div className="clearfix"></div>
                                    <div className="program-search px-0">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="input-group mb-3">
                                            <input
                                              type="text"
                                              name="comment"
                                              className="form-control rounded-0"
                                              placeholder="type comment"
                                              autoComplete="off"
                                              value={comment}
                                              onChange={(e: any) => {
                                                setcomment(e.target.value);
                                              }}
                                              onKeyDown={handleEnter}
                                            />
                                            <div className="input-group-append">
                                              <button
                                                className={
                                                  comment?.length > 0
                                                    ? "btn btn-secondary"
                                                    : "btn btn-secondary discuDiseble"
                                                }
                                                type="button"
                                                onClick={hendlecomment}
                                              >
                                                comment
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </figcaption>
                                  {filtCommet?.length && filtCommet?.length > 0 ? <div className="row bg-grey-light px-4 reviewlistall blogComm">
                                    <div className="col-12">
                                      <div className="reviewstops bg-grey-light review-discussion">
                                        <div className="row">
                                          <div className="col-12">
                                            <h6 className="pb-2 yellow">
                                              <small>
                                                <b>
                                                  {/* {discussComments?.length
                                              ? discussComments?.length
                                              : "0"}{" "} */}
                                                  {filtCommet?.length &&
                                                    filtCommet?.length}{" "}
                                                  comments
                                                </b>
                                              </small>
                                            </h6>

                                            {filtCommet &&
                                              filtCommet?.map(
                                                (data: any, i: any) => {

                                                  console.log(data);
                                                  let commentdates = dateFormat(data?.created_at, " d-m-yyyy");
                                                  return (
                                                    i < viewAll && (
                                                      <div className="reviews_list mt-4" key={i}>
                                                        <img
                                                          src="/assets/img/user_blank.png"
                                                          alt=""
                                                        />

                                                        <div className="reviews_item blogComment">
                                                          <h6>
                                                            {" "}
                                                            <span className="color-green">
                                                              {/* amir khan */}
                                                              {data?.Student?.name}
                                                            </span>
                                                            <span>
                                                              <small>

                                                                {commentdates && commentdates}
                                                                {/* 25-06-2021 */}
                                                              </small>{" "}
                                                            </span>
                                                          </h6>
                                                          <p className="pt-1 pb-1">
                                                            {data?.comments}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              )}

                                            {filtCommet?.length &&
                                              filtCommet?.length > viewAll ? (
                                              <div className="all-center my-2">
                                                <a
                                                  onClick={() => {
                                                    setviewAll(
                                                      filtCommet?.length
                                                        ? filtCommet?.length
                                                        : "0"
                                                    );
                                                  }}
                                                  className="view-more readbtn"
                                                >
                                                  view all
                                                </a>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- END / ITEM --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
}

export default BlogDetails;
