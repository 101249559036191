import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  GET_UNIVERSITIES_LIST,
  GET_UNIVERSITIES_BY_ID,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_HOME,
  GET_ALL_UNIVERSITIES_COMMENT,
  ADD_UNIVERSITIES_COMMENT,
  GET_RANKING_UNIVERSITIES,
  GET_UNIVERSITIES_BY_SLUG,
  TRENDING_UNIVERSITIES,
  GET_UNIVERSITIES_COUNT,
  UNIVERSITY_LIKE_DISLIKE,
  UNIVERSITY_LIKE,
  APPLIED_COURSE,
  GET_AD_BANNER
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const getAllUniversityListAction = (data: any) => {
  return { type: GET_UNIVERSITIES_LIST, payload: data };
};

const getUniversityByIdAction = (data: any) => {
  return { type: GET_UNIVERSITIES_BY_ID, payload: data };
};
const getuniversitiesAction = (data: any) => {
  return { type: GET_UNIVERSITIES, payload: data };
};
const getTrendingUniversitiesHomeAction = (data: any) => {
  return { type: GET_UNIVERSITIES_HOME, payload: data };
};
const getuniversitiesCountAction = (data: any) => {
  return { type: GET_UNIVERSITIES_COUNT, payload: data };
};
const getTrendingUniversitiesAction = (data: any) => {
  return { type: TRENDING_UNIVERSITIES, payload: data };
};
const getAllUniversitiesCommentAction = (data: any) => {
  return { type: GET_ALL_UNIVERSITIES_COMMENT, payload: data };
};
const addUniversityCommentAction = (data: any) => {
  return { type: ADD_UNIVERSITIES_COMMENT, payload: data };
};
const getrankinguniversitiesAction = (data: any) => {
  return { type: GET_RANKING_UNIVERSITIES, payload: data };
};
const fineUniversityBySlugAction = (data: any) => {
  return { type: GET_UNIVERSITIES_BY_SLUG, payload: data };
};
const universityLikeDislikeAction = (data: any) => {
  return { type: UNIVERSITY_LIKE_DISLIKE, payload: data };
};
const universityLikeAction = (data: any) => {
  return { type: UNIVERSITY_LIKE, payload: data };
};
const appliedCoursesFrontendUniStud = (data: any) => {
  return { type: APPLIED_COURSE, payload: data };
};
const getAdBannerAction = (data: any) => {
  return { type: GET_AD_BANNER, payload: data };
};


export const getAllUniversity = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllUniversity}`, data);
      dispatch(getAllUniversityListAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAllUniversitiesComment = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.getAllUniversitiesComment}`, data);
      dispatch(getAllUniversitiesCommentAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getrankinguniversities = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.getrankinguniversities}`, data);
      dispatch(getrankinguniversitiesAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const addUniversityComment = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.addUniversityComment}`, data);
      dispatch(addUniversityCommentAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getuniversities = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.getuniversities}`, data);
      let dt = response.data.data;
      if (data?.intake) {
        dt?.length > 0 && dt.sort((a: any, b: any) => {
          let arrA: any[] = a?.intake?.split(",") || [];
          let arrB: any[] = b?.intake?.split(",") || [];
          return arrA.length - arrB.length;
        })
        dt?.length > 0 && dt.sort((a: any, b: any) => {
          let arrA: any[] = a?.intake?.split(",") || [];
          let arrB: any[] = b?.intake?.split(",") || [];
          let indexA: number = arrA.indexOf(data?.intake);
          let indexB: number = arrB.indexOf(data?.intake);
          return indexA - indexB;
        })
      }
      response.data.data = dt;
      dispatch(getuniversitiesAction(response.data));
      // dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getTrendingUniversitiesHome = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.getuniversities}`, data);
      dispatch(getTrendingUniversitiesHomeAction(response?.data));
      // dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const universityLikeDislike = (data: any, id: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.universityLikeDislike}/${id}`, data);
      console.log(response, '=====');

      dispatch(universityLikeDislikeAction(response?.data?.data));
      // dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const universityLike = (data: any, id: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.universityLikeList}`, data);
      dispatch(universityLikeAction(response?.data?.data?.list));
      // dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getuniversityCount = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.getuniversities}`, data);
      dispatch(getuniversitiesCountAction(response?.data?.totalCount));
      // dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getTrendingUniversities = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.trendingUniversities}`, data);
      dispatch(getTrendingUniversitiesAction(response?.data?.data));
      // dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getUniversityById = (data: any) => {
  return async (dispatch: any) => {
    try {

      dispatch(loading(true));
      const response: any = await api.get(`${URL.getUniversityById}?id=${data}`);
      dispatch(getUniversityByIdAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getUniversityBySlug = (data: any) => {
  return async (dispatch: any) => {
    try {

      // dispatch(loading(true));
      const response: any = await api.get(`${URL.fineUniversityBySlug}?slug=${data}`);
      dispatch(fineUniversityBySlugAction(response?.data?.data));

      // dispatch(loading(false));
    }
    catch (error: any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const appliedCoursesFrontendUniStudMain = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.appliedCoursesFrontendUniStud}`, data);
      dispatch(appliedCoursesFrontendUniStud(response?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAdBanner = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.get(URL.getAdBanner, { params: data });
      dispatch(getAdBannerAction(response?.data?.data));
    }
    catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};