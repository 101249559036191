import React, { useState, useEffect } from "react";
import { getQuarters } from "../../Utils/Common/Common";
import { useSelector, useDispatch } from "react-redux";
import {
  studentAction,
  scholershipAndOfferAction,
  applyNoAction,
} from "../../redux/common/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { date } from "../../validation/Validation";
import "../../assets/css/profile.css";
import { URL } from "../../redux/common/url";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { Multiselect } from "multiselect-react-dropdown";
import { $CombinedState } from "redux";
// import DatePickers from 'react-date-picker';

function UpdateProfile() {
  let multiselectRef: any = React.createRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const baseurl = URL.API_BASE_URL;
  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [values, setValues] = useState<any>({});
  const [passwordValues, setPasswordValues] = useState<any>({
    old_password: "",
    password: "",
    new_password: "",
  });
  const [value, setdartevalue] = useState(new Date());
  const [genders, setGender] = useState<any>("");
  const [startDate, setStartDate] = useState<any>(null);
  const [currentDate, setcurrentDate] = useState<any>("");
  const [yearsel, setyearsel] = useState<any[]>([]);
  const [FileErr, setFileErr] = useState<any>("");
  const [marksheet_10, setmarksheet_10] = useState<any>("");
  const [markshit_12, setmarkshit_12] = useState<any>("");
  const [bachelorsOrdiploma, setbachelorsOrdiploma] = useState<any>("");
  const [IELTS_TOFEL_PTE, setIELTS_TOFEL_PTE] = useState<any>("");
  const [GMAT_GRE_SAT, setGMAT_GRE_SAT] = useState<any>("");
  const [CV, setCV] = useState<any>("");
  const [Passport, setPassport] = useState<any>("");
  const [Profile, setProfile] = useState<any>("");
  const [modelData, setmodelData] = useState<any>("");
  const [loading, setloading] = useState<any>("");
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [cusCourses, setCusCourses] = useState<any>([]);
  const [ResStream, setResStream] = useState<any>([]);
  const [ResCourses, setResCourses] = useState<any>([]);
  // const [AddCustomFields, setAddCustomFields] = useState<any>("");

  const [qualification10, setqualification10] = useState<any>({
    passingyear: "",
    ResultType: "",
    marks: "",
  });
  const [qualification12, setqualification12] = useState<any>({
    passingyear: "",
    ResultType: "",
    marks: "",
  });

  const [qualificationug, setqualificationug] = useState<any>({
    passingyear: "",
    ResultType: "",
    marks: "",
  });

  const [qualificationpg, setqualificationpg] = useState<any>({
    passingyear: "",
    ResultType: "",
    marks: "",
  });
  var ExamArr = [];

  ExamArr["1"] = "10th standard";
  ExamArr["2"] = "12th standard";
  ExamArr["12"] = "bachelors/diploma";
  ExamArr["7"] = "IELTS/TOFEL/PTE";
  ExamArr["8"] = "GMAT/GRE/SAT";
  ExamArr["11"] = "CV (CURRICULUM VITAE)";
  ExamArr["6"] = "passport";

  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );
  const Interest = studentProfile?.interest
    ? JSON.parse(studentProfile?.interest)
    : "";
  if (Interest.length == 0) {
    var courseLevel = null;
  } else {
    var InterestedLevel = Interest[0]?.course_level;
    if (Interest[0]?.streamData) {
      var InterestedCourseId = Interest[0]?.streamData?.streamId;
    }
    if (Interest[0]?.country_id && Interest[0]?.country_id.length > 0) {
      var country_id_ = Interest[0]?.country_id;
      var countrysArr = [];
      country_id_.map((v, i) => {
        countrysArr.push({
          countryId: v.countryId,
          countryName: v.countryName,
        });
      });
    } else {
      var countrysArr = [];
    }
    if (Interest[0]?.course_id && Interest[0]?.course_id.length > 0) {
      var course_id_ = Interest[0]?.course_id;
      var course_id_arr = [];
      course_id_.map((v, i) => {
        course_id_arr.push({
          courseId: parseInt(v.courseId),
          courseName: v.courseName,
        });
      });
    } else {
      var course_id_arr = [];
    }
  }

  const attachmentList: any = useSelector((state: any) =>
    state.student.getAttachment ? state.student.getAttachment : []
  );

  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );

  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      window.location.href = "/login";
    } else {
      window.location.href = "/";
    }
  };

  const hendle10mrk = (e: any, file: any) => {
    if (!file) {
      setFileErr("file upload is required.");
    } else if (
      ![
        "application/pdf",
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file?.type)
    ) {
      setFileErr(
        "Document should be in .doc/.docx/.png/.pdf/.jpg/.jpeg format."
      );
    } else if (file?.size > 1024 * 1024 * 2) {
      setFileErr("Document size should be less than 2MB.");
    } else {
      setFileErr("");

      const formData: any = new FormData();
      // formData.append("AttachmentFile", file);
      // formData.append("AttachmentAction", "prescriptions");

      //     dispatch(submitAction.uploadAttachmentAction(formData));
    }
  };

  const handleProfile = async (e: any) => {
    var _validFileExtensions = [
      "image/jpg",
      "image/jpeg",
      "image/bmp",
      "image/png",
    ];
    const file = e.target.files[0];
    if (file) {
      if (!_validFileExtensions.includes(file.type)) {
        toast.error("Allowed files are jpg, jpeg, bmp, png");
        return false;
      }
    }
    const fileInput = document.querySelector("#profileImage");
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);
    const res = await axios(
      baseurl + "/api/fileUpload/upload?module_name=profile",
      {
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data; ",
          token: isAuthenticated || "",
        },
      }
    );
    let response = res.data.fileName;
    setProfile(response);
  };
  const ProfilePicture = Profile;

  const handleMarks10 = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios(
        baseurl + "/api/fileUpload/upload?module_name=attachments",
        {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; ",
            token: isAuthenticated || "",
          },
        }
      );
      let response = res.data.fileName;
      setmarksheet_10(response);
      // console.log(response);
      setTimeout(function () {
        var Data = {
          student_id: user?.id,
          reference_type: 1,
          attachment: response ? "/attachments/" + response : null,
        };
        dispatch(studentAction.studentAttachment(Data));
        toast.success("10th standard document uploaded successfully.");
        // window.location.reload();
      }, 300);
    }
  };
  const handleMarks12 = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios(
        baseurl + "/api/fileUpload/upload?module_name=attachments",
        {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; ",
            token: isAuthenticated || "",
          },
        }
      );
      let response = res.data.fileName;
      // setmarksheet_10(response);
      setTimeout(function () {
        var Data = {
          student_id: user?.id,
          reference_type: 2,
          attachment: response ? "/attachments/" + response : null,
        };
        dispatch(studentAction.studentAttachment(Data));
        toast.success("12th standard document uploaded successfully.");
        // window.location.reload();
      }, 300);
    }
  };
  const handleDiploma = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios(
        baseurl + "/api/fileUpload/upload?module_name=attachments",
        {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; ",
            token: isAuthenticated || "",
          },
        }
      );
      let response = res.data.fileName;
      // setmarksheet_10(response);
      setTimeout(function () {
        var Data = {
          student_id: user?.id,
          reference_type: 12,
          attachment: response ? "/attachments/" + response : null,
        };
        dispatch(studentAction.studentAttachment(Data));
        toast.success("bachelors/diploma document uploaded successfully.");
      }, 300);
    }
  };
  const handlePteExam = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios(
        baseurl + "/api/fileUpload/upload?module_name=attachments",
        {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; ",
            token: isAuthenticated || "",
          },
        }
      );
      let response = res.data.fileName;
      setTimeout(function () {
        var Data = {
          student_id: user?.id,
          reference_type: 7,
          attachment: response ? "/attachments/" + response : null,
        };
        dispatch(studentAction.studentAttachment(Data));
        toast.success("IELTS/TOFEL/PTE document uploaded successfully.");
      }, 300);
    }
  };
  const handleGreExam = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios(
        baseurl + "/api/fileUpload/upload?module_name=attachments",
        {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; ",
            token: isAuthenticated || "",
          },
        }
      );
      let response = res.data.fileName;
      // setmarksheet_10(response);
      setTimeout(function () {
        var Data = {
          student_id: user?.id,
          reference_type: 8,
          attachment: response ? "/attachments/" + response : null,
        };
        dispatch(studentAction.studentAttachment(Data));
        toast.success("GMAT/GRE/SAT document uploaded successfully.");
      }, 300);
    }
  };
  const handleCV = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios(
        baseurl + "/api/fileUpload/upload?module_name=attachments",
        {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; ",
            token: isAuthenticated || "",
          },
        }
      );
      let response = res.data.fileName;
      // setmarksheet_10(response);
      setTimeout(function () {
        var Data = {
          student_id: user?.id,
          reference_type: 11,
          attachment: response ? "/attachments/" + response : null,
        };
        dispatch(studentAction.studentAttachment(Data));
        toast.success("CV (Curriculum Vitae) document uploaded successfully.");
      }, 300);
    }
  };

  const handlePassport = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios(
        baseurl + "/api/fileUpload/upload?module_name=attachments",
        {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data; ",
            token: isAuthenticated || "",
          },
        }
      );
      let response = res.data.fileName;
      // setmarksheet_10(response);
      setTimeout(function () {
        var Data = {
          student_id: user?.id,
          reference_type: 6,
          attachment: response ? "/attachments/" + response : null,
        };
        dispatch(studentAction.studentAttachment(Data));
        toast.success("Passport document uploaded successfully.");
      }, 300);
    }
  };

  const hendlefiles = (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      setFileErr("file upload is required.");
    } else if (
      ![
        "application/pdf",
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file?.type)
    ) {
      setFileErr(
        "Document should be in .doc/.docx/.png/.pdf/.jpg/.jpeg format."
      );
    } else if (file?.size > 1024 * 1024 * 2) {
      setFileErr("Document size should be less than 2MB.");
    } else {
      setFileErr("");
      // marksheet10
      if (e.target.name == "marksheet10") {
        setmarksheet_10(file);
      } else if (e.target.name == "markshit_12") {
        setmarkshit_12(file);
      } else if (e.target.name == "bachelorsOrdiploma") {
        setbachelorsOrdiploma(file);
      } else if (e.target.name == "IELTS_TOFEL_PTE") {
        setIELTS_TOFEL_PTE(file);
      } else if (e.target.name == "GMAT_GRE_SAT") {
        setGMAT_GRE_SAT(file);
      } else if (e.target.name == "CV") {
        setCV(file);
      } else if (e.target.name == "Passport") {
        setPassport(file);
      }
    }
  };

  var documents: any = [];
  documents.push(
    { marksheet_10 },
    { markshit_12 },
    { bachelorsOrdiploma },
    { IELTS_TOFEL_PTE },
    { GMAT_GRE_SAT },
    { CV },
    { Passport }
  );

  // console.log(documents);

  var qualification: any = [];
  qualification.push(
    { qualification10 },
    { qualification12 },
    { qualificationug },
    { qualificationpg }
  );
  // qul.push({"01": nieto.label, "02": nieto.value});

  const username = studentProfile?.name;

  values.gender = genders ? genders : null;
  values.dob = startDate ? startDate : null;
  values.id = user?.id;
  values.qualification = qualification;
  values.documents = documents;
  values.profile_image = ProfilePicture
    ? "/profile/" + ProfilePicture
    : studentProfile?.profile_image
    ? studentProfile?.profile_image
    : null;

  const updateProfile: any = useSelector((state: any) =>
    state.student.studentUpdate ? state.student.studentUpdate : []
  );

  // console.log(values);

  const deleteAttachments = (e: any) => {
    var id = e;
    var Data = { mobile: user?.mobile, id: user?.id };
    dispatch(studentAction.deleteAttachment(Data, id));
    // dispatch(studentAction.getStudentById(user?.id));
    toast.success("Document deleted successfully.");
    window.location.reload();
    return () => {};
  };

  const verifyEmail = (e: any) => {
    var Data = {
      mobile: user?.mobile,
      id: user?.id,
      refer_url: window.location.origin,
    };
    dispatch(studentAction.emailVerify(Data));
    // dispatch(studentAction.getStudentById(user?.id));
    toast.success("Email verification sent successfully.");
  };
  // const callingApiDisableBtnClick = (e: any) => {
  //   if (!callingApiDisableBtn) {
  //     console.log('callingApiDisableBtn');
  //     console.log(callingApiDisableBtn);
  //     setcallingApiDisableBtn(true);
  //     // e.target.setAttribute("disabled", "disabled");
  //     return true;
  //   }
  // }
  const update = (e: any) => {
    const formData: any = new FormData();
    const Name = $("#userName").val();
    const Mobile = $("#userMobile").val();
    const Email = $("#userEmail").val();
    const Address = $("#userAddress").val();
    const DOB = $("#userDOB").val();
    const City = $("#userCity").val();
    const Pincode = $("#userPincode").val();
    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!Name) {
      $("#userName").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!Mobile) {
      $("#userMobile").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (Mobile) {
      if (Mobile.length != 10) {
        $("#userMobile").after(
          '<div class="error-class">Invalid mobile number.</div>'
        );
        isError = true;
      }
    }
    if (Mobile.length == 10) {
      if (phoneno.test(Mobile) === false) {
        $("#userMobile").after(
          '<div class="error-class">Invalid mobile number.</div>'
        );
        isError = true;
      }
    }
    if (!Email) {
      $("#userEmail").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (Email) {
      if (regex.test(Email) === false) {
        $("#userEmail").after('<div class="error-class">Invalid email.</div>');
        isError = true;
      }
    }
    if (!Address) {
      $("#userAddress").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!DOB) {
      $("#userDOB").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!City) {
      $("#userCity").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!Pincode) {
      $("#userPincode").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }

    $(".update_profile").prop("disabled", true);
    dispatch(studentAction.studentUpdate(values, user?.id));
    // dispatch(studentAction.getStudentById(user?.id));
    setqualification10({ passingyear10: "", ResultType: "", marks: "" });
    setqualification12({ passingyear12: "", ResultType: "", marks: "" });
    // setresponse(data);
    return () => {};
  };
  var Response = true;
  if (updateProfile.status == true) {
    $(".update_profile").prop("disabled", false);
    Response = false;
  }

  const updateEducationDetails = () => {
    const formData: any = new FormData();
    const Tenth = $("#userTenth").val();
    const TenthType = $("#userTenthType").val();
    const TenthMarks = $("#userTenthMarks").val();
    const Twelfth = $("#userTwelfth").val();
    const TwelfthType = $("#userTwelfthType").val();
    const TwelfthMarks = $("#userTwelfthMarks").val();

    var custYear = $("input[name='cuspassingyear[]']");
    var custResultType = $("input[name='cusresulttype[]']");

    var custResultTypeArr = [];
    var custMarksArr: any = [];
    var custJson = [];
    custResultType.map((v, k) => {
      custResultTypeArr.push($(k).val());
    });
    console.log(custResultTypeArr);
    var custMarks = $("input[name='cusmarks[]']");
    custMarks.map((vv, kv) => {
      custMarksArr.push($(kv).val());
    });

    custYear.map((v, k) => {
      var passingyear_ = $(k).val();
      var custResultType_ = custResultTypeArr[v];
      var custMarksArr_ = custMarksArr[v];
      custJson.push({
        passingyear: passingyear_,
        ResultType: custResultType_,
        marks: custMarksArr_,
      });
    });
    $(".error-class").remove();
    var isError = false;
    if (!Tenth) {
      $("#userTenth").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!TenthType) {
      $("#userTenthType").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!TenthMarks) {
      $("#userTenthMarks").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!Twelfth) {
      $("#userTwelfth").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!TwelfthType) {
      $("#userTwelfthType").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!TwelfthMarks) {
      $("#userTwelfthMarks").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }
    qualification.push({ customJson: custJson });
    dispatch(studentAction.studentUpdate(values, user?.id));
    // dispatch(studentAction.getStudentById(user?.id));
    setqualification10({ passingyear10: "", ResultType: "", marks: "" });
    setqualification12({ passingyear12: "", ResultType: "", marks: "" });
    return () => {};
  };

  const hendlqulifi10 = (e: any) => {
    const { name, value } = e.target;
    setqualification10({ ...qualification10, [name]: value });
  };
  const hendlqulifi12 = (e: any) => {
    const { name, value } = e.target;
    setqualification12({ ...qualification12, [name]: value });
  };

  const hendlqulifiug = (e: any) => {
    const { name, value } = e.target;
    setqualificationug({ ...qualificationug, [name]: value });
  };
  const hendlqulifipg = (e: any) => {
    const { name, value } = e.target;
    setqualificationpg({ ...qualificationpg, [name]: value });
  };

  useEffect(() => {
    var year: any = new Date().getFullYear();
    var current = year;
    year -= 15;
    let array = [...yearsel];
    for (var i = 0; i < 15; i++) {
      if (year + i == current) {
        year += 1;
        array.push(year);
        setyearsel(array);
      } else {
        year += 1;
        array.push(year);
        setyearsel(array);
      }
    }
  }, []);
  const qualifi =
    studentProfile?.qualification && JSON.parse(studentProfile?.qualification);

  useEffect(() => {
    setValues({
      name: studentProfile?.name == undefined ? "" : studentProfile?.name,
      email: studentProfile?.email == undefined ? "" : studentProfile?.email,
      mobile: studentProfile?.mobile == undefined ? "" : studentProfile?.mobile,
      address:
        studentProfile?.address == undefined ? "" : studentProfile?.address,
      city: studentProfile?.city == undefined ? "" : studentProfile?.city,
      pincode:
        studentProfile?.pincode == undefined ? "" : studentProfile?.pincode,
    });
    setGender(
      studentProfile?.gender == undefined ? "" : studentProfile?.gender
    );
    setStartDate(
      studentProfile?.dob == undefined ? "" : new Date(studentProfile?.dob)
    );
    /* setcurrentDate(
      new Date(moment(new Date()).subtract(10, "years").format("YYYY-MM-DD"))
    ); */
    if (qualifi?.length > 0) {
      setqualification10({
        passingyear:
          qualifi[0]?.qualification10?.passingyear &&
          qualifi[0]?.qualification10?.passingyear == undefined
            ? ""
            : qualifi[0]?.qualification10?.passingyear,
        ResultType:
          qualifi[0]?.qualification10?.ResultType &&
          qualifi[0]?.qualification10?.ResultType == undefined
            ? ""
            : qualifi[0]?.qualification10?.ResultType,
        marks:
          qualifi[0]?.qualification10?.marks &&
          qualifi[0]?.qualification10?.marks == undefined
            ? ""
            : qualifi[0]?.qualification10?.marks,
      });
      setqualification12({
        passingyear:
          qualifi[1]?.qualification12?.passingyear &&
          qualifi[1]?.qualification12?.passingyear == undefined
            ? ""
            : qualifi[1]?.qualification12?.passingyear,
        ResultType:
          qualifi[1]?.qualification12?.ResultType &&
          qualifi[1]?.qualification12?.ResultType == undefined
            ? ""
            : qualifi[1]?.qualification12?.ResultType,
        marks:
          qualifi[1]?.qualification12?.marks &&
          qualifi[1]?.qualification12?.marks == undefined
            ? ""
            : qualifi[1]?.qualification12?.marks,
      });
      setqualificationug({
        passingyear:
          qualifi[2]?.qualificationug?.passingyear &&
          qualifi[2]?.qualificationug?.passingyear == undefined
            ? ""
            : qualifi[2]?.qualificationug?.passingyear,
        ResultType:
          qualifi[2]?.qualificationug?.ResultType &&
          qualifi[2]?.qualificationug?.ResultType == undefined
            ? ""
            : qualifi[2]?.qualificationug?.ResultType,
        marks:
          qualifi[2]?.qualificationug?.marks &&
          qualifi[2]?.qualificationug?.marks == undefined
            ? ""
            : qualifi[2]?.qualificationug?.marks,
      });
      setqualificationpg({
        passingyear:
          qualifi[3]?.qualificationpg?.passingyear &&
          qualifi[3]?.qualificationpg?.passingyear == undefined
            ? ""
            : qualifi[3]?.qualificationpg?.passingyear,
        ResultType:
          qualifi[3]?.qualificationpg?.ResultType &&
          qualifi[3]?.qualificationpg?.ResultType == undefined
            ? ""
            : qualifi[3]?.qualificationpg?.ResultType,
        marks:
          qualifi[3]?.qualificationpg?.marks &&
          qualifi[3]?.qualificationpg?.marks == undefined
            ? ""
            : qualifi[3]?.qualificationpg?.marks,
      });
    }
  }, [studentProfile]);

  useEffect(() => {
    if (updateProfile.status == true) {
      dispatch(studentAction.getStudentById(user?.id, ""));
    }
    return () => {};
  }, [updateProfile]);

  const handleTabClick = (tabName: any) => {
    const currentPath = location.pathname;

    const newPath = currentPath.includes("/profile")
      ? `/profile/${tabName}`
      : `profile/${tabName}`;

    history.push(newPath);
  };

  useEffect(() => {
    dispatch(
      studentAction.getAttachmentList({
        id: user?.id,
        mobile: user?.mobile,
      })
    );
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => {};
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleChangePassword = (e: any) => {
    const { name, value } = e.target;
    setPasswordValues({ ...passwordValues, [name]: value });
  };

  $(".show-upload").click(function (k, v) {
    var id = $(this).attr("data-id");
    // console.log(id);
    $("." + id).fadeIn(0);
  });

  const getStreams = async (e: any = null) => {
    var level = $(e.target).val();
    if (level) {
      getStreamList(level);
      multiselectRef.current.resetSelectedValues();
    } else {
      // toast.error('Select course level first.');
    }
  };
  const getCourses = async (e: any) => {
    var courseId = $(e.target).val();
    if (courseId) {
      /*  let arr:any[] = [];
      course_id_arr = [...arr]; */
      course_id_arr.length = 0;
      /* course_id_arr?.splice(0,course_id_arr.length); */
      multiselectRef.current.resetSelectedValues();
      getCoursesList(courseId);
    } else {
      // toast.error('Select course level first.');
    }
  };

  const editUpdateUserInterest = () => {
    getStreamList(InterestedLevel);
    getCoursesList(InterestedCourseId);
  };
  const getStreamList = async (levelId: any) => {
    if (levelId) {
      try {
        var response: any = await axios.get(
          `${
            URL.API_BASE_URL +
            `` +
            URL.getstreamlist +
            `?status=1&course_level_id=` +
            levelId
          }`
        );
        if (response?.data?.status) {
          var resStreamData = response?.data?.data;
          setResStream(resStreamData);
        }
        return false;
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      // toast.error('Select course level first.');
    }
  };
  const getCoursesList = async (CoursesId: any) => {
    if (CoursesId) {
      try {
        var response: any = await axios.get(
          `${
            URL.API_BASE_URL +
            `` +
            URL.getAllCourses +
            `?status=1&parent_id=` +
            CoursesId
          }`
        );
        if (response?.data?.status) {
          var resCoursesData = response?.data?.data;
          setResCourses(resCoursesData);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      // toast.error('Select course level first.');
    }
  };
  const StreamData = ResStream;
  const CoursesData = ResCourses;
  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );

  const handleUpdateInterest = (event: any, value: any) => {
    $(".error-class").remove();
    var courseLevel_ = $('select[name="CourseLevel"]').val();
    var course_ = $('select[name="CourseName"] :selected').val();
    var course_t = $('select[name="CourseName"] :selected').text();
    var intake_ = $('select[name="IntakeInput"]').val();
    var objective_ = $('select[name="ObjectiveInput"]').val();
    var ExamName_ = $('select[name="ExamName"]').val();
    var ObtainMarks_ = $('input[name="ObtainMarks"]').val();
    var QualificationInput_ = $('select[name="QualificationInput"]').val();
    var QualificationResultType_ = $(
      'select[name="QualificationResultType"]'
    ).val();
    var QualificationObtainMarks_ = $(
      'input[name="QualificationObtainMarks"]'
    ).val();
    /**
     * validation
     */
    var isError = false;
    if (!courseLevel_) {
      $('select[name="CourseLevel"]').after(
        '<div class="error-class">select course level.</div>'
      );
      isError = true;
    }
    if (!course_) {
      $('select[name="CourseName"]').after(
        '<div class="error-class">select course.</div>'
      );
      isError = true;
    }
    if (!intake_) {
      $('select[name="IntakeInput"]').after(
        '<div class="error-class">select intake.</div>'
      );
      isError = true;
    }
    if (!ExamName_) {
      $('select[name="ExamName"]').after(
        '<div class="error-class">select you exam.</div>'
      );
      isError = true;
    }
    if (!ObtainMarks_) {
      $('input[name="ObtainMarks"]').after(
        '<div class="error-class">enter your obtain marks.</div>'
      );
      isError = true;
    } else {
      if (ExamName_ === "IELTS") {
        if (ObtainMarks_ > 9) {
          $('input[name="ObtainMarks"]').after(
            '<div class="error-class">maximum marks upto 9.</div>'
          );
          isError = true;
        }
      } else if (ExamName_ === "PTE") {
        if (ObtainMarks_ > 90) {
          $('input[name="ObtainMarks"]').after(
            '<div class="error-class">maximum marks upto 90.</div>'
          );
          isError = true;
        }
      } else if (ExamName_ === "TOEFL") {
        if (ObtainMarks_ > 120) {
          $('input[name="ObtainMarks"]').after(
            '<div class="error-class">maximum marks upto 120.</div>'
          );
          isError = true;
        }
      }
    }
    if (!QualificationInput_) {
      $('select[name="QualificationInput"]').after(
        '<div class="error-class">select you highest qualification.</div>'
      );
      isError = true;
    }
    if (!QualificationResultType_) {
      $('select[name="QualificationResultType"]').after(
        '<div class="error-class">select you education result type.</div>'
      );
      isError = true;
    }
    if (!QualificationObtainMarks_) {
      $('input[name="QualificationObtainMarks"]').after(
        '<div class="error-class">enter your obtain marks.</div>'
      );
      isError = true;
    } else {
      if (QualificationResultType_ === "CGPA") {
        if (QualificationObtainMarks_ > 10) {
          $('input[name="QualificationObtainMarks"]').after(
            '<div class="error-class">maximum cgpa upto 10.</div>'
          );
          isError = true;
        }
      }
      if (QualificationResultType_ === "Percentage") {
        if (QualificationObtainMarks_ > 100) {
          $('input[name="QualificationObtainMarks"]').after(
            '<div class="error-class">maximum cgpa upto 100.</div>'
          );
          isError = true;
        }
      }
    }
    if (isError) {
      return false;
    }
    var preferredcountries_arr = [];
    if (selectedValue && selectedValue.length > 0) {
      selectedValue.map((v: any, k: any) => {
        preferredcountries_arr.push({
          countryId: parseInt(v.key),
          countryName: v.value,
        });
      });
    } else {
      preferredcountries_arr = countrysArr;
    }
    var cusCourses_arr = [];
    if (cusCourses && cusCourses.length > 0) {
      cusCourses.map((v: any, k: any) => {
        cusCourses_arr.push({ courseId: parseInt(v.id), courseName: v.title });
      });
    } else {
      cusCourses_arr = course_id_arr;
    }

    var ResultType = QualificationResultType_;
    var highest_edu_ = QualificationObtainMarks_;
    if (ResultType == "Percentage") {
      if (highest_edu_) {
        $(".error-class").remove();
        var isError = false;
        if (highest_edu_ > 100) {
          $("#highest_edu_").after(
            '<div class="error-class">Maximum marks upto 100.</div><br />'
          );
          isError = true;
        }
        if (isError) {
          return false;
        }
      }
    }
    if (ResultType == "CGPA") {
      if (highest_edu_) {
        $(".error-class").remove();
        var isError = false;
        if (highest_edu_ > 10) {
          $("#highest_edu_").after(
            '<div class="error-class">Maximum cgpa upto 10.</div><br />'
          );
          isError = true;
        }
        if (isError) {
          return false;
        }
      }
    }
    var interestArr: any = [];
    var course_level = courseLevel_;
    var streamData = { streamId: course_, streamName: course_t };
    var intake = intake_;
    var course_id = cusCourses_arr;
    var objective = { objective: objective_ };
    var country_id = preferredcountries_arr;
    var tests = { test: ExamName_, marks: ObtainMarks_ };
    var highest_edu = {
      highest_edu: QualificationInput_,
      resultType: ResultType,
      Achieved_Or_Expected_marks: highest_edu_,
    };
    interestArr = {
      course_level,
      streamData,
      intake,
      course_id,
      objective,
      country_id,
      tests,
      highest_edu,
    };
    var studentInterest = {
      mobile: user?.mobile,
      id: user?.id,
      updated_by: user?.id,
      interest: JSON.stringify([interestArr]),
    };

    dispatch(applyNoAction.applicantAdd(studentInterest, history));
    // window.location.reload();
  };
  const handleCusCountry = (e) => {
    setSelectedValue(e);
  };
  const handleCusCourses = (e) => {
    setCusCourses(e);
  };
  var filterJson: any = [];
  const CustomJson = studentProfile?.qualification
    ? JSON.parse(studentProfile?.qualification)
    : [];
  if (CustomJson) {
    CustomJson?.map((data: any, index: any) => {
      filterJson = data?.customJson;
    });
  }
  if (filterJson) var addCustomFieldsCount = filterJson.length;
  else var addCustomFieldsCount = 0;
  const addCustomFieldsFun = (e) => {
    var AddCustomFields =
      `
    <div class="row w-100 ml-0 set_row_` +
      addCustomFieldsCount +
      `">
      <div class="col-md-4 col-lg-4 _mt-5">
        <div class="field">
          <input type="text" required="" name="cuspassingyear[]" value="">
          <label>enter year</label></div>
        </div>
      <div class="col-md-4 col-lg-4 _mt-5">
        <div class="field">
          <input type="text" required="" name="cusresulttype[]" value="">
          <label>title</label>
        </div>
      </div>
      <div class="col-md-4 col-lg-4 _mt-5">
        <div class="field">
          <input type="text" required="" name="cusmarks[]" value="">
          <label>value</label>
        </div>
      </div>
      <div class="cus_close" onclick="$('.set_row_` +
      addCustomFieldsCount +
      `').remove()"><i class="fa fa-close"></i></div>
    </div>
    `;
    $(".add_new_row").append(AddCustomFields);
    addCustomFieldsCount++;
  };
  const removeRow = (id: any) => {
    $(".set_row_" + id).remove();
  };
  const cleanInputVal = (id: any) => {
    $("#" + id).val("");
  };

  const updatePassword = (e: any) => {
    var isError = false;
    if (passwordValues) {
      if (!passwordValues?.new_password.length) {
        toast.error("Enter new password.");
        return false;
      }
      if (passwordValues?.new_password.length < 6) {
        toast.error("Password should be 6 character.");
        return false;
      }
      if (studentProfile?.password && !passwordValues.old_password) {
        var isError = true;
        toast.error("Enter your current password.");
      }
      if (
        !passwordValues.new_password ||
        !passwordValues.confirm_password ||
        passwordValues.confirm_password != passwordValues.new_password
      ) {
        var isError = true;
        toast.error("New password and confirm password not matched.");
      }
    } else {
      var isError = true;
      toast.error("Enter your new password.");
    }
    if (isError) {
      return false;
    }
    var data = {
      old_password: passwordValues.old_password
        ? passwordValues.old_password
        : null,
      new_password: passwordValues.new_password,
      confirm_password: passwordValues.confirm_password,
      id: user?.id,
      mobile: user?.mobile,
    };
    dispatch(studentAction.updatePassword(data));
  };
  const getQuartersArr = getQuarters();
  console.log(moment().subtract(10, "years").format("DD-MM-YYYY"));

  function subYears(_date: Date, num: number): Date | null | undefined {
    const date = new Date(_date);
    date.setFullYear(date.getFullYear() - num);
    return date;
  }

  return (
    <div className="col-md-12 col-lg-9">
      <div className="row">
        <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
          <div className="page-sidebar">
            {getOFferAndScholar &&
              getOFferAndScholar?.map((data: any, i: any) => {
                return (
                  i < 2 && (
                    <div className="widget sidebar-title ads-blog">
                      <div key={i}>
                        <div className="offers-item_ yello title">
                          <a
                            onClick={(e) => {
                              data?.type == "Offers"
                                ? opneModel(data?.id)
                                : history.push(
                                    "/scholarshipdetails/" + data?.sch_slug
                                  );
                            }}
                            data-toggle="modal"
                            data-target="#offer-pop"
                          >
                            {data?.type == "Offers" ? (
                              data?.coupon_icon !== null ? (
                                <img
                                  className="scale"
                                  src={baseurl + data?.coupon_icon}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                />
                              )
                            ) : data?.sch_image !== null ? (
                              <img
                                className="scale"
                                src={baseurl + data?.sch_image}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/img/noImage.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className="scale"
                                src="/assets/img/noImage.jpeg"
                              />
                            )}
                          </a>
                          <figcaption className="content-box offerHeight_">
                            <a
                              className="margin_auto"
                              onClick={(e) => {
                                data?.type == "Offers"
                                  ? opneModel(data?.id)
                                  : history.push(
                                      "/scholarshipdetails/" + data?.sch_slug
                                    );
                              }}
                              data-toggle="modal"
                              data-target="#offer-pop"
                            >
                              <h5>
                                {data?.type == "Offers" && (
                                  <span>
                                    {data?.coupon_type == 1
                                      ? "Get " + data?.max_off + "%"
                                      : "Get " + data?.max_off}{" "}
                                    OFF
                                  </span>
                                )}
                                {data?.type == "Offers"
                                  ? data?.coupon_title?.length > 16
                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                    : data?.coupon_title
                                  : data?.sch_title?.length > 40
                                  ? `${data?.sch_title.substring(0, 40)}.`
                                  : data?.sch_title}
                              </h5>
                            </a>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}

            {/* <div className="widget sidebar-title ads-blog">
              <img src="assets/img/ad-blog.png" className="scale" alt="" />
            </div> */}
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="contentbx shade1 choachwhat bg-white myprofile h-100">
            <ul className="nav nav-tabs" role="tablist">
              <li
                className="nav-item"
                onClick={() => handleTabClick("basic_details")}
              >
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#tabs-1"
                  role="tab"
                >
                  basic details
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => handleTabClick("education_details")}
              >
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tabs-2"
                  role="tab"
                >
                  educational details
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => handleTabClick("document")}
              >
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tabs-3"
                  role="tab"
                >
                  document
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => handleTabClick("interest")}
              >
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tabs-4"
                  role="tab"
                >
                  interest
                </a>
              </li>
            </ul>
            {/* <!-- Tab panes --> */}
            <div className="tab-content">
              <div className="tab-pane active" id="tabs-1" role="tabpanel">
                <div className="profiletabs shade1 p-4 mb-0">
                  <div className="profilehead">
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        {/* <!-- Your Profile start --> */}
                        <div className="userpic">
                          <span>
                            {studentProfile?.profile_image !== null ? (
                              <img
                                src={baseurl + studentProfile?.profile_image}
                                alt=""
                              />
                            ) : (
                              <img src="/assets/img/dummy-user.png" alt="" />
                            )}
                            {/* <a href="" className="cam">studentProfile?.profile_image
                              <i className="fa fa-pencil"></i>
                            </a> */}
                            <a className="cam">
                              <input
                                type="file"
                                id="profileImage"
                                name="file"
                                onChange={(e) => {
                                  handleProfile(e);
                                }}
                                accept="image/jpg,image/jpeg,image/png"
                              />
                              <i className="fa fa-pencil"></i>
                            </a>
                          </span>
                        </div>
                        <h5 className="color-green pt-3 pb-1">
                          {/* amit choudhary */}
                          {studentProfile?.name}
                        </h5>
                        <p>
                          <a href="">
                            {/* amitchoudhary@gmail.com */}
                            {studentProfile?.email}
                          </a>
                        </p>
                        {/* <!-- Your Profile End --> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile_field fields-wrp">
                        <form
                          action="#"
                          className="row"
                          autoComplete="new-password"
                        >
                          <div className="col-md-12 col-lg-6">
                            <div className="field">
                              <input
                                type="text"
                                name="name"
                                id="userName"
                                autoComplete="off"
                                value={values?.name}
                                onChange={(e: any) => handleChange(e)}
                              />
                              <label>name</label>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                id="userMobile"
                                name="mobile"
                                autoComplete="new-off"
                                value={values?.mobile}
                                onChange={(e: any) => handleChange(e)}
                                maxLength="10"
                              />
                              <label>mobile number</label>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                id="userEmail"
                                autoComplete="new-off"
                                name="email"
                                value={values?.email}
                                onChange={(e: any) => handleChange(e)}
                              />
                              <label>email</label>
                              {(() => {
                                if (studentProfile?.email_verified == 1) {
                                  return (
                                    <a
                                      href="javascript:void(0)"
                                      className="text-green field_outerlink comp_url"
                                    >
                                      verified
                                    </a>
                                  );
                                } else {
                                  if (studentProfile?.email)
                                    return (
                                      <a
                                        className="field_outerlink comp_url"
                                        onClick={verifyEmail}
                                      >
                                        verify email
                                      </a>
                                    );
                                }
                              })()}
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="field_gender">
                              <h6>
                                <span>gender</span>{" "}
                                <input
                                  type="radio"
                                  id="male"
                                  name="gender"
                                  checked={genders == 1}
                                  value="1"
                                  onChange={(e: any) =>
                                    setGender(e.target.value)
                                  }
                                />
                                <label htmlFor="male">
                                  <small>male</small>
                                </label>
                                <input
                                  type="radio"
                                  id="female"
                                  name="gender"
                                  value="2"
                                  checked={genders == 2}
                                  onChange={(e: any) =>
                                    setGender(e.target.value)
                                  }
                                />
                                <label htmlFor="female">
                                  <small>female</small>
                                </label>
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="field">
                              <DatePicker
                                selected={startDate}
                                onChange={(date: any) => setStartDate(date)}
                                peekNextMonth
                                placeholderText="date of birth"
                                showMonthDropdown
                                showYearDropdown
                                name="dob"
                                dateFormat="dd-MM-yyyy"
                                dropdownMode="select"
                                id="userDOB"
                                maxDate={subYears(new Date(), 10)}
                              />

                              {/* <label>mobile number</label> */}
                              <span className="fa fa-calendar"></span>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="field">
                              <input
                                type="text"
                                id="userAddress"
                                autoComplete="new-off"
                                name="address"
                                value={values?.address}
                                onChange={(e: any) => handleChange(e)}
                              />
                              <label>address</label>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                id="userCity"
                                name="city"
                                autoComplete="new-off"
                                value={values?.city}
                                onChange={(e: any) => handleChange(e)}
                              />
                              <label>city</label>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                name="pincode"
                                maxLength="6"
                                autoComplete="nope"
                                id="userPincode"
                                value={values?.pincode}
                                onChange={(e: any) => handleChange(e)}
                                required
                              />
                              <label>pincode</label>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-12">
                            <div className="fieldbtn text-right pt-3">
                              <button
                                type="button"
                                className="view-more flot-right userProfilebtn update_profile"
                                // value="update"
                                onClick={(e: any) => update(e)}
                              >
                                {" "}
                                update
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <hr></hr>
                    </div>
                  </div> */}
                  <div className="profile_field fields-wrp">
                    <form className="profile_field fields-wrp">
                      <div className="row border-1-grey mt-3">
                        <div className="col-md-12">
                          <div className="pt-3">
                            <h5>change password</h5>
                            <hr></hr>
                          </div>
                        </div>
                        {(() => {
                          if (studentProfile?.password) {
                            return (
                              <div className="col-md-12">
                                <div className="field pt-0">
                                  <input
                                    type="password"
                                    name="old_password"
                                    autoComplete="new-off"
                                    id="oldPassword"
                                    onChange={(e: any) =>
                                      handleChangePassword(e)
                                    }
                                    required
                                  />
                                  <label>current password</label>
                                </div>
                              </div>
                            );
                          }
                        })()}

                        <div className="col-md-6">
                          <div className="field">
                            <input
                              type="password"
                              name="new_password"
                              autoComplete="new-off"
                              minLength={6}
                              id="newPassword"
                              onChange={(e: any) => handleChangePassword(e)}
                              required
                            />
                            <label>new password</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="field">
                            <input
                              type="password"
                              name="confirm_password"
                              autoComplete="new-off"
                              minLength={6}
                              id="confirmPassword"
                              onChange={(e: any) => handleChangePassword(e)}
                              required
                            />
                            <label>confirm password</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 mb-3">
                          <div className="fieldbtn text-right pt-3">
                            <button
                              type="button"
                              className="view-more flot-right userProfilebtn update_profile"
                              // value="update"
                              onClick={(e: any) => updatePassword(e)}
                            >
                              {" "}
                              update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tabs-2" role="tabpanel">
                <div className="profiletabs shade1 p-4 mb-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile_field fields-wrp">
                        <form action="#" className="row">
                          {/* <div className="col-md-12 col-lg-12 d-flex" key="1"> */}
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualification10?.passingyear?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                10th passing year
                              </label>
                              <select
                                className="form-control1"
                                value={qualification10?.passingyear}
                                name="passingyear"
                                onChange={(evt) => hendlqulifi10(evt)}
                                id="userTenth"
                              >
                                <option value="">10th passing year</option>
                                {yearsel &&
                                  yearsel?.map((data: any, i: any) => {
                                    return (
                                      i < 15 && (
                                        <option value={data}> {data}</option>
                                      )
                                    );
                                  })}
                                {/* <option>2015</option>
                                <option>2014</option> */}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualification10?.ResultType?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                result type
                              </label>
                              <select
                                className="form-control1"
                                name="ResultType"
                                value={qualification10?.ResultType}
                                onChange={(evt) => hendlqulifi10(evt)}
                                id="userTenthType"
                              >
                                <option value="">result type</option>
                                <option value={"CGPA"}>CGPA</option>
                                <option value={"Grade"}>Grade</option>
                                <option value={"Percentage"}>Percentage</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                name="marks"
                                id="userTenthMarks"
                                required
                                value={qualification10?.marks}
                                onChange={(evt) => hendlqulifi10(evt)}
                              />
                              <label>10th marks</label>
                            </div>
                          </div>
                          {/* </div> */}
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualification12?.passingyear?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                12th passing year
                              </label>
                              <select
                                className="form-control1"
                                name="passingyear"
                                value={qualification12?.passingyear}
                                onChange={(evt) => hendlqulifi12(evt)}
                                id="userTwelfth"
                              >
                                <option value="">12th passing year</option>
                                {yearsel &&
                                  yearsel?.map((data: any, i: any) => {
                                    return (
                                      i < 15 && (
                                        <option value={data}> {data}</option>
                                      )
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualification12?.ResultType?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                result type
                              </label>
                              <select
                                className="form-control1"
                                name="ResultType"
                                value={qualification12?.ResultType}
                                onChange={(evt) => hendlqulifi12(evt)}
                                id="userTwelfthType"
                              >
                                <option value="">result type</option>
                                <option value={"CGPA"}>CGPA</option>
                                <option value={"Grade"}>Grade</option>
                                <option value={"Percentage"}>Percentage</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                required
                                name="marks"
                                value={qualification12?.marks}
                                onChange={(evt) => hendlqulifi12(evt)}
                                id="userTwelfthMarks"
                              />
                              <label>12th marks</label>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualificationug?.passingyear?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                ug passing year
                              </label>
                              <select
                                className="form-control1"
                                name="passingyear"
                                value={qualificationug?.passingyear}
                                onChange={(evt) => hendlqulifiug(evt)}
                              >
                                <option value="">ug passing year</option>
                                {yearsel &&
                                  yearsel?.map((data: any, i: any) => {
                                    return (
                                      i < 15 && (
                                        <option value={data}> {data}</option>
                                      )
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualificationug?.ResultType?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                result type
                              </label>
                              <select
                                className="form-control1"
                                name="ResultType"
                                value={qualificationug?.ResultType}
                                onChange={(evt) => hendlqulifiug(evt)}
                              >
                                <option value="">result type</option>
                                <option value={"CGPA"}>CGPA</option>
                                <option value={"Grade"}>Grade</option>
                                <option value={"Percentage"}>Percentage</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                required
                                name="marks"
                                value={qualificationug?.marks}
                                onChange={(evt) => hendlqulifiug(evt)}
                              />
                              <label>ug marks</label>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualificationpg?.passingyear?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                pg passing year
                              </label>
                              <select
                                className="form-control1"
                                name="passingyear"
                                value={qualificationpg?.passingyear}
                                onChange={(evt) => hendlqulifipg(evt)}
                              >
                                <option value="">pg passing year</option>
                                {yearsel &&
                                  yearsel?.map((data: any, i: any) => {
                                    return (
                                      i < 15 && (
                                        <option value={data}> {data}</option>
                                      )
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div
                              className={
                                qualificationpg?.ResultType?.length > 0
                                  ? "field"
                                  : "field active"
                              }
                            >
                              <label
                                className="control-label1"
                                htmlFor="selectCtrl"
                                style={{ opacity: "1" }}
                              >
                                result type
                              </label>
                              <select
                                className="form-control1"
                                name="ResultType"
                                value={qualificationpg?.ResultType}
                                onChange={(evt) => hendlqulifipg(evt)}
                              >
                                <option value="">result type</option>
                                <option value={"CGPA"}>CGPA</option>
                                <option value={"Grade"}>Grade</option>
                                <option value={"Percentage"}>Percentage</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-4 _mt-5">
                            <div className="field">
                              <input
                                type="text"
                                required
                                name="marks"
                                value={qualificationpg?.marks}
                                onChange={(evt) => hendlqulifipg(evt)}
                              />
                              <label>pg marks</label>
                            </div>
                          </div>
                          <div className="add_new_row w-100">
                            {filterJson &&
                              filterJson.length > 0 &&
                              filterJson.map((v: any, k: any) => {
                                return (
                                  <div
                                    className={"row w-100 ml-0 set_row_" + k}
                                  >
                                    <div className="col-md-4 col-lg-4 _mt-5">
                                      <div className="field">
                                        <input
                                          type="text"
                                          name="cuspassingyear[]"
                                          defaultValue={v.passingyear}
                                        />
                                        <label>enter year</label>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 _mt-5">
                                      <div className="field">
                                        <input
                                          type="text"
                                          name="cusresulttype[]"
                                          defaultValue={v.ResultType}
                                        />
                                        <label>title</label>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 _mt-5">
                                      <div className="field">
                                        <input
                                          type="text"
                                          name="cusmarks[]"
                                          defaultValue={v.marks}
                                        />
                                        <label>value</label>
                                      </div>
                                    </div>
                                    <div
                                      className="cus_close"
                                      onClick={(e) => removeRow(k)}
                                    >
                                      <i className="fa fa-close"></i>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="col-md-12 col-lg-12 pr-0">
                            <div className="fieldbtn text-right pt-3 allvalue">
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => addCustomFieldsFun(e)}
                              >
                                <i className="fa fa-plus-circle"></i>
                              </a>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12">
                            <div className="fieldbtn text-right pt-3">
                              <a
                                // type="submit"
                                className="view-more flot-right userProfilebtn"
                                // value="update"
                                onClick={updateEducationDetails}
                              >
                                {" "}
                                update
                              </a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tabs-3" role="tabpanel">
                <div className="profiletabs shade1 p-4 mb-0 doc-pd">
                  {ExamArr &&
                    ExamArr?.map((data: any, index: any) => {
                      return (
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">{data}</h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-icon">
                              <div>
                                {(() => {
                                  const attachmentFiltedList =
                                    attachmentList.length > 0 &&
                                    attachmentList?.filter((data: any) => {
                                      return data.reference_type == index;
                                    });
                                  if (
                                    attachmentFiltedList.length == 0 ||
                                    attachmentFiltedList == false
                                  ) {
                                    return (
                                      <div>
                                        <a
                                          id="upload__"
                                          style={{
                                            display: index == 1 ? "" : "none",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="markTenth"
                                            onChange={(e) => {
                                              handleMarks10(e);
                                            }}
                                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                                          />
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/upload-icon.svg"
                                            alt=""
                                          />
                                        </a>

                                        <a
                                          id="upload__"
                                          style={{
                                            display: index == 2 ? "" : "none",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="markTwelfth"
                                            onChange={(e) => {
                                              handleMarks12(e);
                                            }}
                                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                                          />
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/upload-icon.svg"
                                            alt=""
                                          />
                                        </a>

                                        <a
                                          id="upload__"
                                          style={{
                                            display: index == 12 ? "" : "none",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="diplomaDocs"
                                            onChange={(e) => {
                                              handleDiploma(e);
                                            }}
                                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                                          />
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/upload-icon.svg"
                                            alt=""
                                          />
                                        </a>

                                        <a
                                          id="upload__"
                                          style={{
                                            display: index == 7 ? "" : "none",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="pteExam"
                                            onChange={(e) => {
                                              handlePteExam(e);
                                            }}
                                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                                          />
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/upload-icon.svg"
                                            alt=""
                                          />
                                        </a>

                                        <a
                                          id="upload__"
                                          style={{
                                            display: index == 8 ? "" : "none",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="greExam"
                                            onChange={(e) => {
                                              handleGreExam(e);
                                            }}
                                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                                          />
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/upload-icon.svg"
                                            alt=""
                                          />
                                        </a>

                                        <a
                                          id="upload__"
                                          style={{
                                            display: index == 11 ? "" : "none",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="curriculum "
                                            onChange={(e) => {
                                              handleCV(e);
                                            }}
                                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                                          />
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/upload-icon.svg"
                                            alt=""
                                          />
                                        </a>

                                        <a
                                          id="upload__"
                                          style={{
                                            display: index == 6 ? "" : "none",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            name="file"
                                            id="passport"
                                            onChange={(e) => {
                                              handlePassport(e);
                                            }}
                                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                                          />
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/upload-icon.svg"
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                    );
                                  } else {
                                    if (
                                      attachmentFiltedList[0]?.is_varified ==
                                        0 ||
                                      attachmentFiltedList[0]?.is_varified == 1
                                    ) {
                                      var currRefeType =
                                        attachmentFiltedList[0]?.reference_type;
                                      return (
                                        <div>
                                          <a
                                            style={{ display: "none" }}
                                            className={"upload__1" + index}
                                          >
                                            <input
                                              type="file"
                                              name="file"
                                              id="markTenth"
                                              onChange={(e) => {
                                                handleMarks10(e);
                                              }}
                                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                                            />
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/upload-icon.svg"
                                              alt=""
                                            />
                                          </a>

                                          <a
                                            style={{ display: "none" }}
                                            className={"upload__2" + index}
                                          >
                                            {" "}
                                            <input
                                              type="file"
                                              name="file"
                                              id="markTwelfth"
                                              onChange={(e) => {
                                                handleMarks12(e);
                                              }}
                                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                                            />
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/upload-icon.svg"
                                              alt=""
                                            />{" "}
                                          </a>

                                          <a
                                            style={{ display: "none" }}
                                            className={"upload__12" + index}
                                          >
                                            {" "}
                                            <input
                                              type="file"
                                              name="file"
                                              id="diplomaDocs"
                                              onChange={(e) => {
                                                handleDiploma(e);
                                              }}
                                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                                            />
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/upload-icon.svg"
                                              alt=""
                                            />{" "}
                                          </a>

                                          <a
                                            style={{ display: "none" }}
                                            className={"upload__7" + index}
                                          >
                                            {" "}
                                            <input
                                              type="file"
                                              name="file"
                                              id="pteExam"
                                              onChange={(e) => {
                                                handlePteExam(e);
                                              }}
                                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                                            />
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/upload-icon.svg"
                                              alt=""
                                            />
                                          </a>

                                          <a
                                            style={{ display: "none" }}
                                            className={"upload__8" + index}
                                          >
                                            {" "}
                                            <input
                                              type="file"
                                              name="file"
                                              id="greExam"
                                              onChange={(e) => {
                                                handleGreExam(e);
                                              }}
                                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                                            />
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/upload-icon.svg"
                                              alt=""
                                            />
                                          </a>

                                          <a
                                            style={{ display: "none" }}
                                            className={"upload__11" + index}
                                          >
                                            {" "}
                                            <input
                                              type="file"
                                              name="file"
                                              id="curriculum "
                                              onChange={(e) => {
                                                handleCV(e);
                                              }}
                                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                                            />
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/upload-icon.svg"
                                              alt=""
                                            />
                                          </a>

                                          <a
                                            style={{ display: "none" }}
                                            className={"upload__6" + index}
                                          >
                                            <input
                                              type="file"
                                              name="file"
                                              id="passport"
                                              onChange={(e) => {
                                                handlePassport(e);
                                              }}
                                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                                            />
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/upload-icon.svg"
                                              alt=""
                                            />
                                          </a>

                                          <a
                                            href={
                                              baseurl +
                                              attachmentFiltedList[0]
                                                ?.attachment
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/eye-icon.svg"
                                              alt=""
                                            />
                                          </a>

                                          <a
                                            className="show-upload"
                                            data-id={
                                              "upload__" + currRefeType + index
                                            }
                                          >
                                            <img
                                              className="icon__"
                                              src="/assets/img/icons/update-icon.svg"
                                              alt=""
                                            />
                                          </a>

                                          {/* <a onClick={(e) => deleteAttachments(attachmentFiltedList[0]?.id)}><img className="icon__" src="/assets/img/icons/delete-icon.svg" alt="" /></a> */}
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <a
                                          href={
                                            baseurl +
                                            attachmentFiltedList[0]?.attachment
                                          }
                                          target="_blank"
                                        >
                                          <img
                                            className="icon__"
                                            src="/assets/img/icons/eye-icon.svg"
                                            alt=""
                                          />
                                        </a>
                                      );
                                    }
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="tab-pane" id="tabs-4" role="tabpanel">
                {(() => {
                  if (Interest.length == 0) {
                    return (
                      <div className="profiletabs shade1 p-2 mb-0 doc-pd">
                        <p>
                          You have not shown you interest{" "}
                          <a
                            className="text_hyper"
                            onClick={() => {
                              history.push("/applynow");
                            }}
                            id="DiscussionBtn"
                          >
                            my interest
                          </a>
                          .
                        </p>
                      </div>
                    );
                  } else {
                    if (Interest[0]?.course_level == 1) {
                      var courseLevel = "Bachelor";
                    } else {
                      var courseLevel = "Master";
                    }
                    if (Interest[0]?.streamData) {
                      var streamData_ = Interest[0]?.streamData?.streamName;
                    } else {
                      var streamData_ = "N/A";
                    }
                    if (Interest[0]?.intake) {
                      var intake_ = Interest[0]?.intake;
                    } else {
                      var intake_ = "N/A";
                    }
                    if (
                      Interest[0]?.course_id &&
                      Interest[0]?.course_id.length > 0
                    ) {
                      var course_id_ = Interest[0]?.course_id;
                    } else {
                      var course_id_ = [];
                    }
                    if (
                      typeof Interest[0]?.objective.objective != "undefined"
                    ) {
                      var objective_ = Interest[0]?.objective.objective;
                    } else {
                      var objective_ = "N/A";
                    }
                    if (
                      Interest[0]?.country_id &&
                      Interest[0]?.country_id.length > 0
                    ) {
                      var country_id_ = Interest[0]?.country_id;
                    } else {
                      var country_id_ = [];
                    }
                    if (
                      Interest[0]?.tests &&
                      Object.keys(Interest[0]?.tests).length > 0
                    ) {
                      var tests_ = Interest[0]?.tests;
                    } else {
                      var tests_ = {};
                    }
                    return (
                      <>
                        <div className="profiletabs shade1 p-4 mb-0 doc-pd">
                          <div className="row doc-sec">
                            <div className="col-md-12 col-sm-6 text-right">
                              <span
                                data-toggle="modal"
                                data-target="#Edit_interests"
                                onClick={() => editUpdateUserInterest()}
                              >
                                <i className="fa fa-edit edit-interest"></i>
                              </span>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">course level</h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6">
                              {courseLevel}
                            </div>
                          </div>
                          <div className="row doc-sec">
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">course</h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6">
                              {streamData_}
                            </div>
                          </div>
                          <div className="row doc-sec">
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">intake</h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6">{intake_}</div>
                          </div>
                          <div className="row doc-sec">
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">specialization</h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6">
                              <div className="" style={{ display: "block" }}>
                                {course_id_?.map((data: any, i: any) => {
                                  return (
                                    <>
                                      <span>
                                        {++i + " ) " + data?.courseName}
                                      </span>
                                      <br />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="row doc-sec">
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">objective</h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6">
                              {objective_}
                            </div>
                          </div>
                          <div className="row doc-sec">
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">
                                  preferred countries
                                </h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6">
                              <div className="" style={{ display: "block" }}>
                                {country_id_?.map((data: any, i: any) => {
                                  return (
                                    <>
                                      <span>
                                        {++i + " ) " + data?.countryName}
                                      </span>
                                      <br />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="row doc-sec">
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">
                                  english proficiency exam
                                </h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6 ">
                              {tests_ ? (
                                <>
                                  <span>
                                    exam name -{" "}
                                    {tests_?.test ? tests_?.test : "N/A"}
                                  </span>
                                  <br />
                                  <span>
                                    obtain marks -{" "}
                                    {tests_?.marks ? tests_?.marks : "N/A"}
                                  </span>
                                </>
                              ) : (
                                tests_?.tests
                              )}
                            </div>
                          </div>
                          <div className="row doc-sec">
                            <div className="col-md-6 col-sm-6">
                              <div className="doc-name">
                                <h1 className="color-green">
                                  highest education
                                </h1>
                              </div>
                            </div>
                            <div className="col-md-5 col-sm-6 text-left">
                              <span>
                                qualification -{" "}
                                {Interest[0]?.highest_edu?.highest_edu}
                              </span>
                              <br />
                              <span>
                                obtain marks -{" "}
                                {
                                  Interest[0]?.highest_edu
                                    ?.Achieved_Or_Expected_marks
                                }
                              </span>
                              <br />
                              <span>
                                result type -{" "}
                                {Interest[0]?.highest_edu?.resultType}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })()}
              </div>
              <div className="tab-pane" id="tabs-5" role="tabpanel">
                <div className="accordion" id="faq5a">
                  <div className="card">
                    <div className="card-header" id="faqhead15a">
                      <a
                        href="#"
                        className="btn btn-header-link"
                        data-toggle="collapse"
                        data-target="#faq15a"
                        aria-expanded="true"
                        aria-controls="faq15a"
                      >
                        How to find TOEFL/PTuniversity?
                      </a>
                    </div>

                    <div
                      id="faq15a"
                      className="collapse show"
                      aria-labelledby="faqhead15a"
                      data-parent="#faq5a"
                    >
                      <div className="card-body">
                        oon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. AAnim
                        pariatur cliche reprehenderit, enim eiusmod high life
                        accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck
                        quinoa nesciunt laborum eiusmod. Brunch 3 wolf md vegan
                        excepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven't heard of them accusamus labore
                        sustainable VHS.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="faqhead25a">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq25a"
                        aria-expanded="true"
                        aria-controls="faq25a"
                      >
                        How to find TOEFL/PTuniversity?
                      </a>
                    </div>

                    <div
                      id="faq25a"
                      className="collapse"
                      aria-labelledby="faqhead25a"
                      data-parent="#faq5a"
                    >
                      <div className="card-body">
                        moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vAnim
                        pariatur cliche reprehenderit, enim eiusmod high life
                        accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck
                        quinoa nesciunt laborum eiusmod. Brunch 3 wolf egan
                        excepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven't heard of them accusamus labore
                        sustainable VHS.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="faqhead35a">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq35a"
                        aria-expanded="true"
                        aria-controls="faq35a"
                      >
                        How to find TOEFL/PTuniversity?
                      </a>
                    </div>

                    <div
                      id="faq35a"
                      className="collapse"
                      aria-labelledby="faqhead35a"
                      data-parent="#faq5a"
                    >
                      <div className="card-body">
                        , sunt aliqua put a bird on it squid single-origin
                        coffee nulla assumenda shoreditch et. Nihil anim
                        keffiyeh helvetica, craft beer labore wes anderson cred
                        nesciunt sapiente ea proident. Ad vegan exc Anim
                        pariatur cliche reprehenderit, enim eiusmod high life
                        accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck
                        quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                        temporepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven't heard of them accusamus labore
                        sustainable VHS.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                      ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                      : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.coupon_code}</span>
                    )}{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      Edit interests
      */}
      <div
        id="Edit_interests"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="row app_now_">
                {(() => {
                  if (Interest[0]?.streamData) {
                    var streamData_ = Interest[0]?.streamData?.streamId;
                  } else {
                    var streamData_ = "";
                  }
                  if (Interest[0]?.intake) {
                    var intake_ = Interest[0]?.intake;
                  } else {
                    var intake_ = "";
                  }
                  if (
                    Interest[0]?.course_id &&
                    Interest[0]?.course_id.length > 0
                  ) {
                    var course_id_ = Interest[0]?.course_id;
                    var course_id_arr = [];
                    course_id_.map((v, i) => {
                      course_id_arr.push({
                        id: parseInt(v.courseId),
                        title: v.courseName,
                      });
                    });
                  } else {
                    var course_id_arr = [];
                  }
                  if (typeof Interest[0]?.objective.objective != "undefined") {
                    var objective_ = Interest[0]?.objective.objective;
                  } else {
                    var objective_ = "";
                  }
                  if (
                    Interest[0]?.country_id &&
                    Interest[0]?.country_id.length > 0
                  ) {
                    var country_id_ = Interest[0]?.country_id;
                    var country_id_arr = [];
                    var countrysArr = [];
                    country_id_.map((v, i) => {
                      country_id_arr.push(parseInt(v.countryId));
                      countrysArr.push({
                        key: v.countryId,
                        value: v.countryName,
                      });
                    });
                  } else {
                    var country_id_arr = [];
                    var countrysArr = [];
                  }
                  if (
                    Interest[0]?.tests &&
                    Object.keys(Interest[0]?.tests).length > 0
                  ) {
                    var tests_ = Interest[0]?.tests;
                  } else {
                    var tests_ = "";
                  }

                  var AllCountrysArr = [];
                  countrys.map((v, i) => {
                    AllCountrysArr.push({
                      key: v.id,
                      value: v.country_name,
                    });
                  });

                  return (
                    <div className="col-lg-12">
                      <h5 className="text-left">Update your interest</h5>
                      <hr />
                      <div className="profiletabs shade1 p-4 mb-0 doc-pd">
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">course level</h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <select
                              name="CourseLevel"
                              className="form-control form-control-sm"
                              onChange={(e) => getStreams(e)}
                            >
                              <option value="">Select Course level</option>
                              <option
                                value="1"
                                selected={
                                  InterestedLevel === "1" ? "selected" : ""
                                }
                              >
                                Bachelor
                              </option>
                              <option
                                value="2"
                                selected={
                                  InterestedLevel === "2" ? "selected" : ""
                                }
                              >
                                Master
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">course</h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <select
                              name="CourseName"
                              className="form-control form-control-sm"
                              onChange={(e) => getCourses(e)}
                            >
                              <option value="">Select Course</option>
                              {StreamData.map((data: any, index: any) => {
                                return (
                                  <option
                                    value={data?.id}
                                    selected={streamData_ == data?.id}
                                  >
                                    {data?.stream_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">intake</h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <select
                              name="IntakeInput"
                              className="form-control form-control-sm"
                            >
                              <option value="">Select Intake</option>
                              {getQuartersArr.map((data: any, index: any) => {
                                return data.map((d: any, dk: any) => {
                                  var date = d[0] + " - " + d[1] + " - " + d[2];
                                  return (
                                    <option
                                      value={date}
                                      selected={intake_ === date}
                                    >
                                      {" "}
                                      {date}
                                    </option>
                                  );
                                });
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">specialization</h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="" style={{ display: "block" }}>
                              {/* <select name="SpecializationInput" className="form-control form-control-sm">
                              <option value="">Select Specialization</option>
                              {CoursesData.map((data: any, index: any) => {
                                return <option value={data?.id} selected={(course_id_arr.includes(data?.id))}>{data?.title}</option>
                              })}
                            </select> */}
                              <Multiselect
                                options={CoursesData}
                                selectedValues={course_id_arr}
                                displayValue="title"
                                showCheckbox={true}
                                id="SpecializationInput"
                                selectionLimit="3"
                                avoidHighlightFirstOption={false}
                                onSelect={(e) => handleCusCourses(e)}
                                onRemove={(e) => handleCusCourses(e)}
                                placeholder="select Specialization"
                                ref={multiselectRef}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">objective</h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <select
                              name="ObjectiveInput"
                              className="form-control form-control-sm"
                            >
                              <option value="">Select Objective</option>
                              <option
                                value="I want to settle outside of my country and go for Permanent Residency (PR)"
                                selected={
                                  objective_ ===
                                  "I want to settle outside of my country and go for Permanent Residency (PR)"
                                }
                              >
                                I want to settle outside of my country and go
                                for Permanent Residency (PR)
                              </option>
                              <option
                                value="I want to get better job and earning opportunities"
                                selected={
                                  objective_ ===
                                  "I want to get better job and earning opportunities"
                                }
                              >
                                I want to get better job and earning
                                opportunities
                              </option>
                              <option
                                value="I want to get education from topmost universities"
                                selected={
                                  objective_ ===
                                  "I want to get education from topmost universities"
                                }
                              >
                                I want to get education from topmost
                                universities
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">
                                preferred countries
                              </h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div className="" style={{ display: "block" }}>
                              <Multiselect
                                options={AllCountrysArr}
                                selectedValues={countrysArr}
                                displayValue="value"
                                id="CountryInput"
                                selectionLimit="3"
                                avoidHighlightFirstOption={false}
                                showCheckbox={true}
                                placeholder="select country"
                                onSelect={(e) => handleCusCountry(e)}
                                onRemove={(e) => handleCusCountry(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">
                                english proficiency exam
                              </h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 text-left">
                            <span>
                              exam name -
                              <select
                                name="ExamName"
                                className="form-control form-control-sm"
                                onChange={(e) => cleanInputVal("ObtainMarks")}
                              >
                                <option value="">Select Exam</option>
                                <option
                                  value="IELTS"
                                  selected={
                                    tests_ ? tests_.test === "IELTS" : ""
                                  }
                                >
                                  IELTS
                                </option>
                                <option
                                  value="PTE"
                                  selected={tests_ ? tests_.test === "PTE" : ""}
                                >
                                  PTE
                                </option>
                                <option
                                  value="TOEFL"
                                  selected={
                                    tests_ ? tests_.test === "TOEFL" : ""
                                  }
                                >
                                  TOEFL
                                </option>
                              </select>
                            </span>
                            <span>
                              obtain marks -
                              <input
                                defaultValue={tests_ ? tests_.marks : ""}
                                type="text"
                                id="ObtainMarks"
                                name="ObtainMarks"
                                className="form-control  form-control-sm"
                              ></input>
                            </span>
                          </div>
                        </div>
                        <div className="row doc-sec">
                          <div className="col-md-6 col-sm-6">
                            <div className="doc-name">
                              <h1 className="color-green">highest education</h1>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 text-left">
                            <span>
                              qualification -
                              <select
                                name="QualificationInput"
                                className="form-control form-control-sm"
                                onChange={(e) =>
                                  cleanInputVal("QualificationObtainMarks")
                                }
                              >
                                <option value="">Select qualification</option>
                                <option
                                  value="12th"
                                  selected={
                                    Interest[0]?.highest_edu?.highest_edu ===
                                    "12th"
                                  }
                                >
                                  12th
                                </option>
                                <option
                                  value="Bachelor"
                                  selected={
                                    Interest[0]?.highest_edu?.highest_edu ===
                                    "Bachelor"
                                  }
                                >
                                  Bachelor
                                </option>
                              </select>
                            </span>
                            <span>
                              result type -
                              <select
                                name="QualificationResultType"
                                className="form-control form-control-sm"
                                onChange={(e) =>
                                  cleanInputVal("QualificationObtainMarks")
                                }
                              >
                                <option value="">Select result type</option>
                                <option
                                  value="CGPA"
                                  selected={
                                    Interest[0]?.highest_edu?.resultType ===
                                    "CGPA"
                                  }
                                >
                                  CGPA
                                </option>
                                <option
                                  value="Grade"
                                  selected={
                                    Interest[0]?.highest_edu?.resultType ===
                                    "Grade"
                                  }
                                >
                                  Grade
                                </option>
                                <option
                                  value="Percentage"
                                  selected={
                                    Interest[0]?.highest_edu?.resultType ===
                                    "Percentage"
                                  }
                                >
                                  Percentage
                                </option>
                              </select>
                            </span>
                            <span>
                              obtain marks -
                              <input
                                type="text"
                                defaultValue={
                                  Interest[0]?.highest_edu
                                    ?.Achieved_Or_Expected_marks
                                }
                                name="QualificationObtainMarks"
                                id="QualificationObtainMarks"
                                className="form-control number  form-control-sm"
                              />
                            </span>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })()}
              </div>
              <figcaption className="details-box pb-3 pt-0">
                <div className="bttns text-center">
                  <a className="applybtn bggreadent">
                    <span
                      className="view-more font-weight-bold _submit_btn"
                      onClick={(e: any) => handleUpdateInterest()}
                    >
                      update
                    </span>
                  </a>
                </div>
              </figcaption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
