import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentAction, scholershipAndOfferAction } from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment"

function Review() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [modelData, setmodelData] = useState<any>("");

    const isAuthenticated: any = getToken();
    var user: any = isAuthenticated && jwt_decode(isAuthenticated);


    const uniReview: any = useSelector((state: any) =>
        state.student.getStudentReview ? state.student.getStudentReview?.data : []
    );
    const baseurl = URL.API_BASE_URL


    const getOFferAndScholar: any[] = useSelector((state: any) =>
        state.scholershipAndOffer.getOFferAndScholar
            ? state.scholershipAndOffer.getOFferAndScholar
            : []
    );
    const opneModel = (e: any) => {

        const modeldata = getOFferAndScholar && getOFferAndScholar?.filter((data: any) => {
            return data?.id == e;
        })

        setmodelData(modeldata)
    }
    const hendleApplyNow = (e: any) => {
        if (isAuthenticated == false) {
            history.push("/login", { logback: 1 });
        } else {
            history.push("/");
        }
    };

    $(".track-appli").click(function (k, v) {
        var id = $(this).attr('data-id');
        console.log(id);
        $("#track-applicants-" + id).fadeIn();
    });


    useEffect(() => {
        if (user?.id && user?.mobile) {
            var Data = ({ id: user?.id, mobile: user?.mobile });
            dispatch(studentAction.getStudentReview(Data));
        }
    }, []);


    useEffect(() => {
        dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
        return () => { };
    }, []);

    return (

        <div className="col-md-12 col-lg-9">
            <div className="row">
                <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
                    <div className="page-sidebar">
                        {getOFferAndScholar &&
                            getOFferAndScholar?.map((data: any, i: any) => {
                                return i < 2 && (
                                    <div className="widget sidebar-title ads-blog">
                                        <div key={i}>
                                            <div className="offers-item_ yello title">
                                                <a onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                                                    data-target="#offer-pop">
                                                    {data?.type == "Offers" ? (
                                                        data?.coupon_icon !== null ? (
                                                            <img
                                                                className="scale"
                                                                src={baseurl + data?.coupon_icon}
                                                                onError={(e: any) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "/assets/img/noImage.jpeg";
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                className="scale"
                                                                src="/assets/img/noImage.jpeg"
                                                            />
                                                        )
                                                    ) : data?.sch_image !== null ? (
                                                        <img
                                                            className="scale"
                                                            src={baseurl + data?.sch_image}
                                                            onError={(e: any) => {
                                                                e.target.onerror = null;
                                                                e.target.src = "/assets/img/noImage.jpeg";
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="scale"
                                                            src="/assets/img/noImage.jpeg"
                                                        />
                                                    )}
                                                </a>
                                                <figcaption className="content-box offerHeight_">
                                                    <a className="margin_auto" onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                                                        data-target="#offer-pop">
                                                        <h5>
                                                            {data?.type == "Offers" && (
                                                                <span>
                                                                    {data?.coupon_type == 1
                                                                        ? 'Get ' + data?.max_off + "%"
                                                                        : 'Get ' + data?.max_off}{" "}
                                                                    OFF
                                                                </span>
                                                            )}
                                                            {data?.type == "Offers"
                                                                ? data?.coupon_title?.length > 16
                                                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                                                    : data?.coupon_title
                                                                : data?.sch_title?.length > 40
                                                                    ? `${data?.sch_title.substring(0, 40)}.`
                                                                    : data?.sch_title}
                                                        </h5>
                                                    </a>
                                                </figcaption>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="col-md-12 col-lg-9">
                    <div className="contentbx shade1 bg-white application_wrap h-100">
                        <section className="categories">
                            <div className="col-md-12 col-lg-12">
                                <div className="appli-t">
                                    <h1>review </h1>
                                    <img src="/assets/img/appoint--icon.svg" />
                                </div>
                                {uniReview && uniReview.length == 0 ? (
                                    <div className="col-lg-12 p-2">
                                        <div className="appli-box shade1">

                                            <div className="col-md-12 appli-box-t">
                                                <p>No reviews found!</p>

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="container mt-5">
                                        {uniReview && uniReview.length > 0 &&
                                            uniReview?.map((data: any, i: any) => {
                                                if (data?.CoachId) {
                                                    var type = 'Mentor';
                                                    var name = data?.CoachName;
                                                    var profile = data?.CoachProfile;
                                                } else {
                                                    var type = 'Counsellor';
                                                    var name = data?.CounsellorName;
                                                    var profile = data?.CounsellorProfile;
                                                }
                                                return (
                                                    <div className="mt-2 first-con1">
                                                        <div className="row revie-uni justify-content-between">
                                                            <div className="col-md-2 pr-0">
                                                                <div className="ml-2">
                                                                    {(() => {
                                                                        if (profile) {
                                                                            return <img className="w-100" src={baseurl + '' + profile} />

                                                                        } else {
                                                                            return <img className="w-100" src="/assets/img/noImage.jpeg" />

                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 pl-0">
                                                                <h1 className="pt-0 pb-0">{name}</h1>
                                                                <div className="revie-p">
                                                                    <p>
                                                                        {data?.Review}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="star-rating01 review-rating text-right  pr-2">
                                                                    <ul>
                                                                        <li className="list-inline-item">
                                                                            <i className="fa fa-star"></i>
                                                                        </li>
                                                                        <li> {data?.Rating ? data?.Rating : "0"}/5 </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div >
            <div
                id="offer-pop"
                className="modal fade offer_detailpopup"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="off-detail-item">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <figure className="feat-text">
                                    {modelData[0]?.type == "Offers" ? (
                                        modelData[0]?.coupon_icon !== null ? (
                                            <img
                                                className="scale homeOffer"
                                                src={baseurl + modelData[0]?.coupon_icon}
                                                onError={(e: any) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "/assets/img/noImage.jpeg";
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                        ) : (
                                            <img
                                                className="scale homeOffer"
                                                src="/assets/img/noImage.jpeg"
                                            />
                                        )
                                    ) : modelData[0]?.sch_image !== null ? (
                                        <img
                                            className="scale homeOffer"
                                            src={baseurl + modelData[0]?.sch_image}
                                            onError={(e: any) => {
                                                e.target.onerror = null;
                                                e.target.src = "/assets/img/noImage.jpeg";
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className="scale homeOffer"
                                            src="/assets/img/noImage.jpeg"
                                        />
                                    )}
                                </figure>
                                <figcaption className="content-box">
                                    <h3>
                                        {modelData[0]?.type == "Offers" ? "Get" : ""}
                                        {modelData[0]?.type == "Offers" && (
                                            <span>
                                                {modelData[0]?.coupon_type == 1
                                                    ? modelData[0]?.max_off + "%"
                                                    : modelData[0]?.max_off}{" "}
                                                OFF
                                            </span>
                                        )}
                                        {modelData[0]?.type == "Offers"
                                            ? modelData[0]?.coupon_title?.length > 16
                                                ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                                                : modelData[0]?.coupon_title
                                            : modelData[0]?.sch_title?.length > 40
                                                ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                                                : modelData[0]?.sch_title}
                                        {/* Study in Abroad */}
                                    </h3>
                                    <div className="bttns">
                                        <a href="" className="tnc">
                                            t&amp;c apply
                                        </a>
                                    </div>
                                </figcaption>
                                <figcaption className="details-box p-3">
                                    <p className="text-left">Coupon Code:{" "}{modelData[0]?.type == "Offers" && (
                                        <span>
                                            {modelData[0]?.coupon_code}
                                        </span>
                                    )} </p>
                                    <p className="text-right">exp:{" "}{modelData[0]?.type == "Offers" && (
                                        <span>
                                            {moment(modelData[0]?.valid_from).format('DD-MM-YYYY')}
                                        </span>
                                    )}
                                        {modelData[0]?.type == "ScholarShip"
                                            ? `${modelData[0]?.apply_end_date}.`
                                            : ""}</p>
                                    <p className="text-left">
                                        {/* {modelData[0]?.description} */}
                                        {modelData[0]?.type == "Offers" && (
                                            <span>
                                                {modelData[0]?.description}
                                            </span>
                                        )}
                                        {modelData[0]?.type == "ScholarShip"
                                            ? `${modelData[0]?.short_desc}.`
                                            : ""}
                                    </p>
                                    <div className="bttns text-right">
                                        <a onClick={hendleApplyNow} className="applybtn bggreadent">
                                            apply now
                                        </a>
                                    </div>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Review
