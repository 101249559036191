import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { getSeoDetail } from "../../redux/setting/action";

function Seo(props: any) {
  const { slug } = props;
  const url = window.location.href;
  let university: any = {};
  const dispatch = useDispatch();
  const seo: any = useSelector((state: any) => state.setting.seo);
  const defaultTitle =
    "Best Overseas Education Consultant in Delhi | study abroad free";
  const defaultDescription =
    "Best Study Abroad Consultants in India, Study in Germany, US, Canada, UK - Apply FREE on the Admissify App";
  const defaultKewords =
    "Overseas Education Consultant in Delhi, study abroad free,abroad study scholarship,how to apply direct to study abroad,study abroad consultants in delhi";

  useEffect(() => {
    let _slug:string = slug && slug?.replace("/", "");
    dispatch(getSeoDetail({ slug: _slug }));
    return () => {};
  }, [slug]);
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {(props?.title || seo?.title || defaultTitle) + " - Admissify"}
        </title>
        <meta
          name="description"
          content={
            props?.meta_description ||
            seo?.meta_description ||
            defaultDescription
          }
        />
        <meta
          name="keywords"
          content={props?.meta_keywords || seo?.meta_keywords || defaultKewords}
        />
        <link rel="canonical" href={url || "https://www.admissify.com"} />
        <meta
          property="og:title"
          content={props?.title || seo?.title || defaultTitle}
        />
        <meta
          property="og:description"
          content={
            props?.meta_description ||
            seo?.og_description ||
            defaultDescription
          }
        />
        <meta property="og:url" content={url || "https://www.admissify.com"} />
        <meta
          property="article:modified_time"
          content={
            props?.modified_time || seo?.updated_at || seo?.created_at || ""
          }
        />
        <meta
          property="og:image"
          content={
            props?.image
              ? props?.image
              : "https://www.admissify.com/assets/img/Admissfy_Logo.png"
          }
        />
        {slug !== "/" && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              Name: props?.title || seo?.title || defaultTitle || "",
              description:
                props?.meta_description ||
                seo?.meta_description ||
                defaultDescription ||
                "",
              url: url,
            })}
          </script>
        )}
        {slug === "/" && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              url: url,
              logo: "https://www.admissify.com/assets/img/Admissfy_Logo.png",
              name: "Admissify",
              sameAs: [
                "https://www.facebook.com/admissify",
                "https://www.instagram.com/admissify",
                "https://twitter.com/admissify",
                "https://www.linkedin.com/company/admissifyedutechpvt.ltd",
              ],
            })}
          </script>
        )}
        {slug === "/" && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              url: url,
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://query.example.com/search?q={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "android-app://com.example/https/query.example.com/search/?q={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
            })}
          </script>
        )}
      </Helmet>
    </div>
  );
}

export default Seo;
