import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { applyNoAction, scholershipAndOfferAction } from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import { sorting } from "../../Utils/Common/Common";
import moment from "moment"

function Appointment() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [modelData, setmodelData] = useState<any>("");

    const isAuthenticated: any = getToken();
    var user: any = isAuthenticated && jwt_decode(isAuthenticated);

    const filteraplicant_: any = useSelector((state: any) =>
        state.applyNow.getAllaplicant ? state.applyNow.getApplicationList : []
    );

    const filteraplicant = filteraplicant_.sort((n1: any, n2: any) => {
        return sorting(n1?.created_date, n2?.created_date)
    })
    const baseurl = URL.API_BASE_URL



    // const filteraplicant = getAllaplicant && getAllaplicant?.filter((data: any) => {
    //     return data?.student_id == user?.id
    // })
    const getOFferAndScholar: any[] = useSelector((state: any) =>
        state.scholershipAndOffer.getOFferAndScholar
            ? state.scholershipAndOffer.getOFferAndScholar
            : []
    );
    const opneModel = (e: any) => {

        const modeldata = getOFferAndScholar && getOFferAndScholar?.filter((data: any) => {
            return data?.id == e;
        })

        setmodelData(modeldata)
    }
    const hendleApplyNow = (e: any) => {
        if (isAuthenticated == false) {
            history.push("/login", { logback: 1 });
        } else {
            history.push("/");
        }
    };
    const trackAppli = (e: any) => {
        // console.log(e.target);
        var id = $(e.target).attr('data-id');
        // console.log(id);
        $("#track-applicants-" + id).toggle();
    }
    // $(".track-appli").click(function (k, v) {
    // 
    // });

    useEffect(() => {
        dispatch(applyNoAction.getapplicationlist({
            // student_id: user?.id, mobile: user?.mobile
        }));
        return () => { };
    }, []);


    useEffect(() => {
        dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
        return () => { };
    }, []);

    const moreCoursesAp = (e: any) => {
        $(e.target).parents('.more-courses-ap_').find('.hide').slideDown();
        $(e.target).fadeOut(0);
    }
    function getTrackClass(val: any, status: any) {
        // console.log(val,status)
        if (val == status) {
            return "done active"
        } else if (Number(val) > Number(status)) {
            return "done"
        } else {
            return ""
        }
        /*  data?.application_status > 1 ? "done active" data?.application_status == 1 ? "done active":"" */
    }
    const statusArray = [
        'N/A',
        'Process Started',
        'Document submitted',
        'Offer Awaited',
        'Unconditional awaited',
        'Offer Accepted',
        'Deposit Made',
        'Visa Filed',
        'In-Visa Process',
        'Visa Letter Received',
        'Accommodation'
    ];

    return (

        <div className="col-md-12 col-lg-9">
            <div className="row">
                <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
                    <div className="page-sidebar">
                        {getOFferAndScholar &&
                            getOFferAndScholar?.map((data: any, i: any) => {
                                return i < 2 && (
                                    <div className="widget sidebar-title ads-blog">
                                        <div key={i}>
                                            <div className="offers-item_ yello title">
                                                <a onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                                                    data-target="#offer-pop">
                                                    {data?.type == "Offers" ? (
                                                        data?.coupon_icon !== null ? (
                                                            <img
                                                                className="scale"
                                                                src={baseurl + data?.coupon_icon}
                                                                onError={(e: any) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "/assets/img/noImage.jpeg";
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                className="scale"
                                                                src="/assets/img/noImage.jpeg"
                                                            />
                                                        )
                                                    ) : data?.sch_image !== null ? (
                                                        <img
                                                            className="scale"
                                                            src={baseurl + data?.sch_image}
                                                            onError={(e: any) => {
                                                                e.target.onerror = null;
                                                                e.target.src = "/assets/img/noImage.jpeg";
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="scale"
                                                            src="/assets/img/noImage.jpeg"
                                                        />
                                                    )}
                                                </a>
                                                <figcaption className="content-box offerHeight_">
                                                    <a className="margin_auto" onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                                                        data-target="#offer-pop">
                                                        <h5>
                                                            {data?.type == "Offers" && (
                                                                <span>
                                                                    {data?.coupon_type == 1
                                                                        ? 'Get ' + data?.max_off + "%"
                                                                        : 'Get ' + data?.max_off}{" "}
                                                                    OFF
                                                                </span>
                                                            )}
                                                            {data?.type == "Offers"
                                                                ? data?.coupon_title?.length > 16
                                                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                                                    : data?.coupon_title
                                                                : data?.sch_title?.length > 40
                                                                    ? `${data?.sch_title.substring(0, 40)}.`
                                                                    : data?.sch_title}
                                                        </h5>
                                                    </a>
                                                </figcaption>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="col-md-12 col-lg-9">
                    <div className="contentbx shade1 bg-white application_wrap 100">

                        <section className="categories">
                            <div className="col-md-12 col-lg-12">
                                <div className="appli-t">
                                    <h1>applications</h1>
                                    <img src="/assets/img/appli-icon.svg" />
                                </div>
                                {filteraplicant.length == 0 ? (
                                    <div className="p-2">
                                        <div className="appli-box shade1">

                                            <div className="col-md-12 appli-box-t">
                                                <p>No application found{' '}
                                                    <a className="text_hyper" onClick={() => { history.push("/universities"); }} id="DiscussionBtn">apply now</a>.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {filteraplicant.length > 0 && filteraplicant?.map((data: any, i: any) => {

                                            let appliedDate = data?.created_at && dateFormat(data?.created_at, "dd-mmm-yyyy");
                                            let lastUpdate = data?.updated_at && dateFormat(data?.updated_at, "dd-mmm-yyyy");
                                            return (
                                                <div className="p-2" key={i}>
                                                    <div className="appli-box bt-application">
                                                        <div className="col-md-12 appli-box-t">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="short-list-uni">
                                                                        {
                                                                            data?.ad_university?.university_logo !== null ?
                                                                                <img src={baseurl + data?.ad_university?.university_logo} alt="" /> :
                                                                                <img src="/assets/img/noImage.jpeg" alt="" />}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9 appli-box-d">
                                                                    <div className="row">
                                                                        <div className="col-md-6 pd-0">
                                                                            <p className="appli-uni-name">
                                                                                <strong>{data?.ad_university?.university_name !== null ? data?.ad_university?.university_name : ""}</strong>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-md-6 pd-0">
                                                                            <p className="text-right">
                                                                                applied date: <strong>{appliedDate && appliedDate}</strong>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-7 more-courses-ap_ pd-0"><p>
                                                                            <strong>course:</strong> {data?.ad_university_course?.university_course ? data?.ad_university_course?.university_course : ''}
                                                                        </p>
                                                                        </div>
                                                                        <div className="col-md-5 pd-0">
                                                                            <p className="appli-st">

                                                                                status: &nbsp;
                                                                                <strong>{statusArray[data?.status] || 'N/A'}</strong>

                                                                            </p>
                                                                            <p className="text-right">
                                                                                last update: <strong>{lastUpdate && lastUpdate}</strong>
                                                                            </p>
                                                                            <p className="track-appli" data-id={i} onClick={(e) => { trackAppli(e) }}>
                                                                                track application status
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="steps" style={{ display: "none" }} id={"track-applicants-" + i}>
                                                            <ul>
                                                                <li className={getTrackClass(data?.status, 1)}>
                                                                    <span>process started</span>
                                                                </li>
                                                                <li className={getTrackClass(data?.status, 2)}><span>document submited</span></li>
                                                                <li className={getTrackClass(data?.status, 3)}><span>offer awaited</span></li>
                                                                <li className={getTrackClass(data?.status, 4)}><span>uncoditional awaited</span></li>
                                                                <li className={getTrackClass(data?.status, 5)}><span>offer accepted</span></li>
                                                                <li className={getTrackClass(data?.status, 6)}><span>deposit made</span></li>
                                                                <li className={getTrackClass(data?.status, 7)}><span>visa filed</span></li>
                                                                <li className={getTrackClass(data?.status, 8)}><span>in-visa process</span></li>
                                                                <li className={getTrackClass(data?.status, 9)}><span>visa letter received</span></li>
                                                                <li className={getTrackClass(data?.status, 10)}><span>accommodation</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div > <div
                id="offer-pop"
                className="modal fade offer_detailpopup"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="off-detail-item">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <figure className="feat-text">
                                    {modelData[0]?.type == "Offers" ? (
                                        modelData[0]?.coupon_icon !== null ? (
                                            <img
                                                className="scale homeOffer"
                                                src={baseurl + modelData[0]?.coupon_icon}
                                                onError={(e: any) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "/assets/img/noImage.jpeg";
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                        ) : (
                                            <img
                                                className="scale homeOffer"
                                                src="/assets/img/noImage.jpeg"
                                            />
                                        )
                                    ) : modelData[0]?.sch_image !== null ? (
                                        <img
                                            className="scale homeOffer"
                                            src={baseurl + modelData[0]?.sch_image}
                                            onError={(e: any) => {
                                                e.target.onerror = null;
                                                e.target.src = "/assets/img/noImage.jpeg";
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className="scale homeOffer"
                                            src="/assets/img/noImage.jpeg"
                                        />
                                    )}
                                </figure>
                                <figcaption className="content-box">
                                    <h3>
                                        {modelData[0]?.type == "Offers" ? "Get" : ""}
                                        {modelData[0]?.type == "Offers" && (
                                            <span>
                                                {modelData[0]?.coupon_type == 1
                                                    ? modelData[0]?.max_off + "%"
                                                    : modelData[0]?.max_off}{" "}
                                                OFF
                                            </span>
                                        )}
                                        {modelData[0]?.type == "Offers"
                                            ? modelData[0]?.coupon_title?.length > 16
                                                ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                                                : modelData[0]?.coupon_title
                                            : modelData[0]?.sch_title?.length > 40
                                                ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                                                : modelData[0]?.sch_title}
                                        {/* Study in Abroad */}
                                    </h3>
                                    <div className="bttns">
                                        <a href="" className="tnc">
                                            t&amp;c apply
                                        </a>
                                    </div>
                                </figcaption>
                                <figcaption className="details-box p-3">
                                    <p className="text-left">Coupon Code:{" "}{modelData[0]?.type == "Offers" && (
                                        <span>
                                            {modelData[0]?.coupon_code}
                                        </span>
                                    )} </p>
                                    <p className="text-right">exp:{" "}{modelData[0]?.type == "Offers" && (
                                        <span>
                                            {moment(modelData[0]?.valid_from).format('DD-MM-YYYY')}
                                        </span>
                                    )}
                                        {modelData[0]?.type == "ScholarShip"
                                            ? `${modelData[0]?.apply_end_date}.`
                                            : ""}</p>
                                    <p className="text-left">
                                        {/* {modelData[0]?.description} */}
                                        {modelData[0]?.type == "Offers" && (
                                            <span>
                                                {modelData[0]?.description}
                                            </span>
                                        )}
                                        {modelData[0]?.type == "ScholarShip"
                                            ? `${modelData[0]?.short_desc}.`
                                            : ""}
                                    </p>
                                    <div className="bttns text-right">
                                        <a onClick={hendleApplyNow} className="applybtn bggreadent">
                                            apply now
                                        </a>
                                    </div>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Appointment
