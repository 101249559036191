import React from 'react'
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";

function HomeServices() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div>
      <section className="categories section greybg h-servives" id="services">
        <div className="container">
          <div className="headingmains text-center">
            <h2 className="titlewithline right">
              Services
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43.032"
                  height="35.894"
                  viewBox="0 0 43.032 35.894"
                >
                  <path
                    id="mortarboard"
                    d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                    transform="translate(0 -42.469)"
                    fill="#fbb415"
                  />
                </svg>
              </span>
            </h2>
            <p className="text-center pl-2 pr-2">
              admissify is there for the benefit of the dreamers. We provide exclusive services to make you achieve what you wish.
            </p>
          </div>

          <div className="">
            <div className="ser-box col-12">
              <div>
                <Slider {...settings}>
                  <div>
                    <Link to={"/counsellor"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/counsellor.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Counsellor</h4>
                          <p>You've labored tough to urge during which you're now. You understand you've got the potential to be among the first-rate for your field. You would like to form the right selections approximately your subsequent steps now if you would like to get your goals. 
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/internship-placement"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/iandp1.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Internship & Placement</h4>
                          <p>Guide On Internship And Placement From Admissify When aspiring to study abroad, you must have some plans to get a bright future. No matter your discipline, a satisfying career will be waiting as you get a degree from a prestigious institution.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/mentor"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/counsellor.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Mentor</h4>
                          <p>Should a mentor's goal be to become perfect? This is the ideal workout, training program, or practice routine. A perfect sequence of signals or progressions. It's a seductive goal and one that can be classified as aspirational.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/funding"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/ser02.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Funding</h4>
                          <p>
                            You've determined to look overseas and find out the globe, that's
                            great! Let's start now with an inventory of estimations so that you recognize wherein your cash got to be spent.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/scholarship"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/ser01.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Exclusive Scholarships</h4>
                          <p>
                            Interested candidates can apply for different scholarship schemes. They are available under various courses. We will tell you the eligibility criteria to apply for a particular scheme and the related benefits. Hurry today and be a member of admissify.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/free-admissify-common-app"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/app-icon4.png"
                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Free admissify Common App</h4>
                          <p>
                            Get the admissify app for free. It is a common platform for all aspirants. The immigration process for further studies becomes smooth with the professionals by your side. Download the free admissify common app today and enjoy the world-class infrastructure.
                          </p>
                        </div>
                      </div></Link>
                  </div>
                  <div>
                    <Link to={"/accommodation"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/ser03.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">International Accommodation </h4>
                          <p>
                            Do not be tense about your accommodation for staying abroad. admissify has all the arrangements to provide suitable lodging facilities to each aspirant. Top-class facilities are available for the aspirants at affordable prices.
                          </p>
                        </div>
                      </div></Link>
                  </div>

                  <div>
                    <Link to={"/alumni-chat-details"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/app-icon2.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Alumni chat</h4>
                          <p>
                            Want to talk with the experts? The Alumni Chat options provide you the chance to talk freely with the ex-students or professors of prestigious institutions. The service is open 24/7. Introduce yourself and start chatting to clear all your doubts.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/test-preparation"}>
                      <div className="service-category" style={{ height: "300px" }}>
                        <div>
                          <span className="icon-circle">
                            <img
                              className="scale"
                              src="assets/img/test-pre.logo.png"

                              alt=""
                            ></img>
                          </span>
                          <h4 className="title">Test Preparation</h4>
                          <p>
                            Forget about the tension of speaking in English. We give you lessons of IELTS and GMAT to crack them with flying colors. Get admission to your preferred institute faster and get the cut-off marks.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomeServices
