import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { UniversityAction } from "../../redux/common/action";
import { URL } from "../../redux/common/url";

function AdSlider() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const dispatch = useDispatch();
  const adBanner: any[] = useSelector((state: any) =>
    state.unversity.adBanner ? state.unversity.adBanner : []
  );

  console.log(adBanner, "banners");

  return (
    <div>
      <div className="ad-slider mb-4">
        <Slider {...settings}>
          {/* <div className="itemslider">
            <a href="https://forms.gle/r8c3Q93Y7nwpdixi6" target="_blank"><img src="/assets/img/banner-live-01.jpg" alt="" /> </a>
          </div> */}
          {adBanner?.length > 0 &&
            adBanner.map((data: any) => (
              <div className="itemslider">
                <img src={URL.API_BASE_URL + data?.images} alt="" />
              </div>
            ))}
          {/* <div className="itemslider">
            <img src="/assets/img/Scholarship-ad-Banner-for-new-website.jpg" alt="" />
          </div>
          <div className="itemslider">
            <img src="/assets/img/IELTS-AD-BANNER-1034-FOR-NEW-WEBSITE.jpg" alt="" />
          </div> */}
        </Slider>
      </div>
    </div>
  );
}

export default AdSlider;
