import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  counselloerAction,
  DiscussionAction,
  UniversityAction,
  scholershipAndOfferAction,
} from "../../redux/common/action";
import dateFormat from "dateformat";
import StarRatings from "react-star-ratings";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { URL } from "../../redux/common/url";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import Seo from "../Seo/Seo";
import Slider from "react-slick";
import { toast } from "react-toastify";
import moment from "moment";
import SocialShareButton from "../SocialShareButton";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
function DiscussionDetails() {
  const urlsforShare = window.location.href;
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const [modelData, setmodelData] = useState<any>("");
  const history = useHistory();
  const baseurl = URL.API_BASE_URL || "";
  const params: any = useParams();
  const isAuthenticated = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [discussionComment, setdiscussionComment] = useState<any>("");
  const [CommentBox, setCommentBox] = useState<any>("");
  const [Categories, setcategories] = useState<any[]>([]);
  const [viewAll, setviewAll] = useState<any>("5");
  const discuss: any = useSelector((state: any) =>
    state.discussion.discussionById ? state.discussion.discussionById : []
  );
  const getDiscussionCategory: any[] = useSelector((state: any) =>
    state.discussion.getDiscussionCategory
      ? state.discussion.getDiscussionCategory
      : []
  );
  const activegetDiscussionCategory =
    getDiscussionCategory &&
    getDiscussionCategory?.filter((data: any) => {
      return data?.status == 1;
    });
  const releteddiscuss: any[] = useSelector((state: any) =>
    state.discussion.getRelatedDiscussion
      ? state.discussion.getRelatedDiscussion
      : []
  );

  const discussComment: any[] = useSelector((state: any) =>
    state.discussion.getDiscussionComment
      ? state.discussion.getDiscussionComment
      : []
  );
  const discussCommentadd: any = useSelector((state: any) =>
    state.discussion.DiscussionCommentAdd
      ? state.discussion.DiscussionCommentAdd
      : ""
  );

  let commentdates = dateFormat(discussCommentadd?.created_at, " dd-mmm-yyyy");

  const discussComments = discussComment?.filter((data: any) => {
    return data?.discussion_id;
  });

  let date = dateFormat(discuss?.data?.created_at, " dd-mmm-yyyy");

  const trendingUniversities: any[] = useSelector((state: any) =>
    state.unversity.trendingUniversities
      ? state.unversity.trendingUniversities
      : []
  );

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );

  const likeUnlike: any = useSelector((state: any) =>
    state.discussion.likeUnlike ? state.discussion.likeUnlike : []
  );

  const commentlikeUnlike: any = useSelector((state: any) =>
    state.discussion.commentlikeUnlike ? state.discussion.commentlikeUnlike : []
  );
  const activeCounselloer =
    counsellor &&
    counsellor?.filter((data: any) => {
      return data?.status == 1;
    });

  const result: any = discuss?.data?.DiscussionLikes?.some(
    (obj: any) => obj?.student_id == user?.id && user?.id
  );

  /**
   * Hendle Like Unilike
   */

  const hendleLike = (e: any, data: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      dispatch(
        DiscussionAction.discussionLikeUnlike({
          discussion_id: data,
          student_id: user?.id,
        })
      );
    }
  };
  /***
   *
   * Comment like unlike
   */
  const handleCommentLike = (e: any, data: any, commentId: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      dispatch(
        DiscussionAction.CommentLikeUnlike({
          discussion_id: data,
          student_id: user?.id,
          comment_id: commentId,
        })
      );
    }
  };

  const hendleCommentBox = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      if (CommentBox == e) {
        setCommentBox("");
      } else {
        setCommentBox(e);
        setdiscussionComment("");
      }
    }
  };
  useEffect(() => {
    dispatch(
      UniversityAction.getTrendingUniversities({
        search_by: "",
        offset: 0,
        country_id: "",
        is_trending: "",
        limit: 10,
      })
    );
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(counselloerAction.getAllCounsellor({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(DiscussionAction.getDiscussionsbyId(params?.slug));
    return () => {};
  }, [likeUnlike]);

  useEffect(() => {
    dispatch(DiscussionAction.getRelatedDiscussion({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => {};
  }, []);
  // useEffect(() => {
  //   dispatch(DiscussionAction.getDisCategoryList({}));
  //   return () => {};
  // }, []);
  // console.log(discuss);
  useEffect(() => {
    if (discuss?.data?.id) {
      var discussion_id = discuss?.data?.id;
      dispatch(
        DiscussionAction.getdiscommentlist({
          user_type: "",
          discussion_id: discussion_id,
          limit: 100,
          offset: 0,
        })
      );
      return () => {};
    }
  }, [discussCommentadd, discuss, commentlikeUnlike]);

  useEffect(() => {
    setdiscussionComment("");
  }, [discussComment]);
  useEffect(() => {
    setdiscussionComment("");
  }, [discussComment]);

  const hendleComment = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      if (discussionComment?.trim()?.length > 0) {
        if (discuss?.data?.id) {
          /**
           * user_type == 1 = student
           */
          dispatch(
            DiscussionAction.discommentAdd({
              discussion_id: discuss?.data?.id,
              user_type: 1,
              user_id: user?.id ? user?.id : null,
              comments: discussionComment,
            })
          );
          // dispatch(DiscussionAction.getdiscommentlist({limit:100,offset:0}));
          setdiscussionComment("");
          return () => {};
        } else {
          toast.error("Discussion id is missing.");
        }
      }
    }
  };
  const handleCommentReply = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      var user: any = isAuthenticated && jwt_decode(isAuthenticated);
      if (discuss?.data?.id) {
        /**
         * user_type == 1 = student
         */
        dispatch(
          DiscussionAction.discommentAdd({
            discussion_id: discuss?.data?.id,
            user_type: 1,
            user_id: user?.id ? user?.id : null,
            comments: discussionComment,
            parent_id: e,
          })
        );
        // dispatch(DiscussionAction.getdiscommentlist({limit:100,offset:0}));
        setdiscussionComment("");
        return () => {};
      } else {
        toast.error("Discussion id is missing.");
      }
    }
  };

  const hendlClickOtherDisco = (e: any) => {
    // e.preventDefault();
    history.push("/about-discussion/" + e + "", {
      counselloer_id: e,
    });
    // dispatch(DiscussionAction.getdiscommentlist({limit:100,offset:0}));

    dispatch(DiscussionAction.getDiscussionsbyId(e));
    return () => {};
  };

  var singleslider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );
  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      window.location.href = "/login";
    } else {
      window.location.href = "/";
    }
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
useEffect(() => {
  window?.scrollTo(0, 0);
  })

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        title={discuss?.data?.topic}
        meta_description={discuss?.data?.description}
        meta_keywords={""}
        modified_time={discuss?.data?.updated_at || discuss?.data?.created_at}
        image={""}
      />
      <section className="unifilter-page">
        <div className="container">
          <div className="mob-mt-160">
            <Breadcrumb
              page={"Discussion"}
              data={{ slug: params?.slug, path: "/discussions" }}
            />
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-pull-10">
              <div className="page-sidebar">
                <div className="widget counsellor shade2">
                  <h5 className="headgreenbg">counsellor </h5>
                  <ul className="bg-white">
                    {activeCounselloer &&
                      activeCounselloer?.map((data: any, i: any) => {
                        return (
                          i < 6 && (
                            <li key={i}>
                              <div className="counsellorBox">
                                <div
                                  className="relatedCounsellor"
                                  onClick={() => {
                                    history.push("/counsellor/" + data?.slug, {
                                      counselloer_id: data?.id,
                                    });
                                  }}
                                  style={{
                                    background: `url(${
                                      baseurl + data?.profile_image
                                    })`,
                                    cursor: "pointer",
                                  }}
                                ></div>
                              </div>
                              {/* <img src="assets/img/testi.png" alt="" /> */}
                              <div className="counsellorName">
                                <h6
                                  onClick={() => {
                                    history.push("/counsellor/" + data?.slug, {
                                      counselloer_id: data?.id,
                                    });
                                  }}
                                >
                                  {data?.gender == 1 ? "Mr." : "Ms. "}{" "}
                                  {data?.name}{" "}
                                </h6>
                                <div className="feat-inn discussiondetails">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />
                                  </ul>
                                </div>
                                <p>
                                  <small>
                                    {data?.counselled ? data?.counselled : 0}{" "}
                                    student counselled
                                  </small>
                                </p>
                              </div>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-2 order-lg-2">
              <div className="page-sidebar">
                {/* <!-- WIDGET --> */}
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    related <b>discussion</b>{" "}
                  </h5>
                  <ul className="bg-white">
                    {releteddiscuss &&
                      releteddiscuss?.map((data: any, i: any) => {
                        if (discuss?.data?.topic !== data?.topic)
                          return (
                            <li key={i}>
                              <p>
                                <a
                                  className="__hover"
                                  onClick={() =>
                                    hendlClickOtherDisco(data?.slug)
                                  }
                                >
                                  {data?.topic && data?.topic}
                                </a>
                              </p>
                            </li>
                          );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget sidebar-title shade2 radius5 text-center">
                  <div className="singleslider">
                    <Slider {...singleslider}>
                      {getOFferAndScholar &&
                        getOFferAndScholar?.map((data: any, i: any) => {
                          if (data?.type == "Offers")
                            return (
                              <div className="offers-item">
                                <figure className="feat-text">
                                  <img
                                    className="scale"
                                    src={baseurl + data?.coupon_icon}
                                  />
                                </figure>
                                <figcaption className="content-box">
                                  <a
                                    onClick={(e) => {
                                      data?.type == "Offers"
                                        ? opneModel(data?.id)
                                        : history.push(
                                            "/scholarshipdetails/" +
                                              data?.sch_slug
                                          );
                                    }}
                                    data-toggle="modal"
                                    data-target="#offer-pop"
                                    className="mt_btn_yellow"
                                  >
                                    view details
                                  </a>
                                  <h3>
                                    Get{" "}
                                    <span>
                                      {data?.coupon_type == 1
                                        ? data?.max_off + "%"
                                        : data?.max_off}{" "}
                                      OFF
                                    </span>{" "}
                                    {data?.coupon_title}
                                  </h3>
                                </figcaption>
                              </div>
                            );
                        })}
                    </Slider>
                  </div>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget counsellor discussion shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    trending <b>university</b>{" "}
                  </h5>
                  <ul className="bg-white">
                    {trendingUniversities?.map((data: any, i: any) => {
                      return (
                        <li key={i}>
                          {data?.university_logo !== null ? (
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                history.push("/university/" + data?.slug, {
                                  university_id: data?.id,
                                });
                              }}
                              src={baseurl + data?.university_logo}
                              alt=""
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "/assets/img/university-icon.png";
                              }}
                            />
                          ) : (
                            <img
                              src="/assets/img/university-icon.png"
                              alt=""
                              className="trending-img"
                            />
                          )}
                          <div className="counsellorName">
                            <h6
                              className="__hover"
                              onClick={() => {
                                history.push("/university/" + data?.slug, {
                                  university_id: data?.id,
                                });
                              }}
                            >
                              {data?.university_name && data?.university_name}
                            </h6>
                            <div className="feat-inn">
                              <ul>
                                <StarRatings
                                  rating={
                                    parseFloat(data?.rating)
                                      ? parseFloat(data?.rating)
                                      : 0
                                  }
                                  starRatedColor="#fcbd30"
                                  starDimension="13px"
                                  starSpacing="0px"
                                />
                              </ul>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-push-2">
            {seo?.header_content && (
            <div className="about-uni-text shade1">
            <SeoHeaderContent />
              </div>
               )}
              <div className="shade1 reviewstops review-discussion pb-0">
                <div className="row">
                  <div className="col-12 pb-3 text-right">
                    <SocialShareButton urlsforShare={urlsforShare} data={""} />
                  </div>
                  <div className="col-12">
                    <h6>
                      <b>{discuss?.data?.topic && discuss?.data?.topic}</b>
                    </h6>
                    <div className="reviews_list mt-2">
                      {(() => {
                        if (discuss?.data?.user_icon) {
                          return (
                            <img
                              src={baseurl + discuss?.data?.user_icon}
                              alt=""
                            />
                          );
                        } else if (discuss?.userDetails?.profile_image) {
                          return (
                            <img
                              src={
                                baseurl + discuss?.userDetails?.profile_image
                              }
                              alt=""
                            />
                          );
                        } else {
                          return <img src="/assets/img/noImage.jpeg" alt="" />;
                        }
                      })()}
                      {/* <img src="assets/img/testi-2.png" alt="" /> */}

                      <div className="reviews_item disPro">
                        <h6>
                          <a
                            className={
                              (discuss?.data?.user_type === 4 &&
                                discuss?.data?.Counsellor?.slug) ||
                              (discuss?.data?.user_type === 5 &&
                                discuss?.data?.Coach?.slug)
                                ? ""
                                : "cursorPointer"
                            }
                            onClick={() => {
                              if (
                                discuss?.data?.user_type === 4 &&
                                discuss?.data?.Counsellor &&
                                discuss?.data?.Counsellor?.slug
                              ) {
                                history.push(
                                  "/counsellor/" +
                                    discuss?.data?.Counsellor?.slug
                                );
                              } else if (
                                discuss?.data.user_type === 5 &&
                                discuss?.data?.Coach &&
                                discuss?.data?.Coach?.slug
                              ) {
                                history.push(
                                  "/mentor/" + discuss?.data?.Coach?.slug
                                );
                              } else {
                                {
                                }
                              }
                            }}
                          >
                            <b className="yellow">
                              {discuss?.data?.auther_name
                                ? discuss?.data?.auther_name
                                : "Guest"}
                            </b>
                          </a>{" "}
                          <span>
                            <small>{date && date} </small>{" "}
                          </span>
                          <p className="profileeva">
                            <i className="fa fa-tag"></i>{" "}
                            <small
                              className="pointer"
                              onClick={() =>
                                history.push("/discussions", {
                                  id: discuss?.data?.category_id,
                                  name: discuss?.data?.category?.category_name,
                                })
                              }
                            >
                              {discuss?.data?.category?.category_name
                                ? discuss?.data?.category?.category_name
                                : ""}
                            </small>
                          </p>
                        </h6>
                        <p className="pt-1 pb-1">
                          {discuss?.data?.description
                            ? discuss?.data?.description
                            : ""}
                        </p>
                        <ul className="iconscomments">
                          <li>
                            <a
                              onClick={(e) => {
                                hendleLike(true, discuss?.data?.id);
                              }}
                            >
                              <i
                                className={
                                  result == true
                                    ? "fa fa-thumbs-o-up active"
                                    : "fa fa-thumbs-o-up"
                                }
                                aria-hidden="false"
                              ></i>{" "}
                              {discuss?.data?.likes
                                ? discuss?.data?.likes
                                : "0"}
                            </a>
                          </li>
                          <li>
                            <a href="" tabIndex={0}>
                              <img
                                className="scale"
                                src="/assets/img/comment.png"
                              />{" "}
                              {/* {discuss?.data?.comments ? discuss?.data?.comments : "0"} */}
                              {discussComments?.length}
                            </a>
                          </li>
                          <li>
                            <a href="" tabIndex={0}>
                              <img
                                className="scale"
                                src="/assets/img/file.png"
                              />{" "}
                              {discuss?.data?.views
                                ? discuss?.data?.views
                                : "0"}
                            </a>
                          </li>
                        </ul>
                        <div className="program-search px-0">
                          <div className="row">
                            <div className="col-12">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  name="comment"
                                  className="form-control rounded-0"
                                  placeholder="comment"
                                  autoComplete="off"
                                  value={discussionComment}
                                  onChange={(e: any) => {
                                    setdiscussionComment(e.target.value);
                                  }}
                                />
                                <div className="input-group-append">
                                  <button
                                    className={
                                      discussionComment?.trim()?.length > 0
                                        ? "btn btn-secondary"
                                        : "btn btn-secondary discuDiseble"
                                    }
                                    type="button"
                                    onClick={hendleComment}
                                  >
                                    comment
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {discussComments?.length > 0 ? (
                  <div className="row bg-grey-light px-4 reviewlistall">
                    <div className="col-12">
                      <div className="reviewstops bg-grey-light review-discussion">
                        <div className="row">
                          <div className="col-12">
                            <h6 className="pb-2 yellow">
                              <small>
                                <b className="f-30">
                                  {discussComments?.length
                                    ? discussComments?.length
                                    : "0"}{" "}
                                  {discussComments?.length == 1
                                    ? "comment"
                                    : "comments"}
                                </b>
                              </small>
                            </h6>

                            {discussComments &&
                              discussComments?.map((data: any, i: any) => {
                                let commentdate = dateFormat(
                                  data?.created_at,
                                  " dd-mmm-yyyy"
                                );

                                return (
                                  i < viewAll && (
                                    <div
                                      className="reviews_list mt-4"
                                      key={i}
                                      style={{
                                        display:
                                          data?.parent_id == null ? "" : "none",
                                      }}
                                    >
                                      <img
                                        src={
                                          data?.user_type == 1
                                            ? data?.Student?.profile_image
                                              ? baseurl +
                                                data?.Student?.profile_image
                                              : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                            : data?.User?.profile_photo
                                            ? baseurl +
                                              data?.User?.profile_photo
                                            : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                        }
                                        className="scale_new_comment_img"
                                        alt=""
                                      />
                                      <div className="reviews_item">
                                        <h6>
                                          {" "}
                                          <span className="color-green">
                                            {(data?.user_type == 1
                                              ? data?.Student?.name
                                              : data?.User?.name) || "Guest"}
                                          </span>
                                          <span>
                                            <small>
                                              {commentdate && commentdate}
                                            </small>{" "}
                                          </span>
                                        </h6>
                                        <p className="pt-1 pb-1">
                                          {data?.comments && data?.comments}
                                        </p>
                                        <ul className="subsomment iconscomments pb-0">
                                          <li>
                                            <a
                                              onClick={(e) => {
                                                handleCommentLike(
                                                  true,
                                                  discuss?.data?.id,
                                                  data?.id
                                                );
                                              }}
                                            >
                                              <i
                                                className={
                                                  result == true
                                                    ? "fa fa-thumbs-o-up active"
                                                    : "fa fa-thumbs-o-up"
                                                }
                                                aria-hidden="false"
                                              ></i>{" "}
                                              {(() => {
                                                // if (commentlikeUnlike) {
                                                //   if (commentlikeUnlike?.comment_id == data?.id) {

                                                //     return <> {'A' + commentlikeUnlike?.counts}</>
                                                //   } else {
                                                //     return <> {data?.like_count ? 'C' + data?.like_count : 0}</>
                                                //   }
                                                // } else {

                                                return (
                                                  <>
                                                    {" "}
                                                    {data?.like_count
                                                      ? data?.like_count
                                                      : 0}
                                                  </>
                                                );
                                                // }
                                              })()}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={(e) => {
                                                hendleCommentBox(
                                                  "commentData" + data?.id
                                                );
                                              }}
                                              tabIndex={0}
                                            >
                                              <span className="reply_comment">
                                                reply
                                              </span>
                                            </a>
                                          </li>
                                          {"commentData" + data?.id ==
                                          CommentBox ? (
                                            <div className="program-search px-0">
                                              <div className="row">
                                                <div className="col-12">
                                                  <ul className="iconscomments subsomment ">
                                                    {discussComments &&
                                                      discussComments?.map(
                                                        (Data: any, i: any) => {
                                                          let commentdate =
                                                            dateFormat(
                                                              Data?.created_at,
                                                              " dd-mmm-yyyy"
                                                            );
                                                          if (
                                                            data?.id ==
                                                            Data?.parent_id
                                                          ) {
                                                            return (
                                                              <div
                                                                className="reviews_list pb-3"
                                                                key={i}
                                                              >
                                                                <img
                                                                  src={
                                                                    Data?.user_type ==
                                                                    1
                                                                      ? Data
                                                                          ?.Student
                                                                          ?.profile_image
                                                                        ? baseurl +
                                                                          Data
                                                                            ?.Student
                                                                            ?.profile_image
                                                                        : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                                                      : Data
                                                                          ?.User
                                                                          ?.profile_photo
                                                                      ? baseurl +
                                                                        Data
                                                                          ?.User
                                                                          ?.profile_photo
                                                                      : "https://apis.admisify.com/counsellor/8a1e4c3a-e498-43a4-b4fd-ca47370938b1.png"
                                                                  }
                                                                  className="scale_new_comment_img"
                                                                  alt=""
                                                                  style={{
                                                                    height:
                                                                      "50px",
                                                                    width:
                                                                      "50px",
                                                                  }}
                                                                />
                                                                <div className="reviews_item">
                                                                  <h6>
                                                                    {" "}
                                                                    <span className="color-green">
                                                                      {(Data?.user_type ==
                                                                      1
                                                                        ? Data
                                                                            ?.Student
                                                                            ?.name
                                                                        : Data
                                                                            ?.User
                                                                            ?.name) ||
                                                                        "Guest"}
                                                                    </span>
                                                                    <span>
                                                                      <small>
                                                                        {commentdate &&
                                                                          commentdate}
                                                                      </small>{" "}
                                                                    </span>
                                                                  </h6>
                                                                  <p className="pt-1 pb-1">
                                                                    {Data?.comments &&
                                                                      Data?.comments}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            );
                                                          } else {
                                                            return null;
                                                          }
                                                        }
                                                      )}
                                                  </ul>
                                                  <div className="input-group mb-3">
                                                    <input
                                                      type="text"
                                                      name={
                                                        "comment" + data?.id
                                                      }
                                                      className="form-control rounded-0"
                                                      placeholder="comment"
                                                      autoComplete="off"
                                                      value={discussionComment}
                                                      onChange={(e: any) => {
                                                        setdiscussionComment(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <div className="input-group-append">
                                                      <button
                                                        className={
                                                          discussionComment?.trim()
                                                            ?.length > 0
                                                            ? "btn btn-secondary"
                                                            : "btn btn-secondary discuDiseble"
                                                        }
                                                        type="button"
                                                        onClick={(e) => {
                                                          handleCommentReply(
                                                            data?.id
                                                          );
                                                        }}
                                                      >
                                                        comment
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            " "
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  )
                                );
                              })}

                            <div className="all-center my-2">
                              {discussComments?.length > viewAll ? (
                                <a
                                  onClick={() => {
                                    setviewAll(
                                      discussComments?.length
                                        ? discussComments?.length
                                        : "0"
                                    );
                                  }}
                                  className="view-more readbtn"
                                >
                                  view all
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {seo?.footer_content && (
              <div className="about-uni-text shade1 mt-5">
            <SeoFooterContent />
              </div>
               )}
            </div>
          </div>
        </div>
      </section>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    Get
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                      ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                      : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code: <span>{modelData[0]?.coupon_code}</span>{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscussionDetails;
