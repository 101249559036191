import React, { useState, useEffect, useRef } from "react";
import AppOn from "./component/AppOn";
import ChooseAdmissify from "./component/ChooseAdmissify";
import Counsellor from "./component/Counsellor";
import HomeServices from "./component/HomeServices";
import ScholarshipOffers from "./component/ScholarshipOffers";
import StudyCourses from "./component/StudyCourses";
import Testimonial from "./component/Testimonial";
import TrendingDiscussion from "./component/TrendingDiscussion";
import TrendingUniversites from "./component/TrendingUniversites";
import { useSelector, useDispatch } from "react-redux";
import { countryAction, UniversityCourseAction } from "../redux/common/action";
import Seo from "../Component/Seo/Seo";
import { useHistory } from "react-router-dom";
import { smartSearchValidation } from "../validation/Validation";
import { toast } from "react-toastify";
import MenuOutSideClick from "../layout/header/outsideClick";
import Breadcrumb from "../Component/Breadcrumb";
function Home() {
  const dispatch = useDispatch();
  const history = useHistory();
  const anchorRef1 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  const state = useSelector((state: any) => state);
  const [searchMainCourse, setSearchMainCourse] = useState<any[]>([]);
  const [studyLevel, setstudyLevel] = useState<any>(1);
  const [uni_country, setuni_country] = useState<any>({});
  const [uni_intake, setuni_intake] = useState<any>("");
  const [uni_course, setuni_course] = useState<any>("");
  const [uni_rank, setuni_rank] = useState<any>("");
  const [uni_marks, setuni_marks] = useState<any>("");
  const [formValidate, setSubmitting] = useState<any>({
    isSubmitting: false,
    error: {},
  });
  const [universityFilter, setuniversityFilter] = useState<any>({});

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );

  const countryback = uni_country && uni_country.toString();

  const activecountry =
    countrys &&
    countrys?.filter((data: any) => {
      return data?.status == 1;
    });

  const allActiveStreams: any = useSelector((state: any) =>
    state.stream.streamList ? state.stream.streamList : []
  );

  const Streme =
    allActiveStreams &&
    allActiveStreams.filter((data: any) => {
      return data?.course_level_id == studyLevel;
    });

  const steameList = Streme.sort((a: any, b: any) =>
    a?.stream_name > b?.stream_name
      ? 1
      : b?.stream_name > a?.stream_name
      ? -1
      : 0
  );
  useEffect(() => {
    if (
      allActiveStreams &&
      steameList &&
      Array.isArray(steameList) &&
      steameList?.length > 0
    ) {
      setSearchMainCourse(steameList?.slice(0, 5));
    }
  }, [allActiveStreams]);

  const hendlback = (e: any) => {
    if (countryback == "[object Object]") {
      toast.error("Please select country.");
    } else {
      let countrySlug = activecountry?.find(
        (ele) => ele?.id == uni_country
      )?.slug;
      let preurl = "/";
      if (countrySlug) {
        preurl += `${countrySlug}/`;
      }
      if (studyLevel) {
        preurl += `${studyLevel == 1 ? "ug" : "pg"}/`;
      }
      history.push(`${preurl}universities`, {
        country_id: countryback.split(","),
        course_id: uni_course,
        rank: uni_rank,
        marks: uni_marks,
        course_level: studyLevel,
        intake: uni_intake,
        isSmartSearch: true,
      });
    }
    // return;
    // const validationErrors = smartSearchValidation(countryback);
    //       setSubmitting({ isSubmitting: validationErrors.isFormValid, error: validationErrors });
    // if(validationErrors.isFormValid){

    // }
  };

  // const filterUni = universityFilter.length > 0 ? universityFilter : university;

  // useEffect(() => {
  //   dispatch(UniversityCourseAction.getAllCourses({}));
  //   return () => {};
  // }, []);

  // useEffect(() => {
  //   dispatch(countryAction.getAllCountry({}));
  //   return () => {};
  // }, []);

  return (
    <>
      <Seo slug={window.location.pathname} />
      <section
        className="headslider"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="sliderbox">
          <div className="slidercont owl-carousel111">
            <div>
              {/* <img className="scale" src="assets/img/banner1.jpg" alt="" /> */}
              <video
                src="../../assets/video/admissify_video.mp4"
                style={{ width: "100%", height: "100%" }}
                loop
                muted
                autoPlay
                playsinline
                controls=""
              ></video>
            </div>
          </div>
          {/* <div className="col-lg-6 page-heading-section6">
            <div className="container">
              <div className="page-heading-content text-left">
                <h2 className="title">
                  the
                  <span> complete abroad</span>
                </h2>
                <p>admission solution</p>
                <p style={{ fontSize: "14px" }}>Search from over 3,000 universities and 100,000 courses, according to your profile</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="search-slider">
          <div className="container">
            <div className="playslider">
              {/* <img className="scale" src="assets/img/play_icon.png" alt="" /> */}
            </div>
            <div
              data-aos="flip-up"
              data-aos-duration="1500"
              className="prnt_dvhome intelliSearch img-responsive leftmargin"
            >
              <div className="frm_dvhome new-intelli">
                <form className="innerSearch">
                  <span className="frm_spn">
                    <img
                      className="scale"
                      src="assets/img//form_icon.png"
                      alt=""
                    />
                    &nbsp; Smart Search
                  </span>
                  <div className="form-group mb-0">
                    <h5 className="pb-2 pt-2">study level</h5>
                    <div className="row radiocustom">
                      <div className="col-md-5 col-sm-5">
                        <input
                          type="radio"
                          name="RadioGroup1"
                          value="bachelor's"
                          id="RadioGroup1_0"
                          defaultChecked
                          onChange={() => setstudyLevel(1)}
                        />
                        <label
                          className="study_level_btn"
                          htmlFor="RadioGroup1_0"
                        >
                          <i className="fa fa-graduation-cap"></i> bachelor's{" "}
                        </label>
                      </div>
                      <div className="col-md-5 col-sm-5">
                        <input
                          type="radio"
                          name="RadioGroup1"
                          value="master's"
                          id="RadioGroup1_1"
                          onChange={() => setstudyLevel(2)}
                        />
                        <label
                          className="study_level_btn"
                          htmlFor="RadioGroup1_1"
                        >
                          <i className="fa fa-graduation-cap"></i> master's
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <h6>
                        country <span style={{ color: "red" }}>*</span>
                      </h6>
                      <select
                        className="chosen-select text-capitalize"
                        name="country"
                        required
                        onChange={(e: any) => setuni_country(e.target.value)}
                      >
                        <option value="">select country </option>

                        {countrys &&
                          countrys?.map((data, i) => {
                            return (
                              <option value={data?.id} key={i}>
                                {data?.country_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <h6>main course</h6>
                      {/* <select className="chosen-select text-capitalize" onChange={(e: any) => { setuni_course(e.target.value) }}>
                        <option value="">select course </option>
                        {steameList && steameList?.map((data: any, i: any) => {
                          return (
                            <option value={data?.id} key={i} >{data?.stream_name}</option>
                          )
                        })}
                      </select> */}
                      <input
                        id="main_c_auto"
                        type="text"
                        ref={anchorRef1}
                        className="chosen-select text-capitalize"
                        placeholder="search"
                        autoComplete="off"
                        onChange={(e: any) => {
                          setuni_course("");
                          const filteredOptions: any[] =
                            steameList &&
                            steameList.filter(
                              (ele: any) =>
                                ele?.stream_name
                                  ?.toLowerCase()
                                  .indexOf(e.target.value?.toLowerCase()) > -1
                            );
                          setSearchMainCourse(filteredOptions);
                        }}
                      />
                      {anchor1 && (
                        <div
                          className="searchuniversities"
                          style={{ padding: "0px", top: "50px" }}
                        >
                          <ul>
                            {searchMainCourse &&
                            searchMainCourse?.length > 0 ? (
                              searchMainCourse?.map((data: any, i: any) => (
                                <li
                                  key={i}
                                  onClick={() => {
                                    setuni_course(data?.id);
                                    let ele: any = anchorRef1?.current;
                                    if (ele) {
                                      ele.value = data?.stream_name;
                                    }
                                  }}
                                >
                                  {data?.stream_name}
                                </li>
                              ))
                            ) : (
                              <li>No Record Found</li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6 col-sm-12 col-xs-12 ">
                      <h6>marks</h6>
                      <select
                        className="chosen-select"
                        onChange={(e: any) => {
                          setuni_marks(e.target.value);
                        }}
                      >
                        <option value="" className="full-text">
                          select marks
                        </option>
                        <option value="50">40 - 50</option>
                        <option value="60">50 - 60 </option>
                        <option value="70">60 - 70</option>
                        <option value="80">70 - 80</option>
                        <option value="90">80 - 90</option>
                        <option value="100">90 - 100</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <h6>university rank</h6>
                      <select
                        className="chosen-select"
                        name="rank"
                        onChange={(e: any) => {
                          setuni_rank(e.target.value);
                        }}
                      >
                        <option value="" className="full-text">
                          select ranking
                        </option>
                        <option value="any">all</option>
                        <option value="10">top 10</option>
                        <option value="25">top 25</option>
                        <option value="50">top 50</option>
                        <option value="100">top 100</option>
                      </select>
                    </div>
                  </div>
                  <h6>select intake time</h6>
                  <div className="row radiocustom1">
                    <div className="col-md-3 col-sm-3">
                      <input
                        type="radio"
                        name="intake"
                        value="any"
                        id="RadioGroup2_0"
                        onChange={(e: any) => {
                          setuni_intake(e.target.value);
                        }}
                      />
                      <label
                        className="study_level_btn"
                        htmlFor="RadioGroup2_0"
                      >
                        any
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <input
                        type="radio"
                        name="intake"
                        value="spring"
                        id="RadioGroup2_1"
                        onChange={(e: any) => {
                          setuni_intake(e.target.value);
                        }}
                      />
                      <label
                        className="study_level_btn"
                        htmlFor="RadioGroup2_1"
                      >
                        spring
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <input
                        type="radio"
                        name="intake"
                        value="fall"
                        id="RadioGroup2_2"
                        onChange={(e: any) => {
                          setuni_intake(e.target.value);
                        }}
                      />
                      <label
                        className="study_level_btn"
                        htmlFor="RadioGroup2_2"
                      >
                        fall
                      </label>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <input
                        type="radio"
                        name="intake"
                        value="summer"
                        id="RadioGroup2_3"
                        onChange={(e: any) => {
                          setuni_intake(e.target.value);
                        }}
                      />
                      <label
                        className="study_level_btn"
                        htmlFor="RadioGroup2_3"
                      >
                        summer
                      </label>
                    </div>
                  </div>
                  <div className="clearfix"></div> <br />
                  <div>
                    <button
                      type="button"
                      className="srch_btn"
                      onClick={hendlback}
                    >
                      Search
                    </button>
                  </div>
                </form>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
              <div className="frm_bg"></div>
            </div>
          </div>
        </div>
      </section>
      <TrendingUniversites />
      <TrendingDiscussion />
      <ScholarshipOffers />
      <Counsellor />
      <AppOn />
      <HomeServices />
      <Testimonial />
      <ChooseAdmissify />
      <StudyCourses />
    </>
  );
}

export default Home;
