import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    studentAction, applyNoAction, scholershipAndOfferAction,
    SettingAction,
} from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import moment from "moment"
function Counsellor() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [modelData, setmodelData] = useState<any>("");
    const [reviewUserId, setReviewUserId] = useState<any>("");
    const [reviewAppointmentId, setReviewAppointmentId] = useState<any>("");
    const [reviewFor, setreviewFor] = useState<any>("");

    const isAuthenticated: any = getToken();
    var user: any = isAuthenticated && jwt_decode(isAuthenticated);

    const getAllaplicant: any = useSelector((state: any) =>
        state.applyNow.getAllaplicant ? state.applyNow.getAllaplicant : []
    );


    const coachCounsellorForProfile: any = useSelector((state: any) =>
        state.student.coachCounsellorForProfile ? state.student.coachCounsellorForProfile : []
    );

    var userInterests = localStorage.getItem('userInterest');
    if (userInterests) {
        userInterests = JSON.parse(userInterests);
    }

    // var countryId = [];
    // if (userInterests.length > 0) {
    //     userInterests[0]?.country_id?.map((data: any, i: any) => {
    //         countryId.push(data.countryId)
    //     });
    // }

    const baseurl = URL.API_BASE_URL



    const filteraplicant = getAllaplicant && getAllaplicant?.filter((data: any) => {
        return data?.student_id == user?.id
    })
    const getOFferAndScholar: any[] = useSelector((state: any) =>
        state.scholershipAndOffer.getOFferAndScholar
            ? state.scholershipAndOffer.getOFferAndScholar
            : []
    );
    const opneModel = (e: any) => {

        const modeldata = getOFferAndScholar && getOFferAndScholar?.filter((data: any) => {
            return data?.id == e;
        })

        setmodelData(modeldata)
    }
    const hendleApplyNow = (e: any) => {
        if (isAuthenticated == false) {
            history.push("/login", { logback: 1 });
        } else {
            history.push("/");
        }
    };

    $(".track-appli").click(function (k, v) {
        var id = $(this).attr('data-id');
        console.log(id);
        $("#track-applicants-" + id).fadeIn();
    });

    useEffect(() => {
        dispatch(applyNoAction.getallapplicant({}));
        return () => { };
    }, []);


    useEffect(() => {
        dispatch(studentAction.coachCounsellorForProfile({
            mobile: user?.mobile,
            id: user?.id
        }));
        return () => { };
    }, []);


    useEffect(() => {
        dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
        return () => { };
    }, []);

    const writeReview = (e: any) => {
        var userId = e.target.attributes.getNamedItem('data-userid').value;
        var appointmentId = e.target.attributes.getNamedItem('data-appointmentid').value;
        var testimonialFor = e.target.attributes.getNamedItem('data-reviewfor').value;
        setReviewUserId(userId);
        setReviewAppointmentId(appointmentId);
        setreviewFor(testimonialFor);
    }
    const submitAppointmentReview = (e: any) => {
        $('.error-class').remove();
        var rating = $('select[name=reviewRating]').val();
        var comment = $('textarea[name=reviewComment]').val();
        var referenceId = reviewUserId;
        var appointmentId = reviewAppointmentId;
        var testimonialFor = reviewFor;
        var isError = false;
        if (!rating) {
            $('select[name=reviewRating]').after('<div class="error-class">select rating</div>');
            isError = true;
        }
        if (!comment) {
            $('textarea [name=reviewComment]').after('<div class="error-class">write a review</div>');
            isError = true;
        }
        if (!referenceId) {
            toast.error('Mentor/Counsellor reference id is missing.');
            isError = true;
        }

        if (!appointmentId) {
            toast.error('appointment id is missing.');
            isError = true;
        }

        if (isError) {
            return false;
        }
        dispatch(studentAction.studentAddReview({
            mobile: user?.mobile,
            id: user?.id,
            testimonial_for: testimonialFor,
            reference_id: referenceId,
            testimonial_type: 1,
            name: user?.name,
            comment: comment,
            rating: rating,
            appointment_id: appointmentId
        }));
    }
    useEffect(() => {
        dispatch(SettingAction.getSetting({}));
        return () => { };
    }, []);

    const getSetting: any = useSelector((state: any) =>
        state.setting.getSetting ? state.setting.getSetting : []
    );
    return (

        <div className="col-md-12 col-lg-9">
            <div className="row">
                <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
                    <div className="page-sidebar">
                        {getOFferAndScholar &&
                            getOFferAndScholar?.map((data: any, i: any) => {
                                return i < 2 && (
                                    <div className="widget sidebar-title ads-blog">
                                        <div key={i}>
                                            <div className="offers-item_ yello title">
                                                <a onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                                                    data-target="#offer-pop">
                                                    {data?.type == "Offers" ? (
                                                        data?.coupon_icon !== null ? (
                                                            <img
                                                                className="scale"
                                                                src={baseurl + data?.coupon_icon}
                                                                onError={(e: any) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "/assets/img/noImage.jpeg";
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                className="scale"
                                                                src="/assets/img/noImage.jpeg"
                                                            />
                                                        )
                                                    ) : data?.sch_image !== null ? (
                                                        <img
                                                            className="scale"
                                                            src={baseurl + data?.sch_image}
                                                            onError={(e: any) => {
                                                                e.target.onerror = null;
                                                                e.target.src = "/assets/img/noImage.jpeg";
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="scale"
                                                            src="/assets/img/noImage.jpeg"
                                                        />
                                                    )}
                                                </a>
                                                <figcaption className="content-box offerHeight_">
                                                    <a className="margin_auto" onClick={(e) => { data?.type == "Offers" ? opneModel(data?.id) : history.push("/scholarshipdetails/" + data?.sch_slug) }} data-toggle="modal"
                                                        data-target="#offer-pop">
                                                        <h5>
                                                            {data?.type == "Offers" && (
                                                                <span>
                                                                    {data?.coupon_type == 1
                                                                        ? 'Get ' + data?.max_off + "%"
                                                                        : 'Get ' + data?.max_off}{" "}
                                                                    OFF
                                                                </span>
                                                            )}
                                                            {data?.type == "Offers"
                                                                ? data?.coupon_title?.length > 16
                                                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                                                    : data?.coupon_title
                                                                : data?.sch_title?.length > 40
                                                                    ? `${data?.sch_title.substring(0, 40)}.`
                                                                    : data?.sch_title}
                                                        </h5>
                                                    </a>
                                                </figcaption>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="col-md-12 col-lg-9">
                    <div className="contentbx shade1 bg-white application_wrap h-100">
                        <section className="categories">
                            <div className="col-md-12 col-lg-12">
                                <div className="appli-t">
                                    <h1>counsellor/mentor</h1>
                                    <img src="/assets/img/appoint--icon.svg" />
                                </div>
                                {coachCounsellorForProfile.length == 0 ? (
                                    <div className="col-lg-12 p-2" >
                                        <div className="appli-box shade1">

                                            <div className="col-md-12 appli-box-t">
                                                <p>no mentor & counsellor found!</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        {coachCounsellorForProfile.length > 0 &&
                                            coachCounsellorForProfile?.map((data: any, i: any) => {
                                                let Date = data?.Date && dateFormat(data?.Date, "dd-mm-yyyy");

                                                var AppointmentId = data?.AppointmentId;
                                                if (data.CounsellorProfileImage) {
                                                    var ProfileImage = data.CounsellorProfileImage;
                                                } else {
                                                    var ProfileImage = data.CoachProfileImage;
                                                }
                                                if (data.CounsellorName) {
                                                    var Name = data.CounsellorName;
                                                } else {
                                                    var Name = data.CoachName;
                                                }
                                                if (data.CounsellorExperience) {
                                                    var Experience = data.CounsellorExperience;
                                                } else {
                                                    var Experience = data.CoachExperience;
                                                }
                                                if (data.CounsellorName) {
                                                    var type = 'Counsellor';
                                                    var UserId = data?.CounsellorId;
                                                    var reviewFor = 3;
                                                } else {
                                                    var type = 'Mentor';
                                                    var reviewFor = 2;
                                                    var UserId = data?.CoachId;
                                                }
                                                return (
                                                    <div className="col-sm-12 col-md-4 col-lg-4 p-0 mb-3">
                                                        <div className="appoint appoint-coach-con">
                                                            <div className="coach-con-profile">
                                                                {ProfileImage != null ? <img
                                                                    src={baseurl + ProfileImage}
                                                                />
                                                                    : <img
                                                                        style={{ height: "30vh" }}
                                                                        src="/assets/img/noImage.jpeg"
                                                                        height="160px"
                                                                        width="100%"
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="couns-d pb-0">
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <h1>{Name}</h1>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h1 className="text-right">{Experience} Yr</h1>
                                                                    </div>
                                                                </div>
                                                                <p className="degi-1">{type}</p>
                                                                {/* <div className="row">
                                                                    <div className="col-12">
                                                                        <p className="couns-disc __content_">{data?.additional_info}</p>
                                                                    </div>
                                                                </div> */}
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <a className="coun-btn" href={"tel:" + getSetting[0]?.mobile} >
                                                                            <div className="couns-chat">
                                                                                <img src="assets/img/phone-alt.png" className="w-10" />connect
                                                                                support</div>
                                                                        </a>
                                                                    </div>
                                                                    {(() => {
                                                                        if (data?.Status == 4) {
                                                                            return <div className="col-12">
                                                                                <a
                                                                                    data-toggle="modal" data-target="#show_write_a_review" className="coun-btn" href="javascript:void(0)">
                                                                                    <div
                                                                                        data-userid={UserId}
                                                                                        data-appointmentid={AppointmentId}
                                                                                        data-reviewFor={reviewFor}
                                                                                        onClick={(e) => writeReview(e)}
                                                                                        className="couns-chat couns-book">
                                                                                        write a review
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        }
                                                                    })()}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div
                id="offer-pop"
                className="modal fade offer_detailpopup"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="off-detail-item">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <figure className="feat-text">
                                    {modelData[0]?.type == "Offers" ? (
                                        modelData[0]?.coupon_icon !== null ? (
                                            <img
                                                className="scale homeOffer"
                                                src={baseurl + modelData[0]?.coupon_icon}
                                                onError={(e: any) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "/assets/img/noImage.jpeg";
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                        ) : (
                                            <img
                                                className="scale homeOffer"
                                                src="/assets/img/noImage.jpeg"
                                            />
                                        )
                                    ) : modelData[0]?.sch_image !== null ? (
                                        <img
                                            className="scale homeOffer"
                                            src={baseurl + modelData[0]?.sch_image}
                                            onError={(e: any) => {
                                                e.target.onerror = null;
                                                e.target.src = "/assets/img/noImage.jpeg";
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className="scale homeOffer"
                                            src="/assets/img/noImage.jpeg"
                                        />
                                    )}
                                </figure>
                                <figcaption className="content-box">
                                    <h3>
                                        {modelData[0]?.type == "Offers" ? "Get" : ""}
                                        {modelData[0]?.type == "Offers" && (
                                            <span>
                                                {modelData[0]?.coupon_type == 1
                                                    ? modelData[0]?.max_off + "%"
                                                    : modelData[0]?.max_off}{" "}
                                                OFF
                                            </span>
                                        )}
                                        {modelData[0]?.type == "Offers"
                                            ? modelData[0]?.coupon_title?.length > 16
                                                ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                                                : modelData[0]?.coupon_title
                                            : modelData[0]?.sch_title?.length > 40
                                                ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                                                : modelData[0]?.sch_title}
                                        {/* Study in Abroad */}
                                    </h3>
                                    <div className="bttns">
                                        <a href="" className="tnc">
                                            t&amp;c apply
                                        </a>
                                    </div>
                                </figcaption>
                                <figcaption className="details-box p-3">
                                    <p className="text-left">Coupon Code:{" "}{modelData[0]?.type == "Offers" && (
                                        <span>
                                            {modelData[0]?.coupon_code}
                                        </span>
                                    )} </p>
                                    <p className="text-right">exp:{" "}{modelData[0]?.type == "Offers" && (
                                        <span>
                                            {moment(modelData[0]?.valid_from).format('DD-MM-YYYY')}
                                        </span>
                                    )}
                                        {modelData[0]?.type == "ScholarShip"
                                            ? `${modelData[0]?.apply_end_date}.`
                                            : ""}</p>
                                    <p className="text-left">
                                        {/* {modelData[0]?.description} */}
                                        {modelData[0]?.type == "Offers" && (
                                            <span>
                                                {modelData[0]?.description}
                                            </span>
                                        )}
                                        {modelData[0]?.type == "ScholarShip"
                                            ? `${modelData[0]?.short_desc}.`
                                            : ""}
                                    </p>
                                    <div className="bttns text-right">
                                        <a onClick={hendleApplyNow} className="applybtn bggreadent">
                                            apply now
                                        </a>
                                    </div>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                id="show_write_a_review"
                className="modal fade offer_detailpopup"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document" style={{ maxWidth: "600px" }}>
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="off-detail-item">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="col-md-12">
                                <div className=" mt-3">
                                    <h5>
                                        <strong>write a review</strong>
                                    </h5>
                                    <hr></hr>
                                </div>
                                <div className="profile_field fields-wrp">
                                    <form id="discussion" className="row">
                                        <div className="col-md-12 col-lg-12 text-center">
                                            <div className="text-center">
                                                <img src="/assets/img/review_img.png" alt="" className="review_img" />
                                            </div>
                                            <br></br>
                                            <div className="text-center">
                                                <select name="reviewRating" className="form-control form-control-sm w-50 m-auto">
                                                    <option value="">select rating</option>
                                                    <option value="1">1 star</option>
                                                    <option value="2">2 star</option>
                                                    <option value="3">3 star</option>
                                                    <option value="4">4 star</option>
                                                    <option value="5">5 star</option>
                                                </select>
                                            </div>
                                            <br></br>
                                            <div className="text-center">
                                                <textarea name="reviewComment" className="form-control w-50  m-auto" placeholder="write a review"></textarea>
                                            </div>
                                            <br></br>
                                        </div>
                                        <div className="col-md-12 col-lg-12">
                                            <div className="fieldbtn text-center mb-3">
                                                <input type="button" onClick={(e) => { submitAppointmentReview(e) }} className="bggreadent apply-sub-btn mt-0" value="submit" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>

    )
}

export default Counsellor
