import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";

import StarRatings from "react-star-ratings";
import { coachAction, counselloerAction } from "../../redux/common/action";
import { URL } from "../../redux/common/url";

function Counsellor() {
  const history = useHistory();

  const baseurl = URL.API_BASE_URL

  const dispatch = useDispatch();

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.coachAndCounsellor ? state.counsellor.coachAndCounsellor : []
  );
  const coachAndCounsellor: any[] = useSelector((state: any) =>
    state.counsellor.coachAndCounsellor ? state.counsellor.coachAndCounsellor : []
  );

  const coach: any[] = useSelector((state: any) =>
    state.coach.couchList ? state.coach.couchList : []
  );


  

  // const counsfilter = counsellor?.filter((datas:any)=>{
  //   return datas?.status == 1
  // })
  // const coachfilter:any = coach?.filter((datas:any)=>{
  //   return datas?.status == 1
  // })


 
 


  useEffect(() => {
    dispatch(counselloerAction.getcoachcounsellor({}));
    return () => {};
  }, []);

  // useEffect(() => {
  //   dispatch(coachAction.getAllcoach({}));
  //   return () => {};
  // }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    <div>
      <section className="categories section">
        <div className=" bg-caoch">
          <div className="container">
            <div className="all-center sub-section pb-5">
              <div className="col-md-12 all-center pb-5">
                <div className="coachslider">
                  <Slider {...settings}>
                    {coachAndCounsellor?.map((data: any, i: any) => {
             
                   
                      
                      return (
                        <div key={i}>
                          <div className="couch-item bottom">
                            <h3>{data?.type}</h3>
                            <figure className="feat-text">
                              <div className="counsellorAndcoachHome" onClick={() => {
                                  data?.type == "counsellor" ?

                                  history.push("/counsellor/" + data?.slug, {
                                    counselloer_id: data?.id,
                                  }): history.push("/mentor/" + data?.slug, {
                                    counselloer_id: data?.id,
                                  })
                                }} style={{ background: `url(${baseurl+data?.profile_image})` ,cursor:"pointer"}}></div>
                              {/* <img
                                className="scale"
                                src="assets/img/coach01.png"
                                // src={baseurl+data?.profile_image}
                              /> */}
                              <figcaption className="content-box">
                                <h4 onClick={() => {
                                  data?.type == "counsellor" ?

                                  history.push("/counsellor/" + data?.slug, {
                                    counselloer_id: data?.id,
                                  }): history.push("/mentor/" + data?.slug, {
                                    counselloer_id: data?.id,
                                  })
                                }}
                                style={{cursor:"pointer", fontSize:"17px"}}
                                > 
                                  {data?.gender == 1 ? "Mr." : "Ms."}{" "}
                                  {/* {data?.name} */}
                                 { data?.name?.length > 12 ?`${data?.name}` :  data?.name}
                                 
                                  <span>
                                    {data?.experience == null
                                      ? "0"
                                      : data?.experience}{" "}
                                    Yr
                                  </span>
                                </h4>
                                <p className="text-left">
                                  {data?.additional_info?.length > 70 ? `${data?.additional_info.substring(0, 70)}.` :  data?.additional_info }
                                 
                                </p>
                                <div className="feat-inn">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />{" "}
                                  </ul>
                                  <span>
                                    students counselled:{" "}
                                    <p>{data?.counselled!= null ?data?.counselled:0}</p>
                                  </span>
                                </div>
                              </figcaption>
                            </figure>
                            <div className="coach-view">
                              <a
                                onClick={() => {
                                  data?.type == "counsellor" ?

                                  history.push("/counsellor/" + data?.slug, {
                                    counselloer_id: data?.id,
                                  }): history.push("/mentor/" + data?.slug, {
                                    counselloer_id: data?.id,
                                  })
                                }}
                                className="mt_btn_yellow"
                              >
                                view profile
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* {coachfilter?.map((data: any, i: any) => {
                  
                      return (
                        <div key={i}>
                          <div className="couch-item">
                            <h3>Coach</h3>
                            <figure className="feat-text">
                              <img
                                className="scale"
                                src="assets/img/coach02.png"
                              />
                              <figcaption className="content-box">
                                <h4>
                                  {data?.gender == 1 ? "Mr." : "Ms. "}{" "}
                                  {data.name}
                                  <span>
                                    {data?.experience == null
                                      ? "0"
                                      : data?.experience}{" "}
                                    Yr
                                  </span>
                                </h4>
                                <p>
                                  {data?.additional_info}
                               
                                </p>
                                <div className="feat-inn">
                                  <ul>
                                    <StarRatings
                                      rating={
                                        parseFloat(data?.rating)
                                          ? parseFloat(data?.rating)
                                          : 0
                                      }
                                      starRatedColor="#fcbd30"
                                      starDimension="13px"
                                      starSpacing="0px"
                                    />{" "}
                                  </ul>
                                  <span>
                                    students counselled:{" "}
                                    <p>
                                      {data?.counselled == null
                                        ? "0"
                                        : data?.counselled}
                                    </p>
                                  </span>
                                </div>
                              </figcaption>
                            </figure>
                            <div className="coach-view">
                              <a
                                onClick={() => {
                                  history.push("/counsellor", {
                                    counselloer_id: data?.id,
                                  });
                                }}
                                className="mt_btn_yellow"
                              >
                                view profile
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })} */}

                    {/* <div>
                      <div className="couch-item bottom">
                        <h3>counsellor</h3>
                        <figure className="feat-text">
                          <img className="scale" src="assets/img/coach01.png" />
                          <figcaption className="content-box">
                            <h4>
                              Mr. M K Jain<span>6 Yr</span>
                            </h4>
                            <p>
                              Tell your counselor you don't think their
                              suggestion could work and explain why.{" "}
                            </p>
                            <div className="feat-inn">
                              <ul>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                              <span>
                                students counselled: <p>20</p>
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view profile
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="couch-item">
                        <h3>Coach</h3>
                        <figure className="feat-text">
                          <img className="scale" src="assets/img/coach02.png" />
                          <figcaption className="content-box">
                            <h4>
                              Ms. Shweta Tiwari<span>5 Yr</span>
                            </h4>
                            <p>
                              Tell your counselor you don't think their
                              suggestion could work and explain why.{" "}
                            </p>
                            <div className="feat-inn">
                              <ul>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                              <span>
                                students counselled: <p>20</p>
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view profile
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="couch-item bottom">
                        <h3>counsellor</h3>
                        <figure className="feat-text">
                          <img className="scale" src="assets/img/coach03.png" />
                          <figcaption className="content-box">
                            <h4>
                              Mr. K K Jain<span>5 Yr</span>
                            </h4>
                            <p>
                              Tell your counselor you don't think their
                              suggestion could work and explain why.{" "}
                            </p>
                            <div className="feat-inn">
                              <ul>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                              <span>
                                students counselled: <p>20</p>
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view profile
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="couch-item">
                        <h3>counsellor</h3>
                        <figure className="feat-text">
                          <img className="scale" src="assets/img/coach03.png" />
                          <figcaption className="content-box">
                            <h4>
                              Mr. K K Jain<span>5 Yr</span>
                            </h4>
                            <p>
                              Tell your counselor you don't think their
                              suggestion could work and explain why.{" "}
                            </p>
                            <div className="feat-inn">
                              <ul>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                              <span>
                                students counselled: <p>20</p>
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                        <div className="coach-view">
                          <a href="#" className="mt_btn_yellow">
                            view profile
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </Slider>
                </div>
              </div>
              <div
                className="all-center aos-init aos-animate"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <Link to={"/counsellor-and-mentor"} className="view-more1">
                  View all
                </Link>
                {/* <a href="#" className="view-more1">
                  View all
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default Counsellor;
