import { 
  LOADING,
  GET_OFFICE_LIST,
  GET_OFFICE_BY_ID,
  GET_IN_TOUCH,
  SERVICE_REQUEST,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  officeList: any;
  officeById: any;
  getInTouch: any[];
  submitQuery: any[];
 }
const initialState : stateType = {
    loading:false,
    officeList: [],
    getInTouch: [],
    submitQuery: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_OFFICE_LIST:
      return { ...state, officeList: action.payload};
      case GET_OFFICE_BY_ID:
      return { ...state, officeById: action.payload};
      case GET_IN_TOUCH:
      return { ...state, getInTouch: action.payload};
      case SERVICE_REQUEST:
      return { ...state, submitQuery: action.payload};
    default:
      return state;
  }
};