import { 
  LOADING,
  GET_ALL_COACH,
  GET_COACH_BY_ID,
  GET_COACH_INFO,
  GET_COACH_BY_SLUG,
  BOOK_APPOINTMENT,
} from "../common/constant";
 type stateType = {
  loading:boolean;
  couchList: any;
  couchDetails: any;
  getcouchinfo: any;
  getcouchBySlug: any;
  bookAppointment: any;
 }
const initialState : stateType = {
    loading:false,
    couchList: [],
    couchDetails: [],
    getcouchinfo: [],
    getcouchBySlug: [],
    bookAppointment: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_ALL_COACH:
      return { ...state, couchList: action.payload};
      case GET_COACH_BY_ID:
      return { ...state, couchDetails: action.payload};
      case GET_COACH_INFO:
      return { ...state, getcouchinfo: action.payload};
      case GET_COACH_BY_SLUG:
      return { ...state, getcouchBySlug: action.payload};
      case BOOK_APPOINTMENT:
      return { ...state, bookAppointment: action.payload};
    default:
      return state;
  }
};